import React from 'react'
import { Row, Col } from 'react-bootstrap'
import './Join.scss'

export default function JoinInfo({ info, active }) {
    return (
        <div className="form-section info-section">
            <Row className="align-items-center">
                <Col md="6" sm="12">
                    <p>
                        <b>{info.forminfo}</b>
                    </p>
                    <p>{info.formmandatory}</p>
                </Col>
                <Col md="6" sm="12">
                    <p>{active === 'artist' ? info.review : ''}</p>
                </Col>
            </Row>
        </div>
    )
}
