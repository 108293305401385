import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import SectionTitle from '../Global/SectionTitle'
import FeaturedArtistHome from './FeaturedArtistHome'
import ExhibitionsHome from './ExhibitionsHome'
import LatestNewsHome from './LatestNewsHome'
import './MediaHome.scss'
import Api from '../../api'
import Helpers from '../../helpers'

function Media({ mediaContent, widgetsContent, artistsCount }, props) {
    const { title, subtitle } = mediaContent
    const { featured_artist, exhibitions, news } = widgetsContent
    const [newsPosts, setNewsPosts] = useState([])
    const [artists, setArtists] = useState([])
    const [events, setEvents] = useState([])

    useEffect(() => {
        async function getData() {
            const response = await Api.get('blogs')
            const res = Helpers.validateResponse(response, alert)
            if (!res) return

            const newsPosts = res.filter(post => {
                let isPost = post.type === 'POST'
                let isNews = false
                post.categories.forEach(cat => {
                    if (cat.en.toLowerCase() === 'news') isNews = true
                })

                return isPost && isNews
            })
            const artists = res.filter(post => post.type === 'ARTIST')
            const events = res.filter(post => post.type === 'EVENT')
            setNewsPosts(newsPosts)
            setArtists(artists)
            setEvents(events)
        }

        getData()
        // eslint-disable-next-line
    }, [])

    return (
        <section className="media text-center">
            <Container>
                <SectionTitle
                    title={title}
                    subtitle={subtitle ? subtitle.replace('XXX', artistsCount.toString()) : ''}
                />
                <div className="media-widgets">
                    {!!!artists && <FeaturedArtistHome posts={artists} title={featured_artist} />}
                    {!!!events && <ExhibitionsHome posts={events} title={exhibitions} />}
                    <LatestNewsHome posts={newsPosts} title={news} />
                </div>
            </Container>
        </section>
    )
}
export default Media
