import React, { useEffect, useState } from 'react'
import { Form, Col } from 'react-bootstrap'
import useForm from 'react-hook-form'
import { withRouter, Link } from 'react-router-dom'
import Auth from '../../auth/Auth'
import { useAlert } from 'react-alert'
import Helpers from '../../helpers'
import BlackLogo from '../../assets/images/databaza_icon.png'

function Login({ content, modalState, setModalState, history }) {
    const { register, handleSubmit, errors } = useForm()
    const alert = useAlert()
    const onSubmit = async userFormData => {
        const res = await Auth.login(userFormData)
        Helpers.validateResponse(res, alert)
    }

    useEffect(() => {
        Object.keys(errors).forEach(err => {
            alert.error(`${err} is required`)
        })
        // eslint-disable-next-line
    }, [errors])

    const closeModal = () => {
        setModalState(false)
    }

    const handleSignup = () => {
        closeModal()
        history.push('/join-user')
    }

    const handleForgotPassword = () => {
        closeModal()
        history.push('/forgot-password')
    }

    const [passwordShown, setPasswordShown] = useState(false)
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    return (
        <>
            <div className={`${modalState ? 'login-modal-overlay' : ''}`} onClick={closeModal}></div>
            <div className={`transitions ${modalState ? 'active' : ''}`}>
                <div className={`login-modal`}>
                    {/* ${modalState ? 'active' : ''} */}
                    <Form className="login-modal-body" onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <span className="close-lightbox" onClick={closeModal}>
                                <i className="fal fa-times"></i>
                            </span>
                        </Form.Group>
                        <Form.Group>
                            <img className="databaza-logo" src={BlackLogo} alt="Databaza.art" />
                            {/* <h6 className="motto-text text-center">{content.subtitle}</h6> */}
                        </Form.Group>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        placeholder={content.email}
                                        ref={register({ required: true })}
                                    />
                                    <div className="form-label">
                                        <i className="fal fa-envelope"></i>
                                        <span className="required-field"></span>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <Form.Control
                                        type={passwordShown ? 'text' : 'password'}
                                        name="password"
                                        ref={register({
                                            required: true,
                                            min: 8,
                                            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/i,
                                        })}
                                        placeholder={content.password}
                                    />
                                    <div className="form-label">
                                        <i className="fal fa-lock"></i>
                                        <span className="required-field"></span>
                                    </div>

                                    <div className="form-label-right" onClick={togglePasswordVisiblity}>
                                        <i className={`fal ${passwordShown ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col>
                                <Link
                                    className="forgot-password-link"
                                    to="forgot-password"
                                    onClick={handleForgotPassword}
                                >
                                    Forgot Password?
                                </Link>
                            </Col>
                        </Form.Row>
                        <Form.Row>
                            <Col sm="12">
                                <button className="btn btn-dark btn-lg btn-block text-white" type="submit">
                                    {content.loginBtn}
                                </button>
                            </Col>
                            <Col sm="12">
                                <p className="sign-up">
                                    {content.dontHaveAccount}
                                    <span className="sign-up-button" onClick={handleSignup}>
                                        {content.signUpBtn}
                                    </span>
                                </p>
                            </Col>
                        </Form.Row>
                    </Form>
                </div>
            </div>
        </>
    )
}
export default withRouter(Login)
