import React from 'react'
import { Row, Col } from 'react-bootstrap'
import SectionTitle from '../Global/SectionTitle'
import ImageTag from '../Global/ImageTag'
import './Artworks.scss'

function Artworks({ artworks }) {
    return (
        <>
            {artworks && artworks.length !== 0 ? (
                <section style={{padding: '29px 0px'}} className="artworks-section">
                    <SectionTitle title="Selected artwork" subtitle="" />
                    <Row>
                        {artworks &&
                            artworks.map(art => (
                                <Col md="3" key={art._id}>
                                    <article className="artwork">
                                        <figure>
                                            <ImageTag src={art.image} alt={art.title} />
                                        </figure>
                                        <footer>
                                            <div className="artist-meta">
                                                <span className="category">{art.technique}</span>
                                                <span className="date">{art.year}</span>
                                            </div>
                                            <h3 className="artist-name">{art.title}</h3>
                                        </footer>
                                    </article>
                                </Col>
                            ))}
                    </Row>
                </section>
            ) : null}
        </>
    )
}

export default Artworks
