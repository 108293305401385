import React, { useEffect } from 'react'
import PageTitle from '../../components/Global/PageTitle'
import { JoinHeaders, JoinInfo, UserForm } from '../../components/Join'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function JoinUserPage() {
    const { t } = useTranslation()
    const joinContent = t('joinpage', { returnObjects: true })
    const { joinheader, info, userform } = joinContent

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section>
            <Container>
                <div className="paper-view">
                    <JoinHeaders user={joinheader.user} artist={joinheader.artist} active="user" />
                    <JoinInfo info={info} />
                    <UserForm form={userform} />
                </div>
            </Container>
        </section>
    )
}
