import React, { useEffect } from 'react'
import { useState } from 'react'
import { Col, Form } from 'react-bootstrap'
import Select from 'react-select'
import Helpers from '../../../helpers'
import Api from '../../../api'

export default function({ register, setValue, setArtTypes }) {
    const selectStyles = Helpers.getSelectStyles()
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(false); /* This is just to show the view... */
    const [taxonomies, setTaxonomies] = useState([{
        mediums: [],
        artstyles: [],
        music_category: [],
        music_genre: [],
        dramatic_category: [],
        dramatic_genre: []
    }]);
    const [taxonomiesIDS, setTaxonomiesIDS] = useState([]);
    const [artType, setArtType] = useState([]);

    const getAllTaxonomies = async () => {
        try {
            const response = await Api.get('taxonomies');
            if (!response || !response.data || !response.data.results) return;
            setData(response.data.results);
            setArtTypes(response.data.results)
        } catch (err) {

        }
    }

    const handleArt = (type, key) => {
        try {
            if (!data || !data[type]) return [];
            let obj = data[type];
            let arr = [];
            obj[key].forEach((elem) => arr.push({ value: elem._id, label: elem.value }))
            return arr;
        } catch (err) {
            return [];
        }
    }

    useEffect(() => {
        let taxIDS = []
        taxonomies.forEach(obj => Object.keys(obj).forEach(key => obj[key].forEach(id => taxIDS.push(id))))
        setTaxonomiesIDS(taxIDS)
        setValue('taxonomies', taxIDS)
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxonomies])


    const handleChangeSelected = (meta, selected, type) => {
        let tempTaxonomies = [...taxonomies]
        let val = meta.value[0]
        tempTaxonomies.forEach(tax => {
            if (!tax[type].includes(val)) {
                tax[type].length = 0;
                tax[type].push(val);
            }
        })
        setTaxonomies(tempTaxonomies);
    }

    useEffect(() => {
        setValue('type', artType)
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [artType])

    useEffect(() => {
        getAllTaxonomies();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        register({ name: 'taxonomies' })
        register({ name: 'type' })
         // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    const removeSpecificTaxonomy = (fields) => {
        let tempTaxonomies = [...taxonomies]
        tempTaxonomies.forEach(tax => fields.forEach(field => tax[field].length = 0))
        setTaxonomies(tempTaxonomies);
    }

    const addArtType = (type) => {
        let temp = [...artType];
        if (!temp.includes(type)) temp.push(type.toUpperCase())
        setArtType(temp);
    }

    const removeArtType = (type) => {
        let temp = [...artType];
        temp = temp.filter(elem => { return elem !== type.toUpperCase() });
        setArtType(temp)
    }

    const handleArtType = (e, type) => {
        let temp = {...selected};
        if (!e.target.checked) {
            if (type === "visual") removeSpecificTaxonomy(["mediums", "artstyles"])
            if (type === "music") removeSpecificTaxonomy(["music_category", "music_genre"]);
            if (type === "dramatic") removeSpecificTaxonomy(["dramatic_category", "dramatic_genre"]);
            delete temp[type]
            setSelected(temp);
            removeArtType(type)
            return;
        }
        addArtType(type)
        temp[type] = data[type]
        setSelected(temp)      
    }
    
    return (
        <React.Fragment>
            <h4 className="text-center">Art Type</h4>
            <div className="art-types" style={{padding: '25px 0px', display: 'flex', justifyContent: 'space-evenly'}}>
                <div>
                    <Form.Check
                        custom
                        type="checkbox"
                        label="Visual"
                        onChange={(e) => handleArtType(e, 'visual')}
                    />
                </div>
                <div>
                    <Form.Check
                        custom
                        type="checkbox"
                        label="Music"
                        onChange={(e) => handleArtType(e, 'music')}
                    />
                </div>
                <div id="test">
                    <Form.Check
                        custom
                        type="checkbox"
                        label="Dramatic"
                        onChange={(e) => handleArtType(e, 'dramatic')}
                    />
                </div>
            </div>
            {
                (selected && selected.visual)
                    && Object.keys(selected.visual).map((key, index) => {
                        return <React.Fragment key={index}>
                                <p>{index === 0 ? "Mediums" : "Artstyles"}</p>
                                    <Form.Row>
                                        <Col>
                                            <Form.Group>
                                                <Select
                                                    options={handleArt('visual', key)}
                                                    styles={selectStyles}
                                                    name="visual"
                                                    // ref={register}
                                                    onChange={(meta, selected) => handleChangeSelected(meta, selected, key)}
                                                    placeholder={"Nothing selected"}
                                                />
                                                <div className="form-label">
                                                    <i className="fal fa-paint-brush-alt"></i>
                                                    <span className="required-field"></span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </React.Fragment> 
                    })
            }

            {
                (selected && selected.music) 
                    && Object.keys(selected.music).map((key, index) => {
                        return <React.Fragment key={index}>
                                    <p>{index === 0 ? "Music Category" : "Music Genre"}</p>
                                    <Form.Row>
                                        <Col>
                                            <Form.Group>
                                                <Select
                                                    options={handleArt('music', key)}
                                                    styles={selectStyles}
                                                    name="music"
                                                    // ref={register}
                                                    onChange={(meta, selected) => handleChangeSelected(meta, selected, key)}
                                                    placeholder={"Nothing selected"}
                                                />
                                                <div className="form-label">
                                                    <i className="fal fa-paint-brush-alt"></i>
                                                    <span className="required-field"></span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </React.Fragment>
                    })
            }

            {
                (selected && selected.dramatic) 
                    && Object.keys(selected.dramatic).map((key, index) => {
                        return <React.Fragment key={index}>
                                    <p>{index === 0 ? "Dramatic Category" : "Dramatic Genre"}</p>
                                    <Form.Row>
                                        <Col>
                                            <Form.Group>
                                                <Select
                                                    options={handleArt('dramatic', key)}
                                                    styles={selectStyles}
                                                    name="dramatic"
                                                    // ref={register}
                                                    onChange={(meta, selected) => handleChangeSelected(meta, selected, key)}
                                                    placeholder={"Nothing selected"}
                                                />
                                                <div className="form-label">
                                                    <i className="fal fa-paint-brush-alt"></i>
                                                    <span className="required-field"></span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </React.Fragment> 
                    })
            }
        </React.Fragment>
    );
}