import Api from '../api/Api'
class Auth {
    constructor() {
        this.isAuthenticated = false
    }

    async login(data) {
        const loginData = await Api.login(data)
        if (loginData && loginData.data && loginData.data.confirmation === 'Success') {
            this.isAuthenticated = true
            sessionStorage.setItem('ACCESS_TOKEN', loginData.data.results.access_token)
            sessionStorage.setItem('USER_DATA', JSON.stringify(loginData.data.results))
            window.location.replace('/')
        }
        return loginData
    }

    async register(data) {}

    logout() {
        sessionStorage.removeItem('ACCESS_TOKEN')
        sessionStorage.removeItem('USER_DATA')
        this.isAuthenticated = false
        window.location.reload()
    }

    isLoggedIn() {
        return this.isAuthenticated || sessionStorage.getItem('ACCESS_TOKEN') !== null
    }
}

export default new Auth()
