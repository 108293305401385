import React from 'react'
import { Form, Col } from 'react-bootstrap'

export default function Terms({ content, register }) {
    return (
        <div className="form-section no-border">
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Form.Check
                            custom
                            type="checkbox"
                            id="terms-and-conditions"
                            name="terms_and_conditions"
                            ref={register({ required: true })}
                            label={content.terms_conditions}
                        />
                        <Form.Check
                            custom
                            type="checkbox"
                            id="privacy-policy"
                            name="privacy_policy"
                            ref={register({ required: true })}
                            label={content.privacy}
                        />
                        <Form.Check
                            custom
                            type="checkbox"
                            id="newsletter-subscription"
                            name="newsletter_subscription"
                            ref={register}
                            label={content.newsletter}
                        />
                    </Form.Group>
                </Col>
            </Form.Row>
        </div>
    )
}
