import React from 'react'
// import PageTitle from '../Global/PageTitle'
import SectionTitle from '../Global/SectionTitle'
import Service from '../Service'
import { Container, Row } from 'react-bootstrap'
import artistIcon from '../../assets/images/dak-icons-about(1).svg'
import databazaIcon from '../../assets/images/dak-icons-about(2).svg'
import youIcon from '../../assets/images/dak-icons-about(3).svg'
import './HowItWorks.scss'

function HowItWorks({ howItWorksContent }) {
    const { section, steps } = howItWorksContent

    const serviceProps = [
        {
            icon: artistIcon,
            arrow: 'top',
        },
        {
            icon: databazaIcon,
            arrow: 'bottom',
        },
        {
            icon: youIcon,
        },
    ]

    return (
        <section className="how-it-works homepage">
            <Container>
                {/* <PageTitle title={page.title} subtitle={page.subtitle} /> */}
                <SectionTitle title={section.title} subtitle={section.subtitle} />
                <Row>
                    {steps.map((s, i) => (
                        <Service
                            key={i}
                            title={s.title}
                            description={s.description}
                            icon={serviceProps[i].icon}
                            arrow={serviceProps[i].arrow}
                        />
                    ))}
                </Row>
            </Container>
        </section>
    )
}
export default HowItWorks
