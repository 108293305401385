import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import './SimpleSearch.scss'

export default function SimpleSearch({ searchContent }) {
    const [focus, setFocus] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')

    const handleSearchFocus = () => setFocus(true)
    const handleSearchBlur = () => setFocus(false)

    const handleSearchChange = e => {
        setSearchTerm(e.target.value)
    }

    const handleSubmit = e => {
        setRedirect(true)
    }

    return (
        <div className="simple-search">
            {redirect && <Redirect to={{ pathname: '/search', state: { term: searchTerm } }} />}
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    onChange={handleSearchChange}
                    onFocus={handleSearchFocus}
                    onBlur={handleSearchBlur}
                />
                <div className={`custom-placeholder ${!focus && 'active'}`}>
                    {searchContent.placeholderBold}
                    {searchContent.placeholderLight}
                </div>
                <div className="search-form-actions">
                    <button type="submit" className="btn btn-clear">
                        <i className="fal fa-search normal-state"></i>
                        <i class="fas fa-search hover-state"></i>
                    </button>
                </div>
            </form>
        </div>
    )
}
