import React, { createContext, useState, useEffect } from 'react'
import i18n from '../i18n'
export const LanguageContext = createContext(null)

const fetchLanguage = function () {
    const languageStorage = localStorage.getItem('lang')
    if (languageStorage && (languageStorage === 'en' || languageStorage === 'sq')) {
        return languageStorage
    }
    return 'sq'
}

const LanguageContextProvider = ({ children }) => {
    const [language, setLanguage] = useState(fetchLanguage)

    useEffect(() => {
        i18n.changeLanguage(language)
        localStorage.setItem('lang', language)
    }, [language])

    return <LanguageContext.Provider value={{ language, setLanguage }}>{children}</LanguageContext.Provider>
}

export default LanguageContextProvider
