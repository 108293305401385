import React, { useContext, useState } from 'react'
import { AlertContext } from '../../contexts/AlertContext'
import './Alert.scss'
import { useEffect } from 'react'

function Alert() {
    const { alerts, setAlerts } = useContext(AlertContext)

    const removeAlert = i => {
        const currentAlerts = alerts
        currentAlerts.splice(i, 1)
        setAlerts([])
    }

    const [values, setValues] = useState(alerts)

    useEffect(() => {
        setValues(alerts)
    }, [alerts])

    return (
        <ul className="alerts-container">
            {values.map((alert, i) => (
                <li key={i} className={alert.type ? alert.type : ''}>
                    <span className="alert-message">{alert.message}</span>
                    <span
                        className="close"
                        onClick={() => {
                            removeAlert(i)
                        }}
                    >
                        <i className="fal fa-times"></i>
                    </span>
                </li>
            ))}
        </ul>
    )
}

export default Alert
