import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import ImageTag from '../Global/ImageTag'
import { LanguageContext } from '../../contexts/LanguageContext'

export default function BlogLayoutEventGrid({ post }) {
    const { language } = useContext(LanguageContext)

    return (
        <div className="col-12 col-lg-4 all-art">
            <div className="media-news">
                {post.featuredImage && (
                    <div className="img-post-exhibitions">
                        <ImageTag className="img-news-back" src={post.featuredImage} />
                    </div>
                )}
            </div>
            <div className="col-12 col-lg-8 calendar-wrapper">
                {post.startDate && (
                    <div className="calendar">
                        <div className="box-1-media">
                            <p className="data-nr">{new Date(post.startDate).getDate()}</p>
                        </div>
                        <div className="box-2-media">
                            <p className="month">
                                {new Date(post.startDate).toLocaleDateString(undefined, { month: 'short' })}
                            </p>
                        </div>
                    </div>
                )}
                <div className="city-and-title">
                    {(post.location || post.place) && (
                        <p className="city-and-name">
                            {post.location} · {post.place}
                        </p>
                    )}
                    <p className="text-title-exhibitions">
                        <Link to={`/news/${post._id}`}>{post.multiLanguageFields[language].title}</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}
