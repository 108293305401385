import React from 'react'

export default function Submit({ content }) {
    return (
        <div className="form-section submit-section no-border text-center">
            <button className="btn btn-dark btn-lg" type="submit">
                {content.title}
            </button>
        </div>
    )
}
