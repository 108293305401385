import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import SocialNetworks from '../Footer/SocialNetworks'
import Auth from '../../auth'
import './ArtistHeader.scss'
import ImageTag from '../Global/ImageTag'
import defaultUser from '../../assets/images/DAK-Profile-Pic.png'

import ShareSocials from '../../components/ShareSocials'

function ArtistHeader({ content, artistInfo, isArtist }) {
    const { coverImage, profileImage, _id, firstName, lastName } = artistInfo
    return (
        <header className="artist-header">
            <figure className="cover-image">
                {coverImage ? (
                    <ImageTag src={coverImage} alt={`${firstName} ${lastName}`} />
                ) : (
                    <div style={{ background: '#f1f1f1', height: '100%' }}></div>
                )}
            </figure>
            <div className="artist-header-content">
                <figure className={`profile-image ${isArtist ? '' : 'user-border'}`}>
                    {profileImage ? (
                        <ImageTag src={profileImage} alt={`${firstName} ${lastName}`} />
                    ) : (
                        <img src={defaultUser} alt={`${firstName} ${lastName}`} />
                    )}
                </figure>

                {isArtist && (
                    <>
                        <div className="header-links">
                            <ul className="list-unstyled artist-breadcrumps">
                                <li>
                                    <Link to="/search">Artists</Link>
                                </li>
                                <li>
                                    {firstName && lastName && (
                                        <Link to={`/artist/${_id}`}>{`${firstName} ${lastName}`}</Link>
                                    )}
                                </li>
                            </ul>

                            <div className="artist-meta">
                                {Auth.isLoggedIn() && (
                                    <div className="link-group">
                                        <button className="btn btn-clear no-padding">
                                            <span>{content.save}</span>
                                            <i className="fas fa-heart"></i>
                                        </button>
                                    </div>
                                )}
                                <div className="link-group">
                                    <SocialNetworks />
                                </div>
                                {/* <div className="link-group">
                                    <Link to="/">
                                        <i className="fas fa-external-link-alt"></i>
                                        <span>Website</span>
                                    </Link>
                                </div> */}
                            </div>
                        </div>

                        <ShareSocials />
                    </>
                )}
            </div>
        </header>
    )
}

export default ArtistHeader
