import React from 'react'

function CopyRight({ copyright, powered }) {
    return (
        <div className="footer-copyright footer-row">
            <div className="copyright-box">
                <p>
                    <span>
                        {copyright} {new Date().getFullYear()}
                    </span>{' '}
                    <b>Databaza e Artisteve te Kosoves</b>
                </p>
            </div>

            <div className="copyright-box copyright-right">
                <p>
                    <a href="http://galeriaqahili.com/">
                        {powered} <b>Galeria Qahili</b>
                    </a>
                </p>
            </div>
        </div>
    )
}

export default CopyRight
