import React from 'react'
import './Activities.scss'

export default function Activities({ activity, history }) {
    const getDate = (key) => {
        try {
            if (activity && activity.details && activity.details.length > 0 && activity.details[0][key]) {
                let date = new Date(activity.details[0][key])
                return date.getDate()
            }
        } catch (err) {
            return null;
        }
    }    
    const getMonth = (key) => {
       try {
           if (activity && activity.details && activity.details.length > 0 && activity.details[0][key]) {
                let date = new Date(activity.details[0][key])
                return date.toLocaleString('default', { month: 'long' }).substring(0, 3).toUpperCase()
           }
       } catch (err) {
           return null;
       }
    }

    const redirectToSingle = (id) => {
        history.push(`/activity/${id}`)
    }

    return (
        <div className="col-md-3">
            <article className="activity-image" onClick={() => redirectToSingle(activity._id)} style={{
                backgroundImage: activity.image ? `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url(${process.env.REACT_APP_IP + ":" + process.env.REACT_APP_API_PORT + '/' + activity.image.replace('\\', '/')})` : ''
            }}>
                <div className="date-activity">
                    <span className="day-activity">{getDate('date') !== null || getDate('date') !== undefined ? getDate('date') : ''}</span>
                    <span className="month-activity">{getMonth('date') !== null || getMonth('date') !== undefined ? getMonth('date') : ''}</span>
                </div>

                <div>
                    <div className="activity-content">
                        <span className="activity-description">{activity.name && activity.name}</span>
                    </div>
                </div>
            </article>
        </div>
    );
}