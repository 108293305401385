import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/Global/PageTitle'
import { Container, Row } from 'react-bootstrap'
import ArtistHeader from '../../components/ArtistHeader'
import ArtistDetails from '../../components/ArtistDetails'
import Artworks from '../../components/Artworks'
import { useAlert } from 'react-alert'
import Api from '../../api'
import Helpers from '../../helpers'
import SectionTitle from '../../components/Global/SectionTitle'
import Activities from '../../components/Activities/Activities'
import ExhibitionsHeaders from '../../components/Exhibitions/ExhibitionsHeaders'
import ArtistOtherInfo from '../../components/ArtistOtherInfo/ArtistOtherInfoHeader'

export default function AboutPage({ match, history }) {
    const { t } = useTranslation()
    const globalContent = t('global', { returnObjects: true })
    const artistContent = t('artistPage', { returnObjects: true })
    const [artistInfo, setArtistInfo] = useState({})
    const [previousExhibitions, setPreviousExhibitions] = useState([])
    const [nextExhibitions, setNextExhibitions] = useState([])
    const [theme, setTheme] = useState([])
    const alert = useAlert()

    useEffect(() => {
        window.scrollTo(0, 0)
        getData()
        // eslint-disable-next-line
    }, [])

    const getThemes = async ids => {
        const response = await Api.post('taxonomiesMultiple', { ids: ids })
        if (response && response.data && response.data.results) setTheme(response.data.results)
    }

    const getData = async () => {
        const response = await Api.getWithId('singleArtist', match.params.id)
        const res = Helpers.validateResponse(response, alert)
        if (!res) return
        if (typeof res.nextExhibitions === 'object' && res.nextExhibitions.length > 0)
            setNextExhibitions(res.nextExhibitions)
        if (typeof res.previousExhibitions === 'object' && res.previousExhibitions.length > 0)
            setPreviousExhibitions(res.previousExhibitions)
        if (res && res.artworks && res.artworks.length > 0) {
            let ids = []
            res.artworks.forEach(artWork => {
                if (artWork.theme) ids.push(artWork.theme)
            })
            getThemes(ids)
        }
        setArtistInfo(res)
    }

    return (
        <section>
            <Container>
                <ArtistHeader content={globalContent} artistInfo={artistInfo} isArtist={true} />
                <PageTitle
                    title={
                        artistInfo.firstName && artistInfo.lastName
                            ? `${artistInfo.firstName} ${artistInfo.lastName}`
                            : null
                    }
                />
            </Container>
            <div className="pink-background">
                <Container>
                    <ArtistDetails theme={theme} artistInfo={artistInfo} content={artistContent.fields} />

                    <PageTitle subtitle={artistInfo.work} />
                </Container>
            </div>
            <Container>
                <ArtistOtherInfo artistInfo={artistInfo} content={artistContent.fields} />
                <ExhibitionsHeaders artistInfo={artistInfo} content={artistContent.fields} />
                <Artworks artworks={artistInfo.artworks} />

                {nextExhibitions && nextExhibitions.length > 0 && (
                    <React.Fragment>
                        <SectionTitle title="Next Exhibitions" />
                        <Row>
                            {nextExhibitions.map(activity => (
                                <Activities key={activity._id} history={history} activity={activity} />
                            ))}
                        </Row>
                    </React.Fragment>
                )}
            </Container>
        </section>
    )
}
