import React, { useState, useEffect } from 'react'
import AdvancedSearch from '../../components/AdvancedSearch'
import SearchResults from '../../components/SearchResults'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import Api from '../../api/Api'
import { useAlert } from 'react-alert'
import Helpers from '../../helpers'

export default function SearchPage() {
    const { t } = useTranslation()
    const placeholderBold = t('homepage.search.placeholderBold', { returnObjects: true })
    const mediaPageContent = t('mediapage', { returnObjects: true })
    const [term, setTerm] = useState('')
    const [selectedTerm, setSelectedTerm] = useState('')
    const [updateData, setUpdateData] = useState(false)
    const [dropdownValue, setDropdownValue] = useState('')
    const [results, setResults] = useState([])
    const [artistCount, setArtistCount] = useState()
    /* States below are necessary for search results filter */
    const [artStyleSelected, setArtStyleSelected] = useState([])
    const [mediumsSelected, setMediumsSelected] = useState([])
    const [citySelected, setCitySelected] = useState([])
    const [artistTypeSelected, setArtistTypeSelected] = useState([])
    const [artTypeSelected, setArtTypeSelected] = useState([])
    const [birthPlaceSelected, setBirthPlaceSelected] = useState([])
    const [musicCategorySelected, setMusicCategorySelected] = useState([])
    const [musicGenreSelected, setMusicGenreSelected] = useState([])
    const [dramaticGenreSelected, setDramaticGenreSelected] = useState([])
    const [dramaticCategorySelected, setDramaticCategorySelected] = useState([])
    const [themesSelected, setThemesSelected] = useState([])
    /* End search states results */
    const [similarWord, setSimilarWord] = useState(null)
    const location = useLocation()
    const alert = useAlert()

    useEffect(() => {
        window.scrollTo(0, 0)
        getData()
        getArtistCount()

        if (location.state !== undefined) {
            setTerm(location.state.term)
            setUpdateData(true)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [updateData])

    const getArtistCount = async () => {
        const resp = await Api.get('artistsCount')
        const res = Helpers.validateResponse(resp, alert)
        if (!res) return
        setArtistCount(res)
    }

    async function getData(termParam = false) {
        let response
        if ((term !== '' && term.length !== 0) || dropdownValue !== '') {
            if (termParam) {
                setTerm(termParam)
                setSelectedTerm(termParam)
                setSimilarWord(null)
            }
            response = await Api.post('search', {
                term: termParam ? termParam : `${term}`,
                ARTSTYLES: artStyleSelected,
                MEDIUMS: mediumsSelected,
                city: citySelected,
                type: artTypeSelected,
                artistType: artistTypeSelected,
                MUSIC_CATEGORY: musicCategorySelected,
                MUSIC_GENRE: musicGenreSelected,
                DRAMATIC_CATEGORY: dramaticCategorySelected,
                DRAMATIC_GENRE: dramaticGenreSelected,
                THEMES: themesSelected,
            })
            if (response && response.data && response.data.similarWord) {
                setSimilarWord(response.data.similarWord)
            }
            const results = Helpers.validateResponse(response, alert)
            if (results) setResults(results)
            else setResults([])
        }
    }

    return (
        <div>
            <AdvancedSearch
                content={mediaPageContent}
                placeholderBold={placeholderBold}
                terms={[term, setTerm]}
                setArtStyleSelected={setArtStyleSelected}
                setBirthPlaceSelected={setBirthPlaceSelected}
                artStyleSelected={artStyleSelected}
                setMusicCategorySelected={setMusicCategorySelected}
                setMusicGenreSelected={setMusicGenreSelected}
                setDramaticGenreSelected={setDramaticGenreSelected}
                setDramaticCategorySelected={setDramaticCategorySelected}
                setThemesSelected={setThemesSelected}
                setCitySelected={setCitySelected}
                setMediumsSelected={setMediumsSelected}
                setArtistTypeSelected={setArtistTypeSelected}
                selectedTerm={selectedTerm}
                setSelectedTerm={setSelectedTerm}
                artTypeSelected={artTypeSelected}
                setArtTypeSelected={setArtTypeSelected}
                dropdown={[dropdownValue, setDropdownValue]}
                getData={getData}
                alert={alert}
            />
            <SearchResults
                similarWord={similarWord}
                mediaPageContent={mediaPageContent}
                terms={[term, setTerm]}
                getData={getData}
                selectedTerm={selectedTerm}
                setSelectedTerm={setSelectedTerm}
                results={[results, setResults]}
                artistCount={artistCount}
            />
        </div>
    )
}
