import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { LanguageContext } from '../../contexts/LanguageContext'
import ImageTag from '../../components/Global/ImageTag'
import defaultImage from '../../assets/images/DAK-Profile-Pic.png'
import Api from '../../api/Api'
import Helpers from '../../helpers/Helpers'
import { useAlert } from 'react-alert'
import { AuthContext } from '../../contexts/AuthContext'

export default function Result({ mediaPageContent, data, artistType }) {
    // TODO: check style var
    // eslint-disable-next-line
    const { view_profile, save, style, theme, birthday, birthplace } = mediaPageContent
    const { user, getCurrentUser } = useContext(AuthContext)
    const {
        firstName,
        lastName,
        medium,
        // TODO: check artstyle var
        // eslint-disable-next-line
        artstyle,
        dateOfBirth,
        cityCountryOfOrigin,
        profileImage,
        _id,
        education,
    } = data

    const currentLanguage = useContext(LanguageContext)
    let eduInfo = {
        institution: '',
        location: '',
    }

    const alert = useAlert()

    const saveArtist = async () => {
        const body = {
            artistID: data._id,
        }
        await Helpers.validateResponse(await Api.post('saveArtist', body), alert)
        await getCurrentUser()
        alert.success('Artist has been saved successfully!')
    }

    if (education && education.length !== 0) {
        if (education[0].institution !== undefined) {
            eduInfo.institution = education[education.length - 1].institution
        }
        if (education[0].location !== undefined) {
            eduInfo.location = education[education.length - 1].location
        }
    }

    return (
        <article className="artist-post">
            <figure>
                {profileImage ? (
                    <ImageTag src={profileImage} alt={`${firstName} ${lastName}`} />
                ) : (
                    <img src={defaultImage} alt="default" />
                )}
            </figure>
            <div className="artist-content">
                <header>
                    <h3>
                        {firstName} {lastName}
                    </h3>
                    <ul className="list-unstyled artist-profesion">
                        {medium &&
                            Array.isArray(medium) &&
                            medium.length > 0 &&
                            medium.map((med, i) => <li key={i}>{med[currentLanguage.language]}</li>)}
                    </ul>
                </header>
                <div className="artist-details">
                    <div className="details-column table-view">
                        <div className="details-cell">
                            <Link to={`/artist/${_id}`}>
                                <span>{view_profile}</span>
                                <i className="fal fa-user-circle"></i>
                            </Link>
                        </div>

                        {user && (
                            <div onClick={() => saveArtist()} className="details-cell">
                                <button className="btn btn-clear">
                                    <span>{save}</span>
                                    <i className="fas fa-heart"></i>
                                </button>
                            </div>
                        )}

                        {/* <div className="details-cell">
                            <button className="btn btn-clear">
                                <span>Compare</span>
                                <i className="fas fa-heart"></i>
                            </button>
                        </div> */}
                    </div>

                    <div className="details-column">
                        {eduInfo.institution && (
                            <dl className="details-cell">
                                <dt>{mediaPageContent.education}</dt>
                                <div className="dd-wrapper">{eduInfo.institution}</div>
                            </dl>
                        )}

                        {data.artistType && (
                            <dl className="details-cell">
                                <dt>Artist Type</dt>
                                <dd>{data && typeof data === 'object' && data.artistType ? data.artistType : ''}</dd>
                            </dl>
                        )}
                    </div>

                    <div className="details-column">
                        {dateOfBirth && (
                            <dl className="details-cell">
                                <dt>{birthday}</dt>
                                <dd>{dateOfBirth}</dd>
                            </dl>
                        )}

                        {cityCountryOfOrigin && (
                            <dl className="details-cell">
                                <dt>{birthplace}</dt>
                                <dd>{cityCountryOfOrigin}</dd>
                            </dl>
                        )}
                    </div>
                </div>
            </div>
        </article>
    )
}
