import React from 'react'
//import React, { useContext } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Auth from '../../auth'
import ImageTag from '../Global/ImageTag'
//import { LanguageContext } from '../../contexts/LanguageContext'

function MobileFooter({ menu, loginState, setExpand }) {
    const { join, account, login } = menu
    const toggleLogin = e => {
        loginState.funk(!loginState.value)
        setExpand(false)
    }

    const userDataJson = sessionStorage.getItem('USER_DATA')
    const userData = JSON.parse(userDataJson)

    const closeNav = () => {
        setExpand(false)
    }

    return (
        <>
            <ul className="list-inline list-unstyled share-links d-sm-flex d-md-flex d-lg-none">
                <li className="list-inline-item">
                    <a
                        href="https://www.facebook.com/GaleriaQahili"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-icon"
                        onClick={closeNav}
                    >
                        <i className="fab fa-facebook-f"></i>
                    </a>
                </li>
                <li className="list-inline-item">
                    <a
                        href="https://www.instagram.com/galeriaqahili"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-icon"
                        onClick={closeNav}
                    >
                        <i className="fab fa-instagram"></i>
                    </a>
                </li>
            </ul>
            <div className="site-menu d-sm-flex d-md-flex d-lg-none">
                <Link to="/join-user" onClick={closeNav}>
                    <i className="fal fa-user-plus"></i>
                    {join}
                </Link>

                {Auth.isLoggedIn() ? (
                    <DropdownButton size="sm" title={account} className="dropdown-right">
                        <Dropdown.Item eventKey="1" className="profile">
                            <figure className="profile-image">
                                <ImageTag
                                    src={userData.profileImage}
                                    alt={`${userData.firstName} ${userData.lastName}`}
                                />
                            </figure>{' '}
                            <span>
                                Hi <b>{userData.firstName}</b>
                            </span>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey="2" as={Link} to="/profile">
                            Profile
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="3" as={Link} to="/saved">
                            Saved
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                            eventKey="3"
                            onClick={() => {
                                Auth.logout()
                            }}
                        >
                            Sign Out
                        </Dropdown.Item>
                    </DropdownButton>
                ) : (
                    <button onClick={toggleLogin} className="btn btn-menu">
                        <i className="fal fa-sign-in"></i>
                        {login}
                    </button>
                )}
            </div>
        </>
    )
}
export default MobileFooter
