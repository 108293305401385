import React, { useState } from 'react'
import { Form, Col } from 'react-bootstrap'
import Select from 'react-select'
import Helpers from '../../../helpers'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const EducationFields = ({ education, updateEducationFields }) => {
    const levelOptions = [
        { value: 'BACHELOR', label: 'Bachelor' },
        { value: 'MASTER', label: 'Master' },
        { value: 'POST_GRADUATE', label: 'Post Graduate' },
        { value: 'HIGH_SCHOOL', label: 'High School' },
    ]

    const selectStyles = Helpers.getSelectStyles()
    const initialValue = {
        level: '',
        location: '',
        institution: '',
        yearOfEducation: '',
        department: '',
    }

    const [educationFields, setEducationFields] = useState([initialValue])
    const [displayEducationExactDate, setDisplayEducationExactDate] = useState([
        { index: 0, bool: false, selected: false },
    ])

    const addFieldGroup = e => {
        e.preventDefault()
        setEducationFields([...educationFields, initialValue])
    }

    const removeFieldGroup = (e, i) => {
        e.preventDefault()
        let tempFields = educationFields
        tempFields.splice(i, 1)
        setEducationFields([...tempFields])
        updateEducationFields(educationFields)
    }

    const handleSelectChange = (selected, meta, i) => {
        let temp = [...educationFields]
        temp[i][meta.name] = selected.value
        setEducationFields(temp)
        updateEducationFields(educationFields)
    }

    const updateValue = (e, i) => {
        let temp = [...educationFields]
        temp[i][e.target.name] = e.target.value
        setEducationFields(temp)
        updateEducationFields(educationFields)
    }

    const handleChangeDate = (date, index) => {
        let temp = [...displayEducationExactDate]
        temp[index]['selected'] = date
        setDisplayEducationExactDate(temp)
    }

    const handleEducationExactDate = (e, index) => {
        let temp = [...displayEducationExactDate]
        if (!temp[index]) {
            let obj = { index: index, bool: false }
            temp.push(obj)
        }
        temp.forEach((obj, i) => {
            if (i === index) {
                obj['bool'] = !obj['bool']
            }
        })
        setDisplayEducationExactDate(temp)
    }

    const generateEducationFields = () => {
        return (
            <>
                {educationFields &&
                    educationFields.map((fields, i) => {
                        return (
                            <div className="field-group-wrapper" key={i}>
                                <Form.Row>
                                    <span
                                        className="remove-field-group"
                                        onClick={e => {
                                            removeFieldGroup(e, i)
                                        }}
                                    >
                                        <i className="fal fa-times"></i>
                                    </span>
                                    <Col md="6" sm="12">
                                        <Form.Group>
                                            <Select
                                                options={levelOptions}
                                                styles={selectStyles}
                                                name="level"
                                                onChange={(selected, meta) => {
                                                    handleSelectChange(selected, meta, i)
                                                }}
                                                placeholder={education.level}
                                            />
                                            <div className="form-label">
                                                <i className="fal fa-user-graduate"></i>
                                                <span className="required-field"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    <Col md="6" sm="12">
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                name="location"
                                                value={fields['location']}
                                                onChange={e => {
                                                    updateValue(e, i)
                                                }}
                                                placeholder={education.location}
                                            />
                                            <div className="form-label">
                                                <i className="fal fa-map-marker-check"></i>
                                                <span className="required-field"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col md="4" sm="12">
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                name="department"
                                                value={fields['department']}
                                                onChange={e => {
                                                    updateValue(e, i)
                                                }}
                                                placeholder={education.department}
                                            />
                                            <div className="form-label">
                                                <i className="fal fa-university"></i>
                                                <span className="required-field"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    <Col md="4" sm="12">
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                name="institution"
                                                value={fields['institution']}
                                                onChange={e => {
                                                    updateValue(e, i)
                                                }}
                                                placeholder={education.institution}
                                            />
                                            <div className="form-label">
                                                <i className="fal fa-university"></i>
                                                <span className="required-field"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="4" sm="12">
                                        <Form.Group>
                                            {displayEducationExactDate &&
                                            displayEducationExactDate[i] &&
                                            displayEducationExactDate[i]['bool'] ? (
                                                <React.Fragment>
                                                    <DatePicker
                                                        dateFormat={'dd-MM-yyyy'}
                                                        showYearDropdown={true}
                                                        selected={displayEducationExactDate[i]['selected']}
                                                        onChange={date => handleChangeDate(date, i)}
                                                        name="yearOfEducation"
                                                        placeholderText={education.exactDate}
                                                    />
                                                    <div className="form-label">
                                                        <i className="fal fa-calendar-day"></i>
                                                        <span className="required-field"></span>
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <Form.Control
                                                        type="text"
                                                        name="yearOfEducation"
                                                        value={fields['yearOfEducation']}
                                                        onChange={e => {
                                                            updateValue(e, i)
                                                        }}
                                                        placeholder={education.year}
                                                    />
                                                    <div className="form-label">
                                                        <i className="fal fa-calendar-day"></i>
                                                        <span className="required-field"></span>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </Form.Group>
                                        <div className="education-exact-date-parent">
                                            <Form.Check
                                                custom
                                                type="checkbox"
                                                onChange={e => handleEducationExactDate(e, i)}
                                                label={education.exactDate}
                                            />
                                        </div>
                                    </Col>
                                </Form.Row>
                            </div>
                        )
                    })}
            </>
        )
    }

    return (
        <>
            {generateEducationFields()}
            <Form.Row>
                <Col className="text-center">
                    <button className="btn btn-clear btn-add" onClick={e => addFieldGroup(e)}>
                        <i class="fal fa-plus-circle"></i>
                        ADD EDUCATION
                    </button>
                </Col>
            </Form.Row>
        </>
    )
}

export default EducationFields
