

import React from "react"
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, PinterestShareButton, TwitterShareButton } from "react-share";

export default function ShareSocials() {
    return (
        <>
            <div className="share-socials mobile-view">
                <h6 className="share-socials-title">share</h6>
                <ul>
                    <li>
                        <FacebookShareButton url={window.location.href} title="Facebook">
                            <button className="btn btn-circle">
                                <i class="fab fa-facebook-f"></i>
                            </button>
                        </FacebookShareButton>
                    </li>
                </ul>

                <ul>
                    <li>
                        <TwitterShareButton url={window.location.href} title={"Twitter"}>
                            <button className="btn btn-circle">
                                <i className="fab fa-twitter"> </i>
                            </button>
                        </TwitterShareButton>
                    </li>
                </ul>

                <ul>
                    <li>
                        <LinkedinShareButton url={window.location.href} title={"Linkedin"}>
                            <button className="btn btn-circle">
                                <i className="fab fa-linkedin-in"> </i>
                            </button>
                        </LinkedinShareButton>
                    </li>
                </ul>

                <ul>
                    <li>
                        <PinterestShareButton url={window.location.href} title={"Pinterest"}>
                            <button className="btn btn-circle">
                                <i className="fab fa-pinterest-p"> </i>
                            </button>
                        </PinterestShareButton>
                    </li>
                </ul>

                <ul>
                    <li>
                        <EmailShareButton url={window.location.href} title={"Email"}>
                            <button className="btn btn-circle">
                                <i class="far fa-envelope"></i>
                            </button>
                        </EmailShareButton>
                    </li>
                </ul>

                <ul>
                    <li>
                        <button className="btn btn-circle">
                            <i class="fal fa-copy" style={{ cursor: 'pointer' }} onClick={async () => {
                                    await navigator.clipboard.writeText(window.location.href)
                            }}></i>
                        </button>
                    </li>
                </ul>
            </div>
        </>
        
    )
}