import React from 'react'
import { Exhibition, ExhibitionTitle, LoadMore } from './ExhibitionPost/index'

export default function Previous() {
    return (
        <React.Fragment>
            <ExhibitionTitle  />
            {[1, 2, 3].map((el, index) => {
                return <Exhibition key={index} />
            })}
            <LoadMore />
        </React.Fragment>
    );
}