import React from 'react'
import Menu from './Menu'
import Sponsors from './Sponsors'
import CopyRight from './CopyRight'
import './Footer.scss'

function Footer({ footerContent, nav }) {
    const { info, copyright, powered } = footerContent
    return (
        <footer id="site-footer">
            <div className="container">
                <Sponsors info={info} />
                <Menu nav={nav} />
                <CopyRight copyright={copyright} powered={powered} />
            </div>
        </footer>
    )
}

export default Footer
