import React, { useContext } from 'react'
import SectionTitle from '../Global/SectionTitle'
import { Row, Col } from 'react-bootstrap'
import { LanguageContext } from '../../contexts/LanguageContext'
import './ArtistDetails.scss'
import { useEffect } from 'react'
import { useState } from 'react'

function ArtistDetails({ content, artistInfo, theme }) {
    const currentLanguage = useContext(LanguageContext)
    const { language } = currentLanguage
    const { shortBio, dateOfBirth, dateOfDeath, city, country, livesAndWorks, artistType } = artistInfo

    const [artStyles, setArtStyles] = useState([])

    useEffect(() => {
        if (artistInfo && artistInfo.taxonomies && artistInfo.taxonomies.length > 0) {
            let tempArtStyles = artistInfo.taxonomies.filter(tax => {
                return tax.type === 'ARTSTYLES'
            })
            setArtStyles(tempArtStyles)
        }
    }, [artistInfo])

    console.log(shortBio)

    return (
        <section className="artist-details">
            <Row>
                <Col lg="6" md="12" className="list-col-left">
                    <ul className="artist-details-full list-unstyled">
                        {dateOfBirth && (
                            <li>
                                <dt>{content.birthday}</dt>
                                <dd>{dateOfBirth}</dd>
                            </li>
                        )}
                        {city && country && (
                            <li>
                                <dt>{content.birthplace}</dt>
                                {city && country && <dd>{`${city}${city && country && ','} ${country}`}</dd>}
                            </li>
                        )}
                        {livesAndWorks && (
                            <li>
                                <dt>{content.basedIn}</dt>
                                <dd>
                                    {livesAndWorks && livesAndWorks.map((place, i) => <span key={i}>{place} </span>)}
                                </dd>
                            </li>
                        )}
                        {dateOfDeath && (
                            <li>
                                <dt>{content.dateOfDeath}</dt>
                                <dd>{dateOfDeath}</dd>
                            </li>
                        )}
                    </ul>
                </Col>

                <Col lg="6" md="12" className="list-col-right">
                    <ul className="artist-details-full list-unstyled">
                        {artStyles.length !== 0 && (
                            <li>
                                <dt>{content.artstyle}</dt>
                                <dd>
                                    {artStyles.length > 0 &&
                                        artStyles.map((art, i) => <span key={i}>{art[language]} </span>)}
                                </dd>
                            </li>
                        )}
                        {theme && theme[0] && theme[0].en && (
                            <li>
                                <dt>{content.themes}</dt>
                                <dd>{theme && theme[0] && theme[0].en ? theme[0].en : ''}</dd>
                            </li>
                        )}
                        {artistType != 0 && (
                            <li>
                                <dt>Artist type</dt>
                                <dd>
                                    {' '}
                                    {artistType &&
                                        typeof artistType === 'string' &&
                                        artistType.length > 0 &&
                                        artistType}{' '}
                                </dd>
                            </li>
                        )}
                        {/* <li>
                            <dt>{content.education.level}</dt>
                            <dd>{education && education.length !== 0 && education[education.length - 1].level}</dd>
                        </li>
                        <li>
                            <dt>{content.education.institution}</dt>
                            <dd>
                                {education &&
                                    education.length !== 0 &&
                                    education.map((ed, i) => (
                                        <span key={i}>{ed && ed.institution && ed.institution} </span>
                                    ))}
                            </dd>
                        </li>
                        <li>
                            <dt>{content.education.location}</dt>
                            <dd>
                                {education &&
                                    education.length !== 0 &&
                                    education.map((ed, i) => (
                                        <span key={i}>{education.length === i + 1 && ed.location} </span>
                                    ))}
                            </dd>
                        </li>
                         */}
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col sm="12" className="biography-text">
                    <div className="text-center">
                        {shortBio && shortBio[language] && (
                            <>
                                <SectionTitle title="Biography" subtitle="" />
                                <p>{shortBio[language]}</p>
                            </>
                        )}
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default ArtistDetails
