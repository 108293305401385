import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/Global/PageTitle'
import { Container, Row, Col } from 'react-bootstrap'
import ArtistHeader from '../../components/ArtistHeader'
import { Redirect } from 'react-router-dom'
import UserForm from '../../components/Join/UserForm/UserForm'
import Api from '../../api'
import Helpers from '../../helpers'
import { useAlert } from 'react-alert'
import Auth from '../../auth'
import Tabs from "./Tabs"
import SavedArtists from "../../components/SavedArtists"
import Password from "./Password"

import Personal from "./Personal"
import { useContext } from 'react'
import { AuthContext } from '../../contexts/AuthContext'

export default function Profile({ match }) {
    const { t } = useTranslation()
    const joinContent = t('joinpage', { returnObjects: true })
    const profileContent = t('profilepage', { returnObjects: true })
    const { userform } = joinContent
    const [userInfo, setUserInfo] = useState({})
    const [activeTab, setActiveTab] = useState(0)

    const { user, getCurrentUser } = useContext(AuthContext)

    const alert = useAlert()

    useEffect(() => {
        window.scrollTo(0, 0)
        getData()
        // eslint-disable-next-line
    }, [])

    const submit = async (data) => {

    }

    const getData = () => {
        try {
            const userDataJson = sessionStorage.getItem('USER_DATA')
            const userData = JSON.parse(userDataJson)
            setUserInfo(userData)
        } catch (err) {
            setUserInfo(null)
        }
    }

    const deleteAccount = async () => {
        const response = await Api.delete('deleteAccount', { id: userInfo._id })
        const res = Helpers.validateResponse(response, alert, profileContent.success_delete)
        if (res) {
            Auth.logout()
        }
    }

    if (!userInfo) {
        return <Redirect to={{ pathname: '/' }} />
    }

    return (
        <section>
            <Container>
                <ArtistHeader artistInfo={userInfo} isArtist={false} />
                {userInfo && userInfo.subscriptionDetails && userInfo.subscriptionDetails.subscription && (
                    <PageTitle
                        title={`${userInfo.firstName} ${userInfo.lastName}`}
                        subtitle={`User · ${userInfo.subscriptionDetails.subscription.name}`}
                    />
                )}
                <Tabs getActiveTab={tab => setActiveTab(tab)} />

                {
                    activeTab === 0 ? (
                        <>
                            <div className="user-profile-tabs">
                                <Row>
                                    <Col md="12">
                                        <Personal user={user} getCurrentUser={getCurrentUser} content={userform.personal}/>
                                        <Password user={user} getCurrentUser={getCurrentUser} />
                                        {/* <UserForm form={userform} editDetails={true} userInfo={userInfo} />

                                        <div className="danger-zone">
                                            <button className="btn btn-lg btn-danger" onClick={deleteAccount}>
                                                Delete Account
                                            </button>
                                        </div> */}
                                    </Col>
                                </Row>
                            </div>
                        </>
                    )
                        : (
                            <>
                                <SavedArtists user={userInfo} />
                            </>
                        )
                }
            </Container>
        </section>
    )
}
