import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/Global/PageTitle'
import { Container, Row, Col } from 'react-bootstrap'

export default function AboutPage() {
    const { t } = useTranslation()
    const faqContent = t('faqPage', { returnObjects: true })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section className="about-page-description text-center">
                <Container>
                    <PageTitle title={faqContent.title} subtitle={faqContent.subtitle} />
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            {faqContent.mainContent.map((item, i) => {
                                return (
                                    <div key={i} className="part">
                                        <h5>{item.question}</h5>
                                        <p>{item.answer}</p>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* data */}
            <section className="why-do-we-exist text-center">
                <Container>
                    <h4 style={{ fontWeight: 'bold' }}>{faqContent.data}</h4>
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            {faqContent.dataContent.map((item, i) => {
                                return (
                                    <div key={i} className="faq-part">
                                        <h5>{item.question}</h5>
                                        <p>{item.answer}</p>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* account */}
            <section className="about-page-description text-center">
                <Container>
                    <h4 style={{ fontWeight: 'bold' }}>{faqContent.account}</h4>
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            {faqContent.accountContent.map((item, i) => {
                                return (
                                    <div key={i} className="faq-part">
                                        <h5>{item.question}</h5>
                                        <p>{item.answer}</p>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* artist */}
            <section className="why-do-we-exist text-center">
                <Container>
                    <h4 style={{ fontWeight: 'bold' }}>{faqContent.artist}</h4>
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            {faqContent.artistContent.map((item, i) => {
                                return (
                                    <div key={i} className="faq-part">
                                        <h5>{item.question}</h5>
                                        <p>{item.answer}</p>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* other */}
            <section className="about-page-description text-center">
                <Container>
                    <h4 style={{ fontWeight: 'bold' }}>{faqContent.other}</h4>
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            {faqContent.otherContent.map((item, i) => {
                                return (
                                    <div key={i} className="faq-part">
                                        <h5>{item.question}</h5>
                                        <p>{item.answer}</p>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
