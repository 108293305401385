import React from 'react'

let imgSrc = `${process.env.REACT_APP_IP}:${process.env.REACT_APP_API_PORT}`
//let imgSrc = `https://dak.pristine-tech.com/${src}`

function ImageTag({ src, alt, className }) {
    return <img className={className ? className : ''} src={`${imgSrc}/${src}`} alt={alt ? alt : ''} />
}

export default ImageTag
