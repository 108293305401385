import React from 'react'
import { Col } from 'react-bootstrap'
import arrowIcon from '../../assets/images/howitworks_arrow.svg'
import './Service.scss'

export default function Service({ title, description, icon, arrow, alternative }) {
    return (
        <Col md={4} sm="12">
            <article className={`service ${alternative && 'alternative-layout'}`}>
                <figure>
                    <img src={icon} alt={title} />
                </figure>
                <div className="service-content">
                    <h4 className="service-title">{title}</h4>
                    {alternative ? (
                        <ul className="list-unstyled">
                            {description.map((d, i) => (
                                <li key={i}>{d}</li>
                            ))}
                        </ul>
                    ) : (
                        <p>{description}</p>
                    )}
                </div>
                {arrow && <img className={`arrow ${arrow}`} src={arrowIcon} alt="how it works" />}
            </article>
        </Col>
    )
}
