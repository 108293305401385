import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import '../../components/News/News.scss'
import News from '../../components/News'
import LoadMore from '../../components/Media/ExhibitionPost/LoadMore'
import { useTranslation } from 'react-i18next'
import Api from '../../api'
import Helpers from '../../helpers'

export default function NewsPage() {
    const { t } = useTranslation()
    const mediaPageContent = t('mediapage', { returnObjects: true })
    const blogContent = t('blogpage', { returnObjects: true })
    const [page, setPage] = useState(1);
    const [posts, setPosts] = useState([])
    const [blogTypeSelected, setBlogTypeSelected] = useState('*');
    const [blogCategories, setBlogCategories] = useState([])
    const [categorySelected, setCategorySelected] = useState(null);
    const [loading, setLoading] = useState(false)
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true)

    useEffect(() => {
        ;(async () => {
            let res = await getPosts()
            setPosts(res)
        })()
        fetchAllBlogCategories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setShowLoadMoreButton(true) /* When category selected */
        getPosts(false, categorySelected, true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categorySelected])


    const fetchAllBlogCategories = async () => {
        let tempArr = []
        const response = await Api.get('blogCategories');
        if (response && response.data && response.data.results && response.data.results.length > 0) {
            tempArr = response.data.results.map(category => {
                return { value: category._id, label: category.en }
            })
        }
       tempArr.push({ value: false, label: 'All' }) 
       setBlogCategories(tempArr)
    }

    const getPosts = async (pageHasReset = false, category = false, forceRender = false) => {
        let params = { page: pageHasReset ? pageHasReset : page }
        if (blogTypeSelected) params['type'] = blogTypeSelected;
        if (category) params['category'] = category
        const response = await Api.get('blogs', params )
        if (forceRender && response && response.data && response.data.results && response.data.results.length > 0) {
            setPosts(response.data.results) 
        }
        return Helpers.validateResponse(response, alert)
    }

    const loadMorePosts = async () => {
        const response = await Api.get('blogs', { page: page, type: blogTypeSelected, category: categorySelected })
        if (response && response.data && response.data.results && response.data.results.length > 0) {
            setPosts([ ...posts, ...response.data.results ])
        }
        else setShowLoadMoreButton(false)
        setLoading(false)
    }

    useEffect(() => {
        if (page === 1) {
            setLoading(false)
            return
        }
        loadMorePosts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPage, page])

    useEffect(() => {
        setPage(1);
        // setShowLoadMoreButton(true)
        ;(async () => {
            let newPosts = await getPosts(1, categorySelected)
            if (newPosts && newPosts.length) setShowLoadMoreButton(true)
            setPosts(newPosts)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blogTypeSelected])

    const filterData = async value => setBlogTypeSelected(value);
    
    return (
        <Container>
            <News filterData={filterData} blogContent={blogContent} setCategorySelected={setCategorySelected} blogCategories={blogCategories} mediaPageContent={mediaPageContent} posts={posts} />
            {
                (showLoadMoreButton && typeof posts === "object" && posts.length > 9)
                    && <LoadMore 
                        loading={loading} 
                        onClick={() => {
                            setLoading(true)
                            setPage(page + 1)
                        }
                    } /> 
            }
        </Container>
    )
}
