import React from 'react'
export default function Exhibitions(props) {
    return ( 
        
            <div className="col-12 post-blog-exhibitions">
                <div className="row">
                    <div className="col-4 d-flex justify-content-space-between">
                        <div className="img-post-exhibitions">
                            <img className="img-post" src={require('../../../assets/images/default_cover_image.jpg')} alt="" />
                        </div>
                        <div className="calendar">
                            <div className="box-1-media">
                                <p className="data-nr">17</p>
                            </div>
                            <div className="box-2-media">
                                <p className="month">FEB</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="city-and-title">
                            <p className="city-and-name">Prishtine  ·  Galeria Qahili</p>
                            <p className="text-title-exhibitions">The Legends of Black Girl’s Window</p>
                        </div>
                    </div>
                </div>
            </div>
    )
}