import React, { useEffect, useState } from 'react'
import { PreviousExhibitions, UpComingExhibitions } from '../../components/Media/index'
import { Container } from 'react-bootstrap'
import '../../components/Media/Media.scss'
import { useLocation } from 'react-router-dom'
import { useAlert } from 'react-alert'
import Api from '../../api'
import Helpers from '../../helpers'

export default function MediaPage() {
    const [term, setTerm] = useState('')
    const [updateData, setUpdateData] = useState(false)
    const [dropdownValue] = useState('')
    const [setResults] = useState([])
    const [setArtistCount] = useState()
    const location = useLocation()
    const alert = useAlert()

    useEffect(() => {
        window.scrollTo(0, 0)
        getData()
        getArtistCount()

        if (location.state !== undefined) {
            setTerm(location.state.term)
            setUpdateData(true)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [updateData])

    const getArtistCount = async () => {
        const resp = await Api.get('artistsCount')
        const res = Helpers.validateResponse(resp, alert)
        if (!res) return
        setArtistCount(res)
    }

    async function getData() {
        let response
        if ((term !== '' && term.length !== 0) || dropdownValue !== '') {
            response = await Api.post('search', { term: `${term} ${dropdownValue}` })
            const results = Helpers.validateResponse(response, alert)
            if (results) setResults(results)
            else setResults([])
        }
    }

    return (
        <Container>
            <PreviousExhibitions data={[]} />
            <UpComingExhibitions title="Upcoming Exhibitions" data={[]} />
        </Container>
    )
}
