import React, { useContext } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import ArtistOtherInfoList from './ArtistOtherInfoList'
import './ArtistOtherInfo.scss'
import { LanguageContext } from '../../contexts/LanguageContext'

export default function JoinHeaders({ artistInfo, content }) {
    const { language } = useContext(LanguageContext)
    return (
        <header className="paper-view-header text-center">
            <Tabs id="uncontrolled-tab-example">
                {artistInfo.education && artistInfo.education.length > 0 && (
                    <Tab eventKey="education" title={content.education.title}>
                        <ArtistOtherInfoList content={artistInfo.education} />
                        {artistInfo.education.map(item => {
                            let completeEducationString = ''
                            if (item.level.length) {
                                completeEducationString += item.level + ' - '
                            }
                            if (item.yearOfEducation.length) {
                                completeEducationString += item.yearOfEducation + ' - '
                            }
                            if (item.department.length) {
                                completeEducationString += item.department + ' - '
                            }
                            if (item.institution.length) {
                                completeEducationString += item.institution + ' - '
                            }
                            if (item.location.length) {
                                completeEducationString += item.location
                            }
                            return <p>{completeEducationString}</p>
                        })}
                    </Tab>
                )}
                {artistInfo.prizesWon && artistInfo.prizesWon[language] && artistInfo.prizesWon[language].length > 0 && (
                    <Tab eventKey="awards" title={content.awards}>
                        <ArtistOtherInfoList content={artistInfo.prizesWon} />
                    </Tab>
                )}
                {artistInfo.residencies && artistInfo.residencies.length > 0 && (
                    <Tab eventKey="residencies" title={content.residenciesTitle}>
                        {artistInfo.residencies.map(item => {
                            let completeResidencyString = ''
                            if (item.year.length) {
                                completeResidencyString += item.year + ' - '
                            }
                            if (item.name.length) {
                                completeResidencyString += item.name + ' - '
                            }
                            if (item.place.length) {
                                completeResidencyString += item.place
                            }
                            return <p>{completeResidencyString}</p>
                        })}
                    </Tab>
                )}
                {artistInfo.associations && artistInfo.associations[language] && artistInfo.associations[language].length > 0 && (
                    <Tab eventKey="associations" title={content.associations}>
                        <ArtistOtherInfoList content={artistInfo.associations} />
                    </Tab>
                )}
                {artistInfo.publications && artistInfo.publications[language] && artistInfo.publications[language].length > 0 && (
                    <Tab eventKey="publications" title={content.publications}>
                        <ArtistOtherInfoList content={artistInfo.publications} />
                    </Tab>
                )}
                {artistInfo.facts && artistInfo.facts[language] && artistInfo.facts[language].length > 0 && (
                    <Tab eventKey="facts" title={content.facts}>
                        <ArtistOtherInfoList content={artistInfo.facts} />
                    </Tab>
                )}
            </Tabs>
        </header>
    )
}
