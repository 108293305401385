import React from 'react';
import Spinner from 'react-bootstrap/Spinner'
export default function({ onClick, loading }) {
    return (
        <div className="button-load-more">
            <button className="button-load-more-post" disabled={loading} onClick={onClick}>
                {
                    loading 
                        ?<Spinner animation="border" style={{ color: 'black' }} size="sm">
                        </Spinner>
                        : "Load More"
                }
            </button>
        </div>
    );
}