import React, { useState } from 'react'
import './Exhibitions.scss'

export default function ExhibitionsList({ content }) {
    const [showMore, setShowMore] = useState(false)

    const [showMoreNumber, setShowMoreNumber] = useState(10)
    const handleClick = () => {
        setShowMore(true)
        setShowMoreNumber(showMoreNumber + 10)
    }

    const numberOfItems = showMore ? showMoreNumber : 10

    const showHideClasses = showMoreNumber > content.length ? 'hide' : 'show'

    return (
        <>
            <ul class="artist-details-list">
                {content &&
                    content.slice(0, numberOfItems).map(item => (
                        <li>
                            {item.name}, {item.description}
                        </li>
                    ))}
            </ul>
            <button className={`btn btn-clear btn-shadow ${showHideClasses}`} onClick={() => handleClick()}>Show more</button>
        </>
    )
}
