import React, { useState } from 'react'
import SearchHeader from './SearchHeader'
import Result from './Result'
import { Container } from 'react-bootstrap'
import Auth from '../../auth'
import { Link } from 'react-router-dom'
import './SearchResults.scss'
import bluryArtist1 from '../../assets/images/blury_artist1.jpg'
import bluryArtist2 from '../../assets/images/blury_artist2.jpg'
import BlackLogo from '../../assets/images/databaza_icon.png'

export default function SearchResults({ mediaPageContent, results, terms, artistCount, selectedTerm, setSelectedTerm, similarWord, getData }) {
    const [result] = results
    const [term] = terms

    const [lightbox, setLightbox] = useState(true)
    const handleHideLightbox = () => {
        setLightbox(false)
    }

    return (
        <div className="search-results">
            <SearchHeader
                mediaPageContent={mediaPageContent}
                similarWord={similarWord}
                terms={terms}
                selectedTerm={selectedTerm}
                count={result.length}
                getData={getData}
                artistCount={artistCount}
                />
            <Container className="mb-5">
                {result && Array.isArray(result) && result.length !== 0 ? (
                    <>
                        {result.map((res, i) => (
                            <Result key={i} mediaPageContent={mediaPageContent} data={res} />
                        ))}

                        {!Auth.isLoggedIn() && (
                            <>
                                {lightbox && (
                                    <>
                                        <div className="lightbox-overlay" onClick={handleHideLightbox}></div>
                                        <div> {/* lightbox-wrapper */}
                                            <div className="lightbox-content account-lightbox">
                                                <div className="x-close-button">
                                                    <span className="close-lightbox" onClick={handleHideLightbox}>
                                                        <i className="fal fa-times"></i>
                                                    </span>
                                                </div>
                                                <img className="databaza-logo" src={BlackLogo} alt="Databaza.art" />
                                                <h6 className="motto-text text-center">
                                                    {mediaPageContent.lightbox.title}
                                                </h6>
                                                {/* <h4 style={{ marginBottom: 40 }}>{mediaPageContent.lightbox.title}</h4> */}
                                                <div className="lightbox-buttons-group">
                                                    <button
                                                        className="btn btn-large lightbox-secondary-button"
                                                        onClick={handleHideLightbox}
                                                    >
                                                        No
                                                    </button>
                                                    <Link
                                                        to="/join-user"
                                                        className="btn btn-large lightbox-primary-button"
                                                    >
                                                        Sure
                                                    </Link>
                                                </div>
                                            </div>
                                    </div>
                                    </>
                                )}
                                <div className="blury-content">
                                    <div className="blury-content-message">{mediaPageContent.limit_message}</div>
                                    <img src={bluryArtist1} alt="blury" />
                                </div>

                                <div className="blury-content">
                                    <div className="blury-content-message">{mediaPageContent.limit_message}</div>
                                    <img src={bluryArtist2} alt="blury" />
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    term !== '' && <p className="mb-5 mt-5 no-results">{mediaPageContent.no_results}</p>
                )}
            </Container>
        </div>
    )
}
