
import React, { useState } from "react"
import { Col } from 'react-bootstrap'
import './ProfilePage.scss'

export default function CustomTabs({ getActiveTab }) {
    const [active, setActive] = useState(0)

    const changeActive = (tab) => {
        setActive(tab)
        typeof getActiveTab === "function" && getActiveTab(tab)
    }
    return (
        <>
            <section className="tabs-container">
                <Col className={active === 0 ? 'active-col' : ''} onClick={() => changeActive(0)} md={2}>
                    <h5>My Account</h5>
                </Col>

                <Col className={active === 1 ? 'active-col' : ''} onClick={() => changeActive(1)} md={2}>
                    <h5>Saved</h5>
                </Col>
            </section>
        </>
    )
}