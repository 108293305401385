import React, { useEffect, useState } from 'react'
import SimpleSearch from '../../components/SimpleSearch'
import HowItWorks from '../../components/HowItWorks'
import About from '../../components/About'
import Pricing from '../../components/Pricing'
import Search from '../../components/Search'
import { useTranslation } from 'react-i18next'
import Api from '../../api'
import Helpers from '../../helpers'
// import { Col, Container, Row } from 'react-bootstrap'

export default function Home() {
    const { t } = useTranslation()
    const homepageContent = t('homepage', { returnObjects: true })
    const globalContent = t('global', { returnObjects: true })
    const widgetsContent = t('widgets', { returnObjects: true })
    const [artistsCount, setArtistsCount] = useState(null)
    const { search, howitworks, about, pricing, media } = homepageContent

    const getArtistCount = async () => {
        const resp = await Api.get('artistsCount')
        const res = Helpers.validateResponse(resp, alert)
        if (!res) return
        setArtistsCount(res)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getArtistCount()
    }, [])

    return (
        <div>
            <SimpleSearch searchContent={search} />
            <HowItWorks howItWorksContent={howitworks} />
            <About aboutContent={about} readMoreContent={globalContent.read_more} />
            {/* <Pricing pricingContent={pricing} globalContent={globalContent} /> */}
            <Search artistsCount={artistsCount ? artistsCount : 0} mediaContent={media} widgetsContent={widgetsContent} />
        </div>
    )
}
