import React, { useState } from 'react'
import { useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const getInitialValue = function () {
    return {
        name: '',
        place: '',
        year: '',
    }
}


export default function Associations({ register, setValue }) {
    const [associationsFields, setAssociationsFields] = useState([getInitialValue()])
    const [exactDate, setExactDate] = useState({ 
        '0': { isExact: false }
    })

    useEffect(() => {
        register({ name: 'associations', value: associationsFields })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setValue('associations', associationsFields)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [associationsFields])

    const onChangeEvent = function(index, key, value) {
        let temp = [...associationsFields]
        temp[index][key] = value
        setAssociationsFields(temp)
    }

    const removeField = (index) => {
        if (associationsFields.length > 1) {
            const temp = [...associationsFields]
            const exactDateTemp = { ...exactDate }
            
            delete exactDateTemp[index]
            temp.splice(index, 1)

            setAssociationsFields(temp)
            setExactDate(exactDateTemp)
        }
    }

    const addNewPrize = function() {
        setAssociationsFields([...associationsFields, ...[getInitialValue()]])
    }

    const onChangeExactDate = function(index, checked) {
        index = index.toString()
        let temp = { ...exactDate }
        if (!temp[index]) {
            temp[index] = {
                isExact: checked
            }
        }
        else {
            temp[index]['isExact'] = checked;
        }

        let prizesTemp = [...associationsFields]
        prizesTemp[index]['year'] = ''

        setExactDate(temp)
        setAssociationsFields(prizesTemp)
    }

    const generatePrizesWonField = () => {
        return associationsFields.map((field, i) => (
            <>
            <div className="field-group-wrapper" key={i}>
                <div>
                    <Form.Row>
                    <span className="remove-field-group" onClick={e => removeField(i)}>
                        <i className="fal fa-times"></i>
                    </span>
                        <Col>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={field.name}
                                    onChange={e => onChangeEvent(i, 'name', e.target.value)}
                                    placeholder={"Name"}
                                />
                                <div className="form-label">
                                    <i className="fal fa-home-lg-alt"></i>
                                </div>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="place"
                                    value={field.place}
                                    onChange={e => onChangeEvent(i, 'place', e.target.value)}
                                    placeholder={"Place"}
                                />
                                <div className="form-label">
                                    <i className="fal fa-map-marker-check"></i>
                                </div>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <div style={{ marginBottom: '20px' }}>
                        <Form.Check
                            custom
                            checked={(exactDate && exactDate[i] && exactDate[i].isExact) ? true : false}
                            type="checkbox"
                            onChange={(e) => onChangeExactDate(i, e.target.checked)}
                            label="Exact Date"
                        />
                    </div>
                    <Form.Row>
                        <Col>
                            <Form.Group>
                                {
                                    exactDate && exactDate[i] && exactDate[i].isExact
                                        ? <React.Fragment>
                                            <DatePicker
                                                dateFormat={"dd-MM-yyyy"}
                                                showYearDropdown={true}
                                                selected={field.year}
                                                onChange={date => onChangeEvent(i, 'year', date)}
                                                name="year"
                                                placeholderText={'Year'}
                                            />
                                            <div className="form-label">
                                                <i className="fal fa-history"></i>
                                            </div>
                                        </React.Fragment>
                                        : <React.Fragment>
                                            <Form.Control
                                                type="text"
                                                name="year"
                                                value={field.year}
                                                onChange={e => onChangeEvent(i, 'year', e.target.value)}
                                                placeholder={'Year'}
                                            />
                                            <div className="form-label">
                                                <i className="fal fa-history"></i>
                                            </div>
                                        </React.Fragment>
                                }
                            </Form.Group>
                        </Col>
                    </Form.Row>

                </div>
            </div>
            </>
        ))
    }
    return (
        <>
        <div className="repeater-wrapper">
            <Form.Group>
                <h4 className="text-center">Associations</h4>
            </Form.Group>
            {generatePrizesWonField()}
            <Form.Row>
                <Col className="text-center">
                    <button className="btn btn-clear btn-add" type="button" onClick={addNewPrize}>
                        <i class="fal fa-plus-circle"></i>
                        ADD ASSOCIATION
                    </button>
                </Col>
            </Form.Row>
        </div>
        </>
    )
}