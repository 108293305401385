import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import ExhibitionsList from './ExhibitionsList'
import './Exhibitions.scss'

export default function JoinHeaders({ artistInfo, content }) {
    return (
        <header className="paper-view-header text-center">
            <Tabs defaultActiveKey="previousExhibitions" id="uncontrolled-tab-example">
                {
                    (artistInfo.previousExhibitions && artistInfo.previousExhibitions.length > 0)
                        && (
                            <Tab eventKey="previousExhibitions" title={content.previousExhibitions}>
                                <ExhibitionsList content={artistInfo.previousExhibitions} />
                            </Tab>
                        ) 
                }
                {
                    (artistInfo.personalExhibitions && artistInfo.personalExhibitions.length > 0)
                        && (
                            <Tab eventKey="personalExhibitions" title={content.personalExhibitions}>
                                <ExhibitionsList content={artistInfo.personalExhibitions} />
                            </Tab>
                        ) 
                }
            </Tabs>
        </header>
    )
}
