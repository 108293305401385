import React from 'react'
import Logo from '../../assets/images/logo_black.svg'
import './UnderConstruction.scss'

export default function UnderConstruction() {
    return (
        <section className="text-center under-construction">
            <div>
                <img src={Logo} alt="Databaza.art" />
                <h3>
                    We are working thoroughly to bring a usable beta version of the database. Due to tech issues, the
                    launch of the database is postponed to 12 January 2020!
                </h3>
                <h4>Thank you for your understanding!</h4>
            </div>
        </section>
    )
}
