import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import HomePage from './views/HomePage'
import SearchPage from './views/SearchPage'
import PricingPage from './views/PricingPage'
import AboutPage from './views/AboutPage'
import ContactPage from './views/ContactPage'
import JoinUserPage from './views/JoinUserPage'
import FourOFourPage from './views/FourOFourPage'
import JoinArtistPage from './views/JoinArtistPage'
import ArtistPage from './views/ArtistPage'
import ProfilePage from './views/ProfilePage'
import VerificationCompletePage from './views/VerificationCompletePage'
import VerificationFailedPage from './views/VerificationFailedPage'
import TermsPage from './views/TermsPage'
import ForgotPasswordPage from './views/ForgotPasswordPage/ForgotPasswordPage'
import RecoveryPasswordPage from './views/RecoverPasswordPage/RecoverPasswordPage'
import MediaPage from './views/MediaPage/MediaPage'
import NewsPage from './views/NewsPage/NewsPage'
import SingleNewsPage from './views/SingleNewsPage/'
import SingleMediaPage from './views/SingleMediaPage'
import SingleActivity from './components/Activities/SingleActivity'
import SavedArtistsPage from './views/SavedArtistsPage/SavedArtistsPage'
import UnSubscribe from './views/UnSubscribe/UnSubscribe'
import FaqPage from './views/FaqPage'
import PrivacyPolicyPage from './views/PrivacyPage'
import DisclaimerPage from './views/DisclaimerPage'
import RegisterPage from './views/RegisterPage'
import ArtistSubmittedPage from './views/ArtistSubmittedPage'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
        exact: true,
    },
    {
        path: '/search',
        name: 'Search',
        component: SearchPage,
    },
    {
        path: '/pricing',
        name: 'Pricing',
        component: PricingPage,
    },
    {
        path: '/about',
        name: 'About',
        component: AboutPage,
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactPage,
    },
    {
        path: '/join-user',
        name: 'Join User',
        component: JoinUserPage,
    },
    {
        path: '/add-artist',
        name: 'Add Artist',
        component: JoinArtistPage,
    },
    {
        path: '/artist/:id',
        name: 'Artist',
        component: ArtistPage,
    },
    {
        path: '/profile',
        name: 'Profile',
        component: ProfilePage,
    },
    {
        path: '/verification-complete',
        name: 'Verification Complete',
        component: VerificationCompletePage,
    },
    {
        path: '/un-subscribe',
        name: 'Un Subscribe',
        component: UnSubscribe,
    },
    {
        path: '/verification-fail',
        name: 'Verification fail',
        component: VerificationFailedPage,
    },
    {
        path: '/terms-and-conditions',
        name: 'Terms And Conditions',
        component: TermsPage,
    },
    {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: ForgotPasswordPage,
    },
    {
        path: '/recovery-password',
        name: 'Recovery Password',
        component: RecoveryPasswordPage,
    },
    {
        path: '/media/:id',
        name: 'SingleMedia',
        exact: true,
        component: SingleMediaPage,
    },
    {
        path: '/media',
        name: 'Media',
        component: MediaPage,
    },
    {
        path: '/news/:id',
        name: 'SingleBlog',
        exact: true,
        component: SingleNewsPage,
    },
    {
        path: '/activity/:id',
        name: 'SingleActivity',
        exact: true,
        component: SingleActivity,
    },
    {
        path: '/news',
        name: 'News',
        component: NewsPage,
    },
    {
        path: '/saved',
        name: 'Saved',
        component: SavedArtistsPage,
    },
    {
        path: '/faq',
        name: 'FAQ',
        component: FaqPage,
    },
    {
        path: '/privacy-policy',
        name: 'Privacy Policy',
        component: PrivacyPolicyPage,
    },
    {
        path: '/disclaimer',
        name: 'Disclaimer',
        component: DisclaimerPage,
    },
    {
        path: '/registration-complete',
        name: 'Register',
        component: RegisterPage,
    },
    {
        path: '/artist-submission-complete',
        name: 'Artist submitted',
        component: ArtistSubmittedPage,
    },
]

class Routes extends Component {
    render() {
        return (
            <Switch>
                {/* <IndexRoute component={HomePage} /> */}
                {routes.map((props, i) => (
                    <Route key={i} {...props} />
                ))}
                <Route component={FourOFourPage} path="*" />
            </Switch>
        )
    }
}

export default Routes
