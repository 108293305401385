import React from 'react'
export default function ExhibitionTitle(props) {
    return (
        <div className="title-exhibitions">
            
            {!props.title && <div className="title">
                <h1 className="exhibitions-title">Exhibitions</h1>
                <h4 className="text-exhibitions">Latest and upcoming exhibitions</h4>
            </div>}

            <div className="previous-exhibitions">
                {
                    props.title ? <h3 className="previous">{props.title}</h3> 
                        : <h3 className="previous">Previous Exhibitions</h3> 
                }
            </div>
        </div>
    );
}