import React, { useState, useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'
import Select from 'react-select'
import Helpers from '../../../helpers'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function Personal({ content, register, globalContent, setValue }) {
    const genderOptions = [
        { value: 'MALE', label: 'Male' },
        { value: 'FEMALE', label: 'Female' },
        { value: 'OTHER', label: 'Other' },
    ]

    const [worksAndLivesTags, setWorksAndLivesTags] = useState([])
    const ethnicityOptions = [
        { value: 'AL', label: 'Albanian' },
        { value: 'SR', label: 'Serbian' },
        { value: 'MNE', label: 'Montenegrins' },
        { value: 'TR', label: 'Turkish' },
        { value: 'BOS', label: 'Bosnian' },
    ]

    const selectStyles = Helpers.getSelectStyles()
    const [displayDeathYear, setDisplayDeathYear] = useState(false)
    const [displayBirthExactDate, setDisplayBirthExactDate] = useState(false);
    const [displayDeceasedExactDate, setDisplayDeceasedExactDate] = useState(false);

    const toggleConditionalField = e => {
        setDisplayDeathYear(e.target.checked)
        setDisplayDeceasedExactDate(false)
    }

    const handleSetWorksAndLivesTags = newValue => {
        setWorksAndLivesTags(newValue)
        setValue('livesAndWorks', newValue)
    }

    const handleChangeSelect = (selected, meta) => {
        setValue(meta.name, selected.value)
    }

    const handleChangeDate = (date, field, death) => {
        if (death) {
            setDateOfDeath(date);
        } else {
            setBirthdaySelected(date);
        }
        setValue(field, date)
    }

    const exactDate = (e) => {
        setDisplayBirthExactDate(!displayBirthExactDate);
    }

    const [birthdaySelected, setBirthdaySelected] = useState('');
    const [dateOfDeathSelected, setDateOfDeath] = useState('');

    useEffect(() => {
        register({ name: 'gender' }) // required
        register({ name: 'ethnicity' })
        register({ name: 'dateOfBirth' })
        register({ name: 'dateOfDeath' })
        register({ name: 'livesAndWorks' })
    })

    return (
        <div className="form-section">
            <Form.Group>
                <h4 className="text-center">{content.title}</h4>
            </Form.Group>
            <Form.Row>
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Control
                            type="text"
                            name="firstName"
                            placeholder={content.name}
                            ref={register({ required: true })}
                        />
                        <div className="form-label">
                            <i className="fal fa-user"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Control
                            type="text"
                            name="lastName"
                            placeholder={content.lastname}
                            ref={register({ required: true })}
                        />
                        <div className="form-label">
                            <i className="fal fa-user"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>  
                <Col md="6" sm="12">
                    <Form.Group>
                        <Select
                            options={genderOptions}
                            styles={selectStyles}
                            name="gender"
                            ref={register({ required: true })}
                            onChange={handleChangeSelect}
                            placeholder={content.gender}
                        />
                        <div className="form-label">
                            <i className="fal fa-genderless"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
                <Col md="6" sm="12">
                    <Form.Group>
                        <Select
                            options={ethnicityOptions}
                            styles={selectStyles}
                            name="ethnicity"
                            ref={register({ required: true })}
                            onChange={handleChangeSelect}
                            placeholder={content.ethnicity}
                        />
                        <div className="form-label">
                            <i className="fal fa-flag"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
            </Form.Row>

            <Form.Row>
                <Col md="6" sm="12" style={{marginBottom: '20px'}}>
                    <Form.Check
                        custom
                        type="checkbox"
                        onChange={exactDate}
                        label="Exact Date"
                    />
                </Col>

                {
                    displayDeathYear
                        && <Col md="6" sm="12">
                                <Form.Check
                                    custom
                                    type="checkbox"
                                    onChange={() => setDisplayDeceasedExactDate(!displayDeceasedExactDate)}
                                    label="Deceased Exact Date"
                                />
                            </Col> 
                }
            </Form.Row>

            
            
            <Form.Row>
                <Col md="6" sm="12">
                    <Form.Group>
                        {
                            !displayBirthExactDate
                                ? <React.Fragment>
                                        <Form.Control
                                            min="1800"
                                            max="2099"
                                            step="1"
                                            type="text"
                                            name="dateOfBirth"
                                            placeholder={content.birthyear}
                                            ref={register({ required: true })}
                                        />
                                        <div className="form-label">
                                            <i className="fal fa-calendar-day"></i>
                                            <span className="required-field"></span>
                                        </div>
                                    </React.Fragment>
                                : <React.Fragment>
                                    <DatePicker
                                        dateFormat={"dd-MM-yyyy"}
                                        showYearDropdown={true}
                                        selected={birthdaySelected}
                                        onChange={date => handleChangeDate(date, 'dateOfBirth')}
                                        name="dateOfBirth"
                                        ref={register({ required: true })}
                                        placeholderText={content.birthyear}
                                    />
                                    <div className="form-label">
                                        <i className="fal fa-calendar-day"></i>
                                        <span className="required-field"></span>
                                    </div>
                                </React.Fragment>
                        }
                    </Form.Group>
                </Col>
                <Col md="6" sm="12">
                    <Form.Group>
                        <div className={`conditional-field ${displayDeathYear && 'active'}`}>
                            {
                                displayDeceasedExactDate
                                    ? <React.Fragment>
                                        <DatePicker
                                            dateFormat={"dd-MM-yyyy"}
                                            showYearDropdown={true}
                                            selected={dateOfDeathSelected}
                                            onChange={date => handleChangeDate(date, 'dateOfDeath', true)}
                                            name="dateOfDeath"
                                            ref={register}
                                            placeholderText={content.deceased_year}
                                        />
                                        <div className="form-label">
                                            <i className="fas fa-calendar-day"></i>
                                            <span className="required-field"></span>
                                        </div>
                                    </React.Fragment>
                                    : <React.Fragment>
                                         <Form.Control
                                            min="1800"
                                            max="2099"
                                            step="1"
                                            type="text"
                                            name="dateOfDeath"
                                            placeholder={content.deceased_year}
                                            ref={register}
                                        />
                                        <div className="form-label">
                                            <i className="fas fa-calendar-day"></i>
                                            <span className="required-field"></span>
                                        </div>
                                    </React.Fragment>
                            }
                        </div>
                        <Form.Check
                            custom
                            type="checkbox"
                            onChange={toggleConditionalField}
                            className="form-input-height"
                            id="date-of-death"
                            label={content.doesnt_live}
                        />
                    </Form.Group>
                </Col>
            </Form.Row>

            <Form.Row>
                <Col md="6" sm="12">
                    <Form.Label>{content.birthplace}</Form.Label>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Control
                            name="country"
                            type="text"
                            placeholder={content.country}
                            ref={register({ required: true })}
                        />
                        <div className="form-label">
                            <i className="fal fa-map-marker-smile"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Control
                            name="city"
                            type="text"
                            placeholder={content.city}
                            ref={register({ required: true })}
                        />
                        <div className="form-label">
                            <i className="fal fa-map-marker-smile"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
            </Form.Row>

            <Form.Row>
                <Col md="6" sm="12">
                    <Form.Group>
                        <TagsInput
                            value={worksAndLivesTags}
                            onChange={handleSetWorksAndLivesTags}
                            ref={register({ required: true })}
                            name="livesAndWorks"
                            addKeys={[9, 13, 188]}
                            inputProps={{
                                placeholder: content.lives_and_works,
                            }}
                        />
                        <Form.Text className="text-muted">{globalContent.tag_input}</Form.Text>
                        <div className="form-label">
                            <i className="fal fa-map-marker-smile"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
            </Form.Row>
        </div>
    )
}
