import React, { useEffect } from 'react'
import Pricing from '../../components/Pricing'
import { useTranslation } from 'react-i18next'

export default function PricingPage() {
    const { t } = useTranslation()
    const pricingPageContent = t('pricingpage', { returnObjects: true })
    const globalContent = t('global', { returnObjects: true })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return <Pricing description={true} globalContent={globalContent} pricingContent={pricingPageContent} />
}
