import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import PageTitle from '../../components/Global/PageTitle'
import ContactForm from '../../components/ContactForm'
import { useTranslation } from 'react-i18next'
//import ContactInfo from '../../components/ContactForm/ContactInfo'

export default function ContactPage() {
    const { t } = useTranslation()
    const contactContent = t('contactpage', { returnObjects: true })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className="contact-page-section">
            <Container>
                <PageTitle title={contactContent.title} subtitle={contactContent.subtitle} />
                <ContactForm content={contactContent.form} />
                {/* <ContactInfo contactContent={contactContent.info} /> */}
            </Container>
        </section>
    )
}
