import React from 'react'

function SectionTitle({ title, subtitle, color, marginBottom }, props) {
    return (
        <div className="title-wrapper text-center" style={marginBottom ? { marginBottom: marginBottom } : {}}>
            <h2 className="page-title" style={color ? { color: color } : { }}>{title}</h2>
            {subtitle && <h4 className="subtitle">{subtitle}</h4>}
        </div>
    )
}
export default SectionTitle
