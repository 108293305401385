import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import MobileFooter from './MobileFooter'

function Navigation({ nav, menu, loginState, expandState }) {
    const history = useHistory()
    const pathName = history && history.location && history.location.pathname ? history.location.pathname : false
    const [expand, setExpand] = expandState
    const toggleExpand = () => {
        setExpand(!expand)
    }
    const closeNav = () => {
        setExpand(false)
    }

    return (
        <>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleExpand} />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} active={pathName === '/'} to="/" onClick={closeNav}>
                        {nav.home}
                    </Nav.Link>
                    <Nav.Link as={Link} active={pathName === '/search'} to="/search" onClick={closeNav}>
                        {nav.media}
                    </Nav.Link>
                    <Nav.Link active={pathName === '/news'} as={Link} to="/news" onClick={closeNav}>
                        {nav.news}
                    </Nav.Link>
                    {/* <Nav.Link active={pathName === '/pricing'} as={Link} to="/pricing" onClick={closeNav}>
                        {nav.pricing}
                    </Nav.Link> */}
                    <Nav.Link active={pathName === '/about'} as={Link} to="/about" onClick={closeNav}>
                        {nav.about}
                    </Nav.Link>
                    <Nav.Link as={Link} active={pathName === '/contact'} to="/contact" onClick={closeNav}>
                        {nav.contact}
                    </Nav.Link>
                </Nav>
                <MobileFooter menu={menu} loginState={loginState} onClick={closeNav} setExpand={setExpand} />
            </Navbar.Collapse>
        </>
    )
}
export default Navigation
