import React from 'react'
import Logo from './Logo'
import Nav from '../Footer/Nav'

function Menu({ nav }) {
    return (
        <nav className="navbar navbar-expand-lg footer-row">
            <Logo />
            <Nav nav={nav} />
        </nav>
    )
}

export default Menu
