import React from 'react'

const SocialNetworks = () => {
    return (
        <ul className="navbar-nav social-networks">
            <li>
                <a href="https://www.facebook.com/GaleriaQahili" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-facebook-f"></i>
                </a>
            </li>
            <li>
                <a href="https://www.instagram.com/galeriaqahili" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                </a>
            </li>
        </ul>
    )
}

export default SocialNetworks
