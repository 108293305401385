import React, { useState, useEffect, useContext } from 'react'
import { Form, Col } from 'react-bootstrap'
import ImageUploadField from '../../Global/ImageUploadField'
import Api from '../../../api'
import { LanguageContext } from '../../../contexts/LanguageContext'
import ArtType from './ArtType'
import Select from 'react-select'
import Helpers from '../../../helpers'

const selectStyles = Helpers.getSelectStyles()

export default function Profile({ content, register, setValue, setThemes, setArtTypes }) {
    const [mediumOptions, setMediumOptions] = useState({})
    const [themeOptions, setThemeOptions] = useState([])
    const currentLanguage = useContext(LanguageContext)
    const { language } = currentLanguage

    const getMediums = async () => {
        const response = await Api.getWithId('taxonomies', 'mediums')
        if (!response || !response.data || !response.data.results) return
        const tempMedium = []
        response.data.results.forEach(style => {
            tempMedium.push({ value: style._id, label: style[language] })
        })
        setMediumOptions(tempMedium)
    }

    const getThemes = async () => {
        const response = await Api.getWithId('taxonomies', 'themes')
        if (!response || !response.data || !response.data.results) return
        const tempThemes = []
        response.data.results.forEach(theme => {
            tempThemes.push({ value: theme._id, label: theme[language] })
        })
        setThemeOptions(tempThemes)
        setThemes(tempThemes);
    }

    const handleChangeSelect = function (meta, selected, type) {
        if (meta && meta.value && meta.value[0]) {
            setValue("artist_theme", meta.value[0])
        }
    }

    useEffect(() => {
        register({ name: "artist_theme" })
        getMediums()
        getThemes()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="form-section artist-profile-section">
            <Form.Group>
                <h4 className="text-center">{content.title}</h4>
            </Form.Group>
            <Form.Row>
                <Col>
                    <Form.Group>
                        <Select
                            options={themeOptions || []}
                            styles={selectStyles}
                            name="artist_theme"
                            ref={register}
                            onChange={handleChangeSelect}
                            placeholder={content.themes}
                        />
                        <div className="form-label">
                            <i className="fal fa-paint-brush-alt"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
            </Form.Row>

            <ImageUploadField
                type="file"
                accept="image/png, image/jpeg"
                label={content.profile}
                format="1000x1000 px (max 2mb)"
                button={content.button}
                icon="fal fa-user-circle"
                innerRef={register({ required: true })}
                name="profileImage"
                required={true}
            />
            <ImageUploadField
                type="file"
                accept="image/png, image/jpeg"
                label={content.cover}
                format="1300x250 px (max 2mb)"
                button={content.button}
                icon="fal fa-images"
                innerRef={register}
                required={false}
                name="coverImage"
            />

            <ArtType setArtTypes={setArtTypes} register={register} setValue={setValue} />
        </div>
    )
}
