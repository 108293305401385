import endpoints from './Endpoints'
import axios from 'axios'

let config = {}
let token = sessionStorage.getItem('ACCESS_TOKEN')
class Api {
    async getAllSubscriptions() {
        try {
            return await axios.get(endpoints.subscriptions)
        } catch (error) {
            return error.response
        }
    }

    async login(data) {
        try {
            return await axios.post(endpoints.login, data)
        } catch (error) {
            return error.response
        }
    }

    async get(resource, query = false) {
        const objEndpoint = {
            url: endpoints[resource]
        }
        const config = {
            headers: { Authorization: 'Bearer ' + token },
        }
        if (query) {
            Object.keys(query).forEach((elem, index) => {
                let quote = index === 0 ? '?' :  '&';
                objEndpoint.url += `${quote}${elem}=${query[elem]}`
            })
        }

        try {
            return await axios.get(objEndpoint.url, config)
        } catch (error) {
            return error.response
        }
    }

    async getWithId(resource, id) {
        try {
            return await axios.get(`${endpoints[resource]}/${id}`)
        } catch (error) {
            return error.response
        }
    }

    async getWithTerm(resource, term) {
        try {
            return await axios.get(`${endpoints[resource]}?${term}`,)
        } catch (error) {
            return error.response
        }
    }

    async post(resource, data, formData = false) {
        try {
            if (!formData) {
                config = {
                    headers: { Authorization: 'Bearer ' + token },
                }
                return await axios.post(endpoints[resource], data, config)
            } else {
                config = {
                    headers: { 'content-type': 'multipart/form-data', Authorization: 'Bearer ' + token },
                }
                return await axios.post(endpoints[resource], data, config)
            }
        } catch (error) {
            console.log(error.response)
            return error.response
        }
    }

    async delete(resource, data) {
        try {
            return await axios({
                method: 'delete',
                url: endpoints[resource],
                data: data,
                headers: { Authorization: 'Bearer ' + token },
            })
        } catch (error) {
            console.error(error.response)
            return error.response
        }
    }
}

export default new Api()
