import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import './AdvancedSearch.scss'
import Api from '../../api'
import MultiSelectDropdown from '../MultiSelectDropdown/MultiSelectDropdown'

const birthplaceOptions = [
    { key: 'Prishtinë', label: 'Prishtinë' },
    { key: 'Prizren', label: 'Prizren' },
    { key: 'Gjilan', label: 'Gjilan' },
    { key: 'Pejë', label: 'Pejë' },
    { key: 'Mitrovicë', label: 'Mitrovicë' },
    { key: 'Ferizaj', label: 'Ferizaj' },
    { key: 'Gjakovë', label: 'Gjakovë' },
]

const artistTypeOptions = [
    { key: 7, label: 'Professional' },
    { key: 8, label: 'Maverick' },
    { key: 9, label: 'Naive' },
    { key: 10, label: 'Artisan' },
]

export default function AdvancedSearch({
    content,
    placeholderBold,
    terms,
    getData,
    dropdown,
    setSelectedTerm,
    setArtistTypeSelected,
    selectedTerm,
    alert,
    setArtStyleSelected,
    setMediumsSelected,
    setCitySelected,
    setMusicCategorySelected,
    setMusicGenreSelected,
    setDramaticGenreSelected,
    setDramaticCategorySelected,
    setThemesSelected,
    setBirthPlaceSelected,
    setArtTypeSelected,
    artTypeSelected,
}) {
    const [focus, setFocus] = useState(false)
    const handleSearchFocus = () => setFocus(true)
    const handleSearchBlur = () => setFocus(false)
    const [taxonomies, setTaxonomies] = useState({})
    const [showMoreFilters, setShowMoreFilters] = useState(false)
    const [term, setTerm] = terms

    const [artTypeOptions] = useState([
        { key: 'VISUAL', label: 'Visual' },
        { key: 'MUSIC', label: 'Music' },
        { key: 'DRAMATIC', label: 'Dramatic' },
    ])

    const getTaxonomies = async () => {
        const response = await Api.get('taxonomies')
        if (response && response.data && response.data.results && typeof response.data.results === 'object')
            setTaxonomies(response.data.results)
    }

    useEffect(() => {
        getTaxonomies()
    }, [])

    const handleFormSubmit = e => {
        e.preventDefault()
        setSelectedTerm(term)
        getData()
    }

    return (
        <div className="advanced-search">
            <Container>
                <form onSubmit={handleFormSubmit}>
                    <div className="form-section section-search">
                        <input
                            type="text"
                            value={term}
                            onChange={e => {
                                setTerm(e.target.value)
                            }}
                            onFocus={handleSearchFocus}
                            onBlur={handleSearchBlur}
                        />
                        <div className={`custom-placeholder ${!focus && term === '' && 'active'}`}>
                            <b>{placeholderBold}</b>
                        </div>
                        <div className="search-form-actions">
                            <button type="submit" className="btn btn-clear">
                                <i className="fal fa-search normal-state"></i>
                                <i className="fal fa-search-plus hover-state"></i>
                            </button>
                        </div>
                    </div>

                    <div className="row align-items-center search-filter-row">
                        <div className="col-sm-4">
                            <MultiSelectDropdown
                                showMoreFilters={showMoreFilters}
                                checkbox={true}
                                placeholder={'Filters'}
                                returnLabel={true}
                                setShowMoreFilters={setShowMoreFilters}
                            />
                        </div>
                        <div className="col-sm-4">
                            {showMoreFilters && (
                                <MultiSelectDropdown
                                    placeholder={content.birthplace}
                                    returnLabel={true}
                                    handleOnChange={selected => setCitySelected(selected)}
                                    options={birthplaceOptions}
                                />
                            )}
                        </div>
                        <div className="col-sm-4">
                            {showMoreFilters && (
                                <MultiSelectDropdown
                                    placeholder={'Art Type'}
                                    handleOnChange={selected => setArtTypeSelected(selected)}
                                    options={artTypeOptions}
                                />
                            )}
                        </div>
                        <div className="col-sm-4">
                            {showMoreFilters && (
                                <MultiSelectDropdown
                                    placeholder={'Artist Type'}
                                    returnLabel={true}
                                    handleOnChange={selected => setArtistTypeSelected(selected)}
                                    options={artistTypeOptions}
                                />
                            )}
                        </div>
                        <div className="col-sm-4">
                            {taxonomies && taxonomies.themes && Array.isArray(taxonomies.themes) && showMoreFilters && (
                                <MultiSelectDropdown
                                    placeholder={'Themes'}
                                    handleOnChange={selected => setThemesSelected(selected)}
                                    options={taxonomies.themes}
                                    name={'themes'}
                                />
                            )}
                        </div>
                    </div>

                    {/* Dramatic */}
                    <div className="row align-items-center search-filter-row">
                        <div className="col-sm-4">
                            {taxonomies &&
                                taxonomies.dramatic &&
                                Array.isArray(taxonomies.dramatic.dramatic_category) &&
                                artTypeSelected.includes('DRAMATIC') &&
                                showMoreFilters && (
                                    <MultiSelectDropdown
                                        showMoreFilters={showMoreFilters}
                                        checkbox={true}
                                        disabled={true}
                                        placeholder={'Dramatic Filter'}
                                        returnLabel={true}
                                        setShowMoreFilters={setShowMoreFilters}
                                    />
                                )}
                        </div>
                        <div className="col-sm-4">
                            {taxonomies &&
                                taxonomies.dramatic &&
                                Array.isArray(taxonomies.dramatic.dramatic_category) &&
                                artTypeSelected.includes('DRAMATIC') &&
                                showMoreFilters && (
                                    <MultiSelectDropdown
                                        placeholder={'Dramatic Category'}
                                        handleOnChange={selected => setDramaticCategorySelected(selected)}
                                        options={taxonomies.dramatic.dramatic_category}
                                        name={'dramatic_category'}
                                    />
                                )}
                        </div>
                        <div className="col-sm-4">
                            {taxonomies &&
                                taxonomies.dramatic &&
                                Array.isArray(taxonomies.dramatic.dramatic_genre) &&
                                artTypeSelected.includes('DRAMATIC') &&
                                showMoreFilters && (
                                    <MultiSelectDropdown
                                        placeholder={'Dramatic Genre'}
                                        handleOnChange={selected => setDramaticGenreSelected(selected)}
                                        options={taxonomies.dramatic.dramatic_genre}
                                        name={'dramatic_genre'}
                                    />
                                )}
                        </div>
                    </div>

                    {/* Visual */}
                    <div className="row align-items-center search-filter-row">
                        <div className="col-sm-4">
                            {taxonomies &&
                                taxonomies.visual &&
                                Array.isArray(taxonomies.visual.artstyles) &&
                                artTypeSelected.includes('VISUAL') &&
                                showMoreFilters && (
                                    <MultiSelectDropdown
                                        showMoreFilters={showMoreFilters}
                                        checkbox={true}
                                        disabled={true}
                                        placeholder={'Visual Filter'}
                                        returnLabel={true}
                                        setShowMoreFilters={setShowMoreFilters}
                                    />
                                )}
                        </div>
                        <div className="col-sm-4">
                            {taxonomies &&
                                taxonomies.visual &&
                                Array.isArray(taxonomies.visual.artstyles) &&
                                artTypeSelected.includes('VISUAL') &&
                                showMoreFilters && (
                                    <MultiSelectDropdown
                                        placeholder={content.style}
                                        handleOnChange={selected => setArtStyleSelected(selected)}
                                        options={taxonomies.visual.artstyles}
                                        name={'artstyles'}
                                    />
                                )}
                        </div>
                        <div className="col-sm-4">
                            {taxonomies &&
                                taxonomies.visual &&
                                Array.isArray(taxonomies.visual.mediums) &&
                                artTypeSelected.includes('VISUAL') &&
                                showMoreFilters && (
                                    <MultiSelectDropdown
                                        placeholder={content.medium}
                                        handleOnChange={selected => setMediumsSelected(selected)}
                                        options={taxonomies.visual.mediums}
                                        name={'mediums'}
                                    />
                                )}
                        </div>
                    </div>

                    {/* Music */}
                    <div className="row align-items-center search-filter-row">
                        <div className="col-sm-4">
                            {taxonomies &&
                                taxonomies.music &&
                                Array.isArray(taxonomies.music.music_category) &&
                                artTypeSelected.includes('MUSIC') &&
                                showMoreFilters && (
                                    <MultiSelectDropdown
                                        showMoreFilters={showMoreFilters}
                                        checkbox={true}
                                        disabled={true}
                                        placeholder={'Music Filter'}
                                        returnLabel={true}
                                        setShowMoreFilters={setShowMoreFilters}
                                    />
                                )}
                        </div>
                        <div className="col-sm-4">
                            {taxonomies &&
                                taxonomies.music &&
                                Array.isArray(taxonomies.music.music_category) &&
                                artTypeSelected.includes('MUSIC') &&
                                showMoreFilters && (
                                    <MultiSelectDropdown
                                        placeholder={'Music Category'}
                                        handleOnChange={selected => setMusicCategorySelected(selected)}
                                        options={taxonomies.music.music_category}
                                        name={'music_category'}
                                    />
                                )}
                        </div>
                        <div className="col-sm-4">
                            {taxonomies &&
                                taxonomies.music &&
                                Array.isArray(taxonomies.music.music_genre) &&
                                artTypeSelected.includes('MUSIC') &&
                                showMoreFilters && (
                                    <MultiSelectDropdown
                                        placeholder={'Music Genre'}
                                        handleOnChange={selected => setMusicGenreSelected(selected)}
                                        options={taxonomies.music.music_genre}
                                        name={'music_genre'}
                                    />
                                )}
                        </div>
                    </div>
                </form>
            </Container>
        </div>
    )
}
