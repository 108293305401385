import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Api from '../../api/Api'
import Helpers from '../../helpers'
import './SingleNewsPage.scss'
import { Link } from 'react-router-dom'
import ImageTag from '../../components/Global/ImageTag'
import Spinner from 'react-bootstrap/Spinner'
import { useContext } from 'react'
import { LanguageContext } from '../../contexts/LanguageContext'
import { Form, Col } from 'react-bootstrap'
import ShareSocials from '../../components/ShareSocials'
import useForm from 'react-hook-form'
import { useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'

export default function SingleNewsPage({ match, history }) {
    const { t } = useTranslation()
    const newsletterContent = t('newsletterBox', { returnObjects: true })

    const [post, setPost] = useState(null)
    const [loading, setLoading] = useState(false)

    const alert = useAlert()
    const { register, handleSubmit, errors, reset } = useForm()

    useEffect(() => {
        ;(async () => {
            let response = await Api.getWithId('blog', match.params.id)
            const res = Helpers.validateResponse(response, alert)
            setPost(res)
        })()
    }, [alert, match.params.id])

    const { language } = useContext(LanguageContext)

    const getTitle = () => {
        if (
            post &&
            post.multiLanguageFields &&
            post.multiLanguageFields[language] &&
            post.multiLanguageFields[language].title
        )
            return post.multiLanguageFields[language].title
        return ''
    }

    const getContent = () => {
        if (
            post &&
            post.multiLanguageFields &&
            post.multiLanguageFields[language] &&
            post.multiLanguageFields[language].content
        )
            return post.multiLanguageFields[language].content
        return ''
    }

    useEffect(() => {
        if (!match) history.goBack()
        // eslint-disable-next-line
    }, [match])

    const handleNewsletterSubmit = async data => {
        setLoading(true)
        const response = await Api.post('newsLetterRegister', data)
        setLoading(false)
        reset()
        if (!response || !response.data || response.data.confirmation === 'Fail') {
            alert.error(newsletterContent.errorMessage)
            return
        }
        alert.success(newsletterContent.successMessage)
    }

    const [scrollPosition, setScrollPosition] = useState(0)
    const handleScroll = () => {
        const position = window.pageYOffset
        setScrollPosition(position)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <React.Fragment>
            {post ? (
                <Container onScroll={handleScroll}>
                    <div className="navcontainer ">
                        <div className="media-news col-md-10 offset-md-1">
                            <ul className="navlist">
                                <li className="data.art">
                                    <Link to="/news">News</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-10 offset-md-1 singlenewspage">
                        {(post.location || post.place) && (
                            <div className="single-page-header">
                                <h4>{post.title}</h4>
                                <p className="city-and-name">
                                    {post.location} · {post.place}
                                </p>
                            </div>
                        )}
                        {post && post.featuredImage && post.featuredImage !== '' && (
                            <React.Fragment>
                                <h2 className="image-below-title">{getTitle()}</h2>
                                <h5 className="image-below-subtitle">Lorem ipsum dolor sit amet</h5>
                                {post.publishedDate && (
                                    <li className="image-below-text">
                                        {post.author && post.author} |{' '}
                                        {new Date(post.publishedDate).toLocaleDateString()}
                                    </li>
                                )}
                                <ImageTag className="img-news-back-single" src={post.featuredImage} />
                            </React.Fragment>
                        )}
                        {post.startDate && post.type === 'EVENT' && (
                            <div className="calendar-event-blog grid-blog">
                                <div className="box-1-event-blog box-color-blog-event single-box box-1-single">
                                    <p className="data-nr-event-blog">{new Date(post.startDate).getDate()}</p>
                                </div>
                                <div className="box-2-event-blog box-color-blog-event single-box box-2-single">
                                    <p className="month-event-blog border-single">
                                        {new Date(post.startDate).toLocaleDateString(undefined, { month: 'short' })}
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className="has-html" dangerouslySetInnerHTML={{ __html: getContent() }} />
                    </div>

                    {/* newsletter part */}
                    <div className="newsletter-subscribe col-md-10 offset-md-1">
                        <h5 className="newsletter-subscribe-title">{newsletterContent.title}</h5>
                        <Form onSubmit={handleSubmit(handleNewsletterSubmit)}>
                            <Form.Row>
                                <Col sm="12" md="8">
                                    <Form.Group>
                                        <Form.Control
                                            ref={register({
                                                required: 'Email cannot be empty',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: 'Invalid email address',
                                                },
                                            })}
                                            type="email"
                                            name="email"
                                            placeholder="E-mail *"
                                        />
                                        <div className="form-label">
                                            <i className="fal fa-user"></i>
                                            <span className="required-field"></span>
                                        </div>
                                        {errors && errors.email && errors.email.message && (
                                            <span className="required-field-newsletter">{errors.email.message}</span>
                                        )}
                                    </Form.Group>
                                </Col>
                                <Col sm="12" md="4">
                                    <Form.Group className="text-center">
                                        <button disabled={loading} className="btn btn-dark" type="submit">
                                            {loading ? (
                                                <Spinner size="sm" animation="border" />
                                            ) : (
                                                newsletterContent.buttonTitle
                                            )}
                                        </button>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                        </Form>
                    </div>

                    <ShareSocials />
                </Container>
            ) : (
                <div className="loading-sppiner-parent">
                    <Spinner animation="border" color="black" role="status"></Spinner>
                    <h5 className="loading-spinner-text">Loading...</h5>
                </div>
            )}
        </React.Fragment>
    )
}
