import React, { useState } from 'react'
import { Form, Col } from 'react-bootstrap'
import Select from 'react-select'
import Helpers from '../../../helpers'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ImageUploadField from '../../Global/ImageUploadField'
import TagsInput from 'react-tagsinput'
import { useEffect } from 'react'

export default function({ register, content, setValue, themes, artTypes }) {
    const selectStyles = Helpers.getSelectStyles()
    const initialValue = {
        artWorkType: { selected: false, options: [], id: null },
        title: '',
        image: '',
        urls: '',
        themes: '',
        year: '',
        technique: '',
        yearExactDate: false,
    }

    const [artWorksFields, setArtWorksFields] = useState([initialValue])

    const updateField = (e, index) => {
        let temp = [...artWorksFields]
        temp[index][e.target.name] = e.target.value
        setArtWorksFields(temp)
        setValue('artworks', temp)
    }

    const handleTags = (tag, value, index) => {
        let temp = [...artWorksFields]
        temp[index][tag] = value
        setArtWorksFields(temp)
        setValue('artworks', temp)
    }

    const removeFieldGroup = (e, index) => {
        e.preventDefault()
        if (index === 0) return
        let temp = [...artWorksFields]
        temp.splice(index, 1)
        setArtWorksFields(temp)
        setValue('artworks', temp)
    }

    const handleChangeDate = (date, index) => {
        let temp = [...artWorksFields]
        temp[index]['year'] = date
        temp[index]['yearExactDate'] = true
        setArtWorksFields(temp)
        setValue('artworks', temp)
    }

    const handleDisplayExactDate = index => {
        let temp = [...artWorksFields]
        temp[index]['year'] = ''
        temp[index]['yearExactDate'] = !temp[index]['yearExactDate']
        setArtWorksFields(temp)
        setValue('artworks', temp)
    }

    const addFieldToGroup = e => {
        e.preventDefault()
        let temp = [...artWorksFields]
        temp.push(initialValue)
        setArtWorksFields(temp)
        setValue('artworks', temp)
    }

    const filterOptions = arr => {
        if (!Array.isArray(arr)) return []
        let temp = []
        arr.forEach((elem, index) => temp.push({ value: elem._id, label: elem.value }))
        return temp
    }

    const handleArtTypeOptions = (e, type, index) => {
        let temp = [...artWorksFields]
        if (type) temp[index]['type'] = type.toUpperCase()
        temp[index]['artWorkType']['selected'] = type
        if (type === 'visual' && artTypes && artTypes.visual && artTypes.visual.mediums)
            temp[index]['artWorkType']['options'] = filterOptions(artTypes.visual.mediums)
        else if (type === 'music' && artTypes && artTypes.music && artTypes.music.music_genre)
            temp[index]['artWorkType']['options'] = filterOptions(artTypes.music.music_genre)
        else if (type === 'dramatic' && artTypes && artTypes.dramatic && artTypes.dramatic.dramatic_genre)
            temp[index]['artWorkType']['options'] = filterOptions(artTypes.dramatic.dramatic_genre)

        setArtWorksFields(temp)
        setValue('artworks', temp)
    }

    const handleArtType = (e, type, index) => handleArtTypeOptions(e, type, index)

    useEffect(() => {
        register({ name: 'artworks' })
    })

    const handleFile = (event, index) => {
        let temp = [...artWorksFields]
        temp[index]['image'] = event.target.files
        setArtWorksFields(temp)
        setValue('artworks', temp)
    }

    const handleSelectedOptions = (meta, selected, index) => {
        let temp = [...artWorksFields]
        temp[index][selected.name] = meta.value
        setArtWorksFields(temp)
        setValue('artworks', temp)
    }

    const handleArtTypeSelected = (meta, selected, index) => {
        let temp = [...artWorksFields]
        if (!meta.value) return
        temp[index]['artWorkType']['id'] = meta.value
        temp[index]['category'] = meta.value
        setArtWorksFields(temp)
        setValue('artworks', temp)
    }

    const generateArtWorksFields = () => {
        return (
            <>
                <Form.Group>
                    <h4 className="text-center">Art Works</h4>
                </Form.Group>
                {artWorksFields.map((fields, i) => {
                    return (
                        <div className="field-group-wrapper" key={i}>
                            <div className="art-works-checkboxes">
                                <div>
                                    <Form.Check
                                        custom
                                        type="radio"
                                        label="Visual"
                                        ref={register({ required: true })}
                                        onChange={e => handleArtType(e, 'visual', i)}
                                        checked={fields['artWorkType']['selected'] === 'visual'}
                                    />
                                </div>
                                <div>
                                    <Form.Check
                                        custom
                                        type="radio"
                                        label="Music"
                                        onChange={e => handleArtType(e, 'music', i)}
                                        checked={fields['artWorkType']['selected'] === 'music'}
                                    />
                                </div>
                                <div>
                                    <Form.Check
                                        custom
                                        type="radio"
                                        label="Dramatic"
                                        onChange={e => handleArtType(e, 'dramatic', i)}
                                        checked={fields['artWorkType']['selected'] === 'dramatic'}
                                    />
                                </div>
                            </div>
                            <Form.Row>
                                <span className="remove-field-group" onClick={e => removeFieldGroup(e, i)}>
                                    <i className="fal fa-times"></i>
                                </span>
                                <Col>
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            name="title"
                                            value={fields['title']}
                                            onChange={e => updateField(e, i)}
                                            placeholder={'Title'}
                                        />
                                        <div className="form-label">
                                            <i className="fal fa-map-marker-check"></i>
                                            <span className="required-field"></span>
                                        </div>
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group>
                                        <Select
                                            options={themes}
                                            styles={selectStyles}
                                            name="themes"
                                            onChange={(meta, selected) => handleSelectedOptions(meta, selected, i)}
                                            placeholder={content.themes}
                                        />
                                        <div className="form-label">
                                            <i className="fal fa-paint-brush-alt"></i>
                                            <span className="required-field"></span>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <ImageUploadField
                                type="file"
                                accept="image/png, image/jpeg"
                                label={'Image'}
                                format="1000x1000 px (max 2mb)"
                                button={'Upload'}
                                icon="fal fa-user-circle"
                                // innerRef={register({ required: true })}
                                name="profileImage"
                                callbackFile={event => handleFile(event, i)}
                            />

                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <TagsInput
                                            value={fields['urls'] === '' ? [] : fields['urls']}
                                            onChange={e => handleTags('urls', e, i)}
                                            name="prizesWon"
                                            addKeys={[9, 13, 188]}
                                            inputProps={{
                                                placeholder: 'Urls',
                                            }}
                                        />
                                        <div className="form-label">
                                            <i className="fal fa-award"></i>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        {fields['yearExactDate'] ? (
                                            <React.Fragment>
                                                <DatePicker
                                                    dateFormat={'dd-MM-yyyy'}
                                                    showYearDropdown={true}
                                                    selected={fields['year']}
                                                    onChange={date => handleChangeDate(date, i)}
                                                    name="year"
                                                    placeholderText={'Year'}
                                                />
                                                <div className="form-label">
                                                    <i className="fal fa-history"></i>
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Form.Control
                                                    type="text"
                                                    name="year"
                                                    value={fields['year']}
                                                    onChange={e => updateField(e, i)}
                                                    placeholder={'Year'}
                                                />
                                                <div className="form-label">
                                                    <i className="fal fa-map-marker-check"></i>
                                                    <span className="required-field"></span>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </Form.Group>
                                    <Form.Check
                                        style={{ marginBottom: '15px' }}
                                        custom
                                        type="checkbox"
                                        onChange={e => handleDisplayExactDate(i)}
                                        label="Exact Year"
                                    />
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <TagsInput
                                            value={fields['technique'] === '' ? [] : fields['technique']}
                                            onChange={val => handleTags('technique', val, i)}
                                            name="prizesWon"
                                            addKeys={[9, 13, 188]}
                                            inputProps={{
                                                placeholder: 'Technique',
                                            }}
                                        />
                                        <div className="form-label">
                                            <i className="fal fa-award"></i>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Form.Row>

                            {fields['artWorkType']['options'].length > 0 &&
                                fields['artWorkType']['selected'] === 'visual' && (
                                    <Form.Row>
                                        <Col>
                                            <Form.Label>Visual</Form.Label>
                                            <Form.Group>
                                                <Select
                                                    styles={selectStyles}
                                                    name="visual"
                                                    options={fields['artWorkType']['options']}
                                                    onChange={(meta, selected) =>
                                                        handleArtTypeSelected(meta, selected, i)
                                                    }
                                                    placeholder={'Nothing selected'}
                                                />
                                                <div className="form-label">
                                                    <i className="fal fa-paint-brush-alt"></i>
                                                    <span className="required-field"></span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                )}

                            {fields['artWorkType']['options'].length > 0 &&
                                fields['artWorkType']['selected'] === 'music' && (
                                    <Form.Row>
                                        <Col>
                                            <Form.Label>Music</Form.Label>
                                            <Form.Group>
                                                <Select
                                                    styles={selectStyles}
                                                    name="music"
                                                    options={fields['artWorkType']['options']}
                                                    onChange={(meta, selected) =>
                                                        handleArtTypeSelected(meta, selected, i)
                                                    }
                                                    placeholder={'Nothing selected'}
                                                />
                                                <div className="form-label">
                                                    <i className="fal fa-paint-brush-alt"></i>
                                                    <span className="required-field"></span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                )}

                            {fields['artWorkType']['options'].length > 0 &&
                                fields['artWorkType']['selected'] === 'dramatic' && (
                                    <Form.Row>
                                        <Col>
                                            <Form.Label>Dramatic</Form.Label>
                                            <Form.Group>
                                                <Select
                                                    styles={selectStyles}
                                                    name="dramatic"
                                                    options={fields['artWorkType']['options']}
                                                    onChange={(meta, selected) =>
                                                        handleArtTypeSelected(meta, selected, i)
                                                    }
                                                    placeholder={'Nothing selected'}
                                                />
                                                <div className="form-label">
                                                    <i className="fal fa-paint-brush-alt"></i>
                                                    <span className="required-field"></span>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                )}
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <div className="education-fields repeater-wrapper artists-art-work">
            {generateArtWorksFields()}
            <Form.Row>
                <Col className="text-center">
                    <button className="btn btn-clear btn-add" onClick={e => addFieldToGroup(e)}>
                        <i class="fal fa-plus-circle"></i>
                        ADD ARTWORK
                    </button>
                </Col>
            </Form.Row>
        </div>
    )
}
