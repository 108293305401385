import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form } from 'react-bootstrap'
import Personal from './Personal'
import Contact from './Contact'
import Terms from './Terms'
import Submit from './Submit'
import Professional from './Professional'
import Biography from './Biography'
import Profile from './Profile'
import useForm from 'react-hook-form'
import Helpers from '../../../helpers'
import Api from '../../../api'
import { useAlert } from 'react-alert'
import Auth from '../../../auth'
import ArtWorks from './ArtWorks'
import Activities from './Activities'
import PrizesWon from "./PrizesWon"
import Associations from "./Associations"


export default function ArtistForm({ form, globalContent }) {
    const history = useHistory()
    const { register, handleSubmit, errors, setValue, setError } = useForm({ reValidateMode: 'onSubmit' })
    const alert = useAlert()

    const [themes, setThemes] = useState([]);
    const [artTypes, setArtTypes] = useState(false);

    useEffect(() => {
        Object.keys(errors).forEach(err => {
            alert.error(`${err} is required`)
        })
        // eslint-disable-next-line
    }, [errors])

    const onSubmit = async artistFormData => {
        if (artistFormData.dateOfBirth) {
            artistFormData.dateOfBirth = new Date(artistFormData.dateOfBirth).getFullYear().toString()
        }

        if (artistFormData.dateOfDeath) {
            artistFormData.dateOfDeath = new Date(artistFormData.dateOfDeath).getFullYear().toString()
        }

        if (Auth.isLoggedIn()) {
            const userDataJson = sessionStorage.getItem('USER_DATA')
            const userData = JSON.parse(userDataJson)
            artistFormData.loggedInSubmitter = { ...artistFormData.submitter }
            artistFormData.submitter = userData._id
        }
        if (!(artistFormData.biography_en.length || artistFormData.biography_sq.length)) {
            setError('biography', 'notMatch', 'Please upload a biography!')
            return 
        }

        let formData = Helpers.filterForm(artistFormData, true)
        const validatedArtWorks = Helpers.validateArtistRelationships(artistFormData.artworks, ["title", "image", "year", "themes"], alert, "artwork")
        const validatedActivities = Helpers.validateArtistRelationships(artistFormData.activities, ["name", "image", "description", "artType"], alert, "activity")
        const validatedArtType = Helpers.validateArtistArtType(artistFormData, alert)

        if (artistFormData.activities && artistFormData.activities.length > 0) formData = Helpers.filterArtistRelationshipsImages(artistFormData, formData, "activities", "activity_")
        if (artistFormData.artworks && artistFormData.artworks.length > 0) formData = Helpers.filterArtistRelationshipsImages(artistFormData, formData, "artworks", "artwork_")

        formData.set('activities', JSON.stringify(artistFormData.activities));
        formData.set('artworks', JSON.stringify(artistFormData.artworks));

        if (!validatedArtWorks || !validatedActivities || !validatedArtType) return;
        const response = await Api.post('artist', formData, true)
        Helpers.validateResponse(response, alert, form.success_message)

        setTimeout(() => {
            history.push('/artist-submission-complete')
        }, 1000)
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Personal content={form.personal} register={register} globalContent={globalContent} setValue={setValue} />
            <Professional
                content={form.professional}
                register={register}
                globalContent={globalContent}
                setValue={setValue}
            />
            <Biography content={form.biography} register={register} setValue={setValue} />
            <PrizesWon content={form.personal} register={register} globalContent={globalContent} setValue={setValue} />
            <Associations content={form.personal} register={register} globalContent={globalContent} setValue={setValue} />
            <Contact content={form.contact} register={register} setValue={setValue} />
            <Profile content={form.profile} register={register} setValue={setValue} setThemes={setThemes} setArtTypes={setArtTypes} />
            <ArtWorks content={form.profile} register={register} setValue={setValue} artTypes={artTypes} themes={themes} />
            <Activities register={register} setValue={setValue} />
            <Terms content={form.terms} register={register} />
            <Submit content={form.submit} register={register} />
        </Form>
    )
}
