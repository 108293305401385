import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import SectionTitle from '../../components/Global/SectionTitle'
import Logo from '../../assets/images/logo_black.svg'

export default function TermsPage() {
    const { t } = useTranslation()
    const termsPage = t('termsPage', { returnObjects: true })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section className="terms-and-conditions-section">
                <Container>
                    <div>
                        <div className="text-center">
                            <img
                                src={Logo}
                                style={{ width: 80, marginBottom: 30 }}
                                alt="databaza e artisteve të ekosoves"
                            />
                        </div>
                        <SectionTitle title={termsPage.title} subtitle="" />
                        {termsPage.content.map((item, i) => {
                            return (
                                <div key={i} className="part">
                                    <h4>{item.title}</h4>
                                    {item.list ? (
                                        <ol>
                                            {item.list.map((li, j) => (
                                                <li key={j}>{li}</li>
                                            ))}
                                            {item.list.map((li, j) => (
                                                <li key={j}>{li}</li>
                                            ))}
                                        </ol>
                                    ) : (
                                        <>
                                            {item.text.map((txt, j) => (
                                                <p key={j}>{txt}</p>
                                            ))}
                                        </>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </Container>
            </section>
        </div>
    )
}
