import React from 'react'
import { Container} from 'react-bootstrap';
import './SingleMediaPage.scss';

export default function SingleMediaPage(props) {
    return ( 
		<React.Fragment>
			<Container>
			<div className="navcontainer ">
				<div className="media-news col-md-10 offset-md-1">
					<ul className="navlist exhibitions-list">
						<li className="data.art">Databaza.art {">"} </li>
						<li className="data.art">Media {">"} </li>
						<li className="data.art">Exhibitions </li>
						<li className="icon"> </li>
						<li className="icon"> </li>
					</ul>
					<div className="navlist exhibitions-list text-title-and-author">
						<p className="text-black-girl">The Legends of Black Girl’s Window</p>
						<p className="icon">Prishtine  ·  Galeria Qahili</p>
					</div>
				</div>
			</div>


	
	 <div className=" col-md-10 offset-md-1">
			<div className="img-back">
			<img src={require('../../assets/images/default_cover_image.jpg')} alt="" />
			</div>
			<div className="calendar">
					<div className="box-1">
						<span className="data-nr">17</span>
					</div>
					<div className="box-2">
						<span className="month">FEB</span>
					</div>
			</div>
	</div>

	
		<div className="col-md-10 offset-md-1 singlemedia">
				<p >
					Galeria Qahili is one of the first private art galleries, established in May of 2013. It is a gallery dedicated to celebrating the creative process as well as increasing cultural and social capital within different social groups. Galeria Qahili organizes a broad range of artistic activities such as exhibitions. Galeria Qahili is one of the first private art galleries, established in May of 2013. It is a gallery dedicated to celebrating the creative process as well as increasing cultural and social capital within different social groups. Galeria Qahili organizes a broad range of artistic activities such as exhibitions.	
				</p>

				<p >
					When building a website or PWA, no one ever thinks, “I really hope my visitors run away in fear!” Yet, one wrong move could make a visit to your website seem like a nightmarish walk through a haunted house instead of an awe-inspiring tour of a new home.
				</p>
				
				<img src={require('../../assets/images/default_cover_image.jpg')} alt="" />
				<img src={require('../../assets/images/default_cover_image.jpg')} alt="" />
					
				
				<p >
					To be clear, I’m not talking about dark color palettes or blood-red typography that might remind someone of something they’d seen in a horror movie. If those kinds of design choices make sense and don’t intrude on readability, go for it! What I’m talking about are those ominous feelings emitted by the design and content of a website — similar to the ones someone might feel when walking through a haunted house.
				</p>

				<p >
					Dr. Frank T. McAndrew answered the question “What Makes a House Feel Haunted?” in an article on Psychology Today back in 2015. He explains:
				</p>

				<p>
					“From a psychological point of view, the standard features of haunted houses trigger feelings of dread because they push buttons in our brains that evolved long before houses even existed. These alarm buttons warn us of potential danger and motivate us to proceed with caution.”
					When a visitor shows up on your website, the last thing you want is for them to be wary of moving through it. You want your website to give off a welcoming and safe vibe; not one that makes visitors wonder what’s lurking around the corner.
				</p>
				
				<p >
					So, today, I want to look at some ways in which a website might be giving your visitors the heebie-jeebies and what you can do to eliminate those haunted house-like elements from the experience.
				</p>
			
				<div className="go-to-event">
					<button className="button-go-event">
						 GO TO EVENT’S WEBSITE
					</button>
				</div>
		</div>

		</Container>
		</React.Fragment>
    );
}