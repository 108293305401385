import React from 'react'
function PageTitle({ title, subtitle }) {
    if (typeof subtitle === 'object') {
        let subtitleString = ''
        subtitle.forEach((sub, i) => {
            subtitleString += sub
            if (subtitle.length - 1 !== i) {
                subtitleString += ', '
            }
        })
        subtitle = subtitleString
    }
    return (
        <div className="title-wrapper text-center">
            {title && <h1 className="page-title">{title}</h1> }
            {subtitle && <h4 className="subtitle">{subtitle}</h4>}
        </div>
    )
}
export default PageTitle
