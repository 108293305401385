import React from 'react'
import { Navbar } from 'react-bootstrap'
import logo from '../../assets/images/DB-bardhe-03.svg'

function Logo() {
    return (
        <Navbar.Brand href="/">
            <img src={logo} alt="logo" />
        </Navbar.Brand>
    )
}
export default Logo
