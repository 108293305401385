import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import ImageTag from '../Global/ImageTag'
import { LanguageContext } from '../../contexts/LanguageContext'
export default function BlogLayoutGrid({ post, postContent }) {
    const { language } = useContext(LanguageContext)

    return (
        <div className="col-12 col-lg-4 all-art">
            {post && post.featuredImage && (
                <div className=" media-news">
                    <ImageTag className="img-art" src={post.featuredImage} />
                </div>
            )}
            <div className=" media-news">
                <p className="data">
                    <span>
                        {new Date(post.createdAt).toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        })}
                    </span>
                    <span style={{ margin: '0 5px' }}>·</span>
                    <span>{post.author}</span>
                    {post.categories && post.categories.length !== 0 && (
                        <>
                            <span style={{ margin: '0 5px' }}>·</span>
                            <span className="custom-post-categories">
                                {post.categories.map(cat => (
                                    <b key={cat._id}>{cat.en}</b>
                                ))}
                            </span>
                        </>
                    )}
                </p>

                <Link className="title-art" to={`/news/${post._id}`}>
                    {post.multiLanguageFields[language].title}
                </Link>
                <p className="text-art">
                    {postContent.slice(0, 200)}
                    {postContent.length > 200 && '...'}
                </p>
                <div className="button-read-more">
                    <Link to={`/news/${post._id}`} className="read-more-button">
                        READ MORE
                    </Link>
                </div>
            </div>
        </div>
    )
}
