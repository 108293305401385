import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import useForm from 'react-hook-form'
import Api from '../../../api'
import Personal from './Personal'
import Contact from './Contact'
//import Membership from './Membership'
import Terms from './Terms'
import Submit from './Submit'
import Profile from './Profile'
import Helpers from '../../../helpers'
import { useAlert } from 'react-alert'
import { withRouter } from 'react-router-dom'

function UserForm({ form, editDetails, userInfo = {}, history }) {
    const { register, handleSubmit, setValue, errors, getValues } = useForm({ reValidateMode: 'onSubmit' })
    const [subscriptionId, setSubscriptionId] = useState(null)
    const alert = useAlert()

    useEffect(() => {
        Object.keys(errors).forEach(err => {
            alert.error(`${err} is required`)
        })
        // eslint-disable-next-line
    }, [errors])

    /* TODO: remove fallback on basic subscription */
    useEffect(() => {
        async function getSubscribers() {
            const subscribtions = await Api.get('subscriptions')
            const res = Helpers.validateResponse(subscribtions, alert)
            if (!res || res.length === 0) return
            setSubscriptionId(res[0]._id)
        }

        getSubscribers()
        // eslint-disable-next-line
    }, [])
    /* TODO: remove fallback on basic subscription END */

    const onSubmit = async userFormData => {
        /* TODO: remove fallback on basic subscription */
        if (subscriptionId) userFormData.subscription = subscriptionId
        /* TODO: remove fallback on basic subscription END */
        let formData = Helpers.filterForm(userFormData, true)

        const response = await Api.post('subscribers', formData, true)
        const res = Helpers.validateResponse(response, alert, form.success_message)
        if (!res) return

        setTimeout(() => {
            history.push('/registration-complete')
        }, 2000)
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Personal
                content={form.personal}
                register={register}
                setValue={setValue}
                getValues={getValues}
                editDetails={editDetails}
                userInfo={userInfo}
            />
            <Contact
                content={form.contact}
                register={register}
                setValue={setValue}
                getValues={getValues}
                editDetails={editDetails}
                userInfo={userInfo}
            />
            <Profile content={form.profile} register={register} editDetails={editDetails} userInfo={userInfo} />
            {/* <Membership
                content={form.membership}
                register={register}
                setValue={setValue}
                getValues={getValues}
                editDetails={editDetails}
                userInfo={userInfo}
            /> */}
            <Terms content={form.terms} register={register} />
            <Submit content={form.submit} register={register} />
        </Form>
    )
}

export default withRouter(UserForm)
