import React from 'react'
import { useState, useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { useContext } from 'react'
import { LanguageContext } from '../../contexts/LanguageContext'

export default function({
    options,
    placeholder,
    handleOnChange,
    returnLabel,
    checkbox,
    showMoreFilters,
    setShowMoreFilters,
    disabled,
}) {
    const [showDropDown, setShowDropDown] = useState(false)
    const [stateOptions, setStateOptions] = useState(options)
    const [label, setLabel] = useState([])
    const { language } = useContext(LanguageContext)

    useEffect(() => {
        setStateOptions(options)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    useEffect(() => {
        window.addEventListener('click', handleClickOutside)
    })

    useEffect(() => {
        /* Set label to empty array, and uncheck all checkboxes */
        let tempArr = stateOptions === undefined || !stateOptions ? [] : [...stateOptions]
        if (tempArr && tempArr.length > 0) tempArr.forEach(elem => (elem.checked = false))
        setStateOptions(tempArr)
        setLabel([])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])

    const handleClickOutside = e => {
        let classes = [
            'more-filters-text',
            'dropdown-parent-data',
            'select-placeholder-parent',
            'jss4',
            'MuiIconButton-label',
            'MuiIconButton-colorSecondary',
            'multi-select-dropdown-menu',
            'PrivateSwitchBase-input-4',
            'more-filter-checkbox',
            'MuiButtonBase-root',
            'MuiIconButton-root',
            'placeholderText',
        ]
        if (!classes.includes(e.target.className)) {
            setShowDropDown(false)
        }
    }

    const removeFromLabel = text => {
        let labelsTempArr = [...label]
        labelsTempArr = labelsTempArr.filter(el => {
            return el !== text
        })
        setLabel(labelsTempArr)
    }

    const addToLabel = text => {
        let labelsTempArr = [...label]
        !labelsTempArr.includes(text) && labelsTempArr.push(text)
        setLabel(labelsTempArr)
    }

    const returnData = data => {
        let arr = []
        data.forEach(el => el.checked && arr.push(returnLabel ? el.label : el.key))
        return arr
    }

    const onChangeCheckBox = (e, index, text) => {
        let tempArr = [...stateOptions]
        if (!tempArr || tempArr.length === 0) return
        if (tempArr[index].checked) {
            tempArr[index].checked = false
            removeFromLabel(text)
        } else {
            tempArr[index].checked = true
            addToLabel(text)
        }

        setStateOptions(tempArr)
        handleOnChange && typeof handleOnChange === 'function' && handleOnChange(returnData(tempArr))
    }

    const renderPlaceHolder = () => {
        let arr = []
        label.forEach(elem => arr.push(elem))
        return arr.join(', ')
    }

    return (
        <div>
            <div
                className={!checkbox ? 'select-placeholder-parent' : 'select-placeholder-parent-no-hover'}
                onClick={() => setShowDropDown(!showDropDown)}
            >
                <div className="placeholderText">
                    <span className="more-filters-text" style={disabled ? { fontWeight: 'bold' } : {}}>
                        {label.length === 0 ? placeholder : renderPlaceHolder()}
                    </span>
                </div>

                <div>
                    {checkbox && !disabled ? (
                        <Checkbox
                            checked={showMoreFilters}
                            style={{ color: 'black', backgroundColor: 'transparent' }}
                            onChange={e => setShowMoreFilters(!showMoreFilters)}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            className="more-filter-checkbox"
                        />
                    ) : (
                        <>{!disabled && <i className="fas fa-angle-down dropdown-icon"></i>}</>
                    )}
                </div>
            </div>
            {showDropDown && stateOptions && Array.isArray(stateOptions) && stateOptions.length > 0 && (
                <div className="multi-select-dropdown-menu">
                    {stateOptions.map((el, index) => (
                        <div
                            key={el.key}
                            onClick={e => {
                                e.stopPropagation()
                                onChangeCheckBox(e, index, el && (el.sq || el.en) ? el[language] : el.label)
                            }}
                            className="dropdown-parent-data"
                        >
                            <Checkbox
                                checked={el && el.checked ? true : false}
                                style={{ color: 'black', backgroundColor: 'transparent' }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                className="more-filter-checkbox"
                            />
                            <span className="more-filters-text">
                                {el && (el.sq || el.en) ? el[language] : el.label}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}
