import React from 'react'
import Color from 'color'
import { Link } from 'react-router-dom'
import ImageTag from '../Global/ImageTag'

function PricingTable({
    title,
    slug,
    image,
    features,
    price,
    brand,
    defaultHighlighted,
    shortDesc,
    longDesc,
    description,
    globalContent,
}) {
    const brandColor = Color(brand)

    return (
        <article className={`pricing-table col-md-3 ${defaultHighlighted ? 'active' : ''}`}>
            <header className="table-header">
                <h4 className="title" style={{ color: brand }}>
                    {title}
                </h4>
                <figure>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 139.39">
                        <defs>
                            <linearGradient id={slug} x1="0%" y1="0%" x2="90%" y2="90%">
                                <stop offset="0%" style={{ stopColor: brandColor.darken(0.4).hex(), stopOpacity: 1 }} />
                                <stop
                                    offset="100%"
                                    style={{ stopColor: brandColor.lighten(0.4).hex(), stopOpacity: 1 }}
                                />
                            </linearGradient>
                        </defs>
                        <path
                            fill={`url(#${slug})`}
                            d="M0,72.14v38.27h112.7l27.26,29V62.33h0c0-8.37-8.22-25.26-8.95-26.56a70.07,70.07,0,0,0-118.49-5.9q-1.77,2.52-3.32,5.21C8.21,36.86,0,54.32,0,62.33H0S0,71.46,0,72.14Z"
                        />
                    </svg>
                    <ImageTag src={image} alt={title} />
                </figure>
            </header>
            <div className="table-body">
                <ul className="list-unstyled">{features && features.map((f, i) => <li key={i}>{f}</li>)}</ul>
                {description && (
                    <div className="description">
                        <p>
                            <b>{shortDesc}</b>
                        </p>
                        <p>{longDesc}</p>
                    </div>
                )}

                {price !== undefined && (
                    <p className="price">{price === 0 ? <b>{globalContent.free}</b> : <b>{price} €</b>}</p>
                )}
            </div>
            <footer className="table-footer">
                <button
                    className="btn btn-dynamic-brand"
                    style={{ backgroundColor: brand, color: brandColor.isDark() && 'white' }}
                >
                    {globalContent.subscribe}
                </button>
                <Link className="btn btn-light" to={`/read-more:${slug}`}>
                    {globalContent.read_more}
                </Link>
            </footer>
        </article>
    )
}

export default PricingTable
