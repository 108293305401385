import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { LanguageContext } from '../../contexts/LanguageContext'

function FeaturedArtist({ title, posts }) {
    const { language } = useContext (LanguageContext)
    const post = posts[0]

    const getField = (field) => {
        if (post && post.multiLanguageFields && post.multiLanguageFields[language] && post.multiLanguageFields[language][field]) {
            return post.multiLanguageFields[language][field]
        }
    }

    return (
        <div className="widget">
            <header>
                <h4>{title}</h4>
            </header>
            {posts && posts.length !== 0 && (
                <article className="featured-artist">
                    <figure>
                        {post.featuredImage && (
                            <img
                                src={`${process.env.REACT_APP_IP}:${process.env.REACT_APP_API_PORT}/${post.featuredImage}`}
                                alt={post.title}
                            />
                        )}
                    </figure>
                    <footer>
                        <div className="artist-meta">
                            <span className="category">{getField('occupation') && getField('occupation')}</span>
                            <span className="date">1941</span>
                        </div>
                        <Link className="artist-name" to={`/news/${post._id}`}>{getField('title') && getField('title')}</Link>
                    </footer>
                </article>
            )}
        </div>
    )
}
export default FeaturedArtist
