import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../components/Global/PageTitle'
import SectionTitle from '../../components/Global/SectionTitle'
// import About from '../../components/About'
import HowItWorksAlternative from '../../components/HowItWorksAlternative'
// import Sponsors from '../../components/Sponsors'
import { Container, Row, Col } from 'react-bootstrap'
import purpose from '../../assets/images/dak-all-white.svg'
import { Link } from 'react-router-dom'

export default function AboutPage() {
    const { t } = useTranslation()
    const aboutContent = t('aboutpage', { returnObjects: true })
    const globalContent = t('global', { returnObjects: true })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const zip = (a1, a2) =>
        a1.map((x, i) => {
            return (
                <li className="col-sm-6 purpose-list-item" key={i}>
                    <span className="bold">{x} - </span>
                    {a2[i]}
                </li>
            )
        })

    return (
        <div>
            <section className="about-page-description text-center">
                <Container>
                    <PageTitle title={aboutContent.title} subtitle={aboutContent.subtitle} />
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            {aboutContent.description.map((p, i) => (
                                <p key={i}>{p}</p>
                            ))}
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="why-do-we-exist text-center">
                <Container>
                    <SectionTitle title={aboutContent.whydoweexist.title} subtitle="" />
                    <Row className="justify-content-md-center">
                        <Col md={8}>{aboutContent.whydoweexist.content}</Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col sm={12}>
                            <Link className="btn btn-clear btn-shadow btn-faq" to="/faq">
                                {globalContent.faq}
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </section>

            <HowItWorksAlternative />
            <section className="our-purpose">
                <Container>
                    <SectionTitle title={aboutContent.about_gallery.title} />
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            <p className="text-center">{aboutContent.about_gallery.description}</p>
                            <figure className="about-image">
                                <img src={purpose} alt={aboutContent.about_gallery.title} />
                            </figure>
                            <h4 className="mb-4 mt-5 text-center">{aboutContent.about_gallery.list.title}</h4>
                            <ul className="row purpose-list">
                                {zip(
                                    aboutContent.about_gallery.list.listTitle,
                                    aboutContent.about_gallery.list.listDescription
                                )}
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Left for the future */}
            {/* <Sponsors content={aboutContent.sponsors} /> */}
        </div>
    )
}
