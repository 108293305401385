import React from 'react'
import { Form, Col } from 'react-bootstrap'
import MultilangUploadField from '../../Global/MultilangUploadField'
import ResidencyFields from './ResidencyFields'

export default function Biography({ content, register, setValue }) {
    const updateResidencyFields = data => {
        setValue('residency', data)
    }

    return (
        <div className="form-section">
            <Form.Group>
                <h4 className="text-center">{content.biography}</h4>
            </Form.Group>

            <MultilangUploadField
                type="file"
                accept="application/pdf,application/vnd.ms-excel"
                label={content.biography}
                format=".pdf, .docx max 2mb"
                button={content.upload}
                icon="fal fa-book-user"
                shortDesc={content.shortDesc}
                innerFileName="biography"
                innerDescName="shortBio"
                facts={true}
                innerFactsName="facts"
                factsLabel={content.facts}
                register={register}
                setValue={setValue}
            />

            <Form.Row>
                <Col>
                    <Form.Label>{content.residency.residency}</Form.Label>
                </Col>
            </Form.Row>
            <div className="residency-fields repeater-wrapper">
                <ResidencyFields residency={content.residency} updateResidencyFields={updateResidencyFields} />
            </div>
        </div>
    )
}
