import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom'
import App from './App'
import PageLoading from './components/PageLoading'

ReactDOM.render(
    <Router basename="/">
        <Suspense fallback={<PageLoading />}>
            <App />
        </Suspense>
    </Router>,
    document.getElementById('root')
)
