import React, { useState } from 'react'
import { Form, Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const ResidencyFields = ({ residency, updateResidencyFields }) => {
    const initialValue = {
        name: '',
        place: '',
        year: '',
    }

    const [residencyFields, setResidencyFields] = useState([initialValue])
    const [displayResidencyExactDate, setDisplayResidencyExactDate] = useState([{ index: 0, bool: false, selected: false }])

    const addFieldGroup = e => {
        e.preventDefault()
        setResidencyFields([...residencyFields, initialValue])
    }

    const removeFieldGroup = (e, i) => {
        e.preventDefault()
        let tempFields = residencyFields
        tempFields.splice(i, 1)
        setResidencyFields([...tempFields])
        updateResidencyFields(residencyFields)
    }

    const updateValue = (e, i) => {
        let temp = [...residencyFields]
        temp[i][e.target.name] = e.target.value
        setResidencyFields(temp)
        updateResidencyFields(residencyFields)
    }

    const handleResidencyExactDate = (e, index) => {
        let temp = [...displayResidencyExactDate];
        if (!temp[index]) {
            let obj = { index: index, bool: false }
            temp.push(obj)
        }
        temp.forEach((obj, i) => {
            if (i === index) {
                obj['bool'] = !obj['bool']
            }
        }) 
        setDisplayResidencyExactDate(temp);
    }

    const handleChangeDate = (date, index) => {
        let temp = [...displayResidencyExactDate];
        temp[index]['selected'] = date;
        setDisplayResidencyExactDate(temp)
    } 

    const generateResidencyFields = () => {
        return (
            <>
                {residencyFields &&
                    residencyFields.map((fields, i) => {
                        return (
                            <div className="field-group-wrapper" key={i}>
                                <Form.Row>
                                    <span
                                        className="remove-field-group"
                                        onClick={e => {
                                            removeFieldGroup(e, i)
                                        }}
                                    >
                                        <i className="fal fa-times"></i>
                                    </span>
                                    <Col>
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={fields['name']}
                                                onChange={e => {
                                                    updateValue(e, i)
                                                }}
                                                placeholder={residency.name}
                                            />
                                            <div className="form-label">
                                                <i className="fal fa-home-lg-alt"></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                name="place"
                                                value={fields['place']}
                                                onChange={e => {
                                                    updateValue(e, i)
                                                }}
                                                placeholder={residency.place}
                                            />
                                            <div className="form-label">
                                                <i className="fal fa-map-marker-check"></i>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                        {
                                            displayResidencyExactDate && displayResidencyExactDate[i] && displayResidencyExactDate[i]['bool']
                                                ? <React.Fragment>
                                                    <DatePicker
                                                        dateFormat={"dd-MM-yyyy"}
                                                        showYearDropdown={true}
                                                        selected={displayResidencyExactDate[i]['selected']}
                                                        onChange={date => handleChangeDate(date, i)}
                                                        name="year"
                                                        placeholderText={residency.year}
                                                    />
                                                    <div className="form-label">
                                                        <i className="fal fa-history"></i>
                                                    </div>
                                                </React.Fragment>
                                                : <React.Fragment>
                                                        <Form.Control
                                                            type="text"
                                                            name="year"
                                                            value={fields['year']}
                                                            onChange={e => {
                                                                updateValue(e, i)
                                                            }}
                                                            placeholder={residency.year}
                                                        />
                                                        <div className="form-label">
                                                            <i className="fal fa-history"></i>
                                                        </div>
                                                </React.Fragment>
                                        }
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Check
                                    custom
                                    type="checkbox"
                                    onChange={(e) => { handleResidencyExactDate(e, i) }}
                                    label="Exact Year"
                                />
                            </div>
                        )
                    })}
            </>
        )
    }

    return (
        <>
            {generateResidencyFields()}
            <Form.Row>
                <Col className="text-center">
                    <button className="btn btn-clear btn-add" onClick={addFieldGroup}>
                        <i class="fal fa-plus-circle"></i>
                        ADD RESIDENCY
                    </button>
                </Col>
            </Form.Row>
        </>
    )
}

export default ResidencyFields
