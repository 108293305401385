import React from 'react'
import PlacesAutocomplete from 'react-places-autocomplete';
import { Form } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'

export default function GooglePlacesInput({ value, onChange, onSelect }) {
    return (
        <div>
            <PlacesAutocomplete
                value={value}
                onChange={onChange}
                onSelect={onSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <Form.Control
                            type="tel"
                            {...getInputProps({
                                placeholder: 'Location',
                                className: 'location-search-input',
                            })}  
                        />
                        <div className="autocomplete-dropdown-container">
                        { loading && <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner> 
                        }
                        {suggestions.map((suggestion, i) => {
                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                            ? { backgroundColor: '#f1f1f1', cursor: 'pointer', padding: 15 }
                            : { backgroundColor: '#fffdfd', cursor: 'pointer', padding: 15 };
                            return (
                                <div style={style} key={i} {...getSuggestionItemProps(suggestion, { className })} >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    )
}