import React, { useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'

export default function Contact({ content, register, centerTitle, editDetails, userInfo, setValue }) {
    useEffect(() => {
        if (editDetails) {
            Object.keys(userInfo).forEach(info => {
                if (info !== 'profileImage' && info !== 'coverImage') {
                    setValue(info, userInfo[info])
                }
            })
        }
    })
    return (
        <div className="form-section">
            <Form.Group>
                <h4 className={`${!editDetails ? 'text-center' : ''}`}>{content.title}</h4>
            </Form.Group>
            <Form.Row>
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder={content.email}
                            ref={register({ required: true })}
                        />
                        <div className="form-label">
                            <i className="fal fa-envelope"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Control type="tel" name="phoneNumber" placeholder={content.phone} ref={register} />
                        <div className="form-label">
                            <i className="fal fa-phone"></i>
                        </div>
                    </Form.Group>
                </Col>
            </Form.Row>

            <Form.Row>
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Control
                            type="password"
                            name="password"
                            placeholder={content.password}
                            ref={register({ required: true })}
                        />
                        <div className="form-label">
                            <i className="fal fa-unlock"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
                <Col md="6" sm="12">
                    <Form.Group>
                        <Form.Control
                            type="password"
                            name="crf_password"
                            placeholder={content.confirm_password}
                            ref={register({ required: true })}
                        />
                        <div className="form-label">
                            <i className="fal fa-unlock"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
                <Col md="12" sm="12">
                    <Form.Text className="text-muted">{content.password_requirements}</Form.Text>
                </Col>
            </Form.Row>
        </div>
    )
}
