import React, { useState } from 'react'
import { Form, Col } from 'react-bootstrap'
import { useAlert } from 'react-alert'
import Api from '../../api'
import Helpers from '../../helpers'
import './ContactForm.scss'
import Select from 'react-select'

export default function ContactForm({ content }) {
    const formInitialValue = {
        fullName: '',
        email: '',
        message: '',
        reason: '',
    }
    const [formData, setFormData] = useState(formInitialValue)

    const genderOptions = [
        { value: 'SPONSORSHIP', label: 'Sponsorship' },
        { value: 'PARTNERSHIP', label: 'Partnership' },
        { value: 'REQUEST', label: 'Request' },
        { value: 'VACANCIES', label: 'Vacancies' },
        { value: 'OTHER', label: 'Other' },
    ]
    const selectStyles = Helpers.getSelectStyles()
    const alert = useAlert()
    const handleFormChange = e => {
        let tempObj = { ...formData }
        tempObj[e.target.name] = e.target.value

        setFormData(tempObj)
    }

    const handleChangeSelect = e => {
        let tempObj = { ...formData }
        tempObj['reason'] = e.value
        setFormData(tempObj)
    }

    const handleFormSubmit = async e => {
        e.preventDefault()
        const response = await Api.post('contact', formData)
        const res = Helpers.validateResponse(response, alert, content.success_message)
        if (res) {
            setFormData(formInitialValue)
        }
    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
            padding: 20,
        }),
    }
    return (
        <Form onSubmit={handleFormSubmit}>
            <Form.Row>
                <Col sm="12" md="6">
                    <Form.Group>
                        <Form.Control
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleFormChange}
                            placeholder={content.name}
                            className={formData.fullName ? 'green-border' : ''}
                        />
                        <div className="form-label">
                            <i className="fal fa-user"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
                <Col sm="12" md="6">
                    <Form.Group>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            placeholder={content.email}
                            onChange={handleFormChange}
                            className={formData.email ? 'green-border' : ''}
                        />
                        <div className="form-label">
                            <i class="fal fa-envelope"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col sm="12" md="12">
                    <Form.Group>
                        <Select
                            options={genderOptions}
                            styles={selectStyles}
                            name="gender"
                            onChange={handleChangeSelect}
                            placeholder={content.reason}
                            className={formData.reason ? 'green-border' : ''}
                        />
                        <div className="form-label">
                            <i class="fal fa-list"></i>
                        </div>
                    </Form.Group>
                </Col>
            </Form.Row>

            <Form.Row>
                <Col sm="12" md="12">
                    <Form.Group>
                        <Form.Control
                            as="textarea"
                            placeholder={content.message}
                            rows="6"
                            style={{ minHeight: 250 }}
                            name="message"
                            value={formData.message}
                            onChange={handleFormChange}
                            className={formData.message ? 'green-border' : ''}
                        />
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Group className="text-center">
                <button className="btn btn-dark btn-lg contact-form-submit" type="submit">
                    {content.submit}
                </button>
            </Form.Group>
        </Form>
    )
}
