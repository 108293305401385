import React from 'react'
import { Link } from 'react-router-dom'
import SocialNetworks from './SocialNetworks'

function Nav({ nav }) {
    return (
        <>
            <ul className="navbar-nav mr-auto ml-auto">
                <li className="nav-item">
                    <Link to="/" className="nav-link">
                        {nav.home}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/search" className="nav-link">
                        {nav.media}
                    </Link>
                </li>
                {/* <li className="nav-item">
                    <Link to="/pricing" className="nav-link">
                        {nav.pricing}
                    </Link>
                </li> */}
                <li className="nav-item">
                    <Link to="/about" className="nav-link">
                        {nav.about}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/contact" className="nav-link">
                        {nav.contact}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/terms-and-conditions" className="nav-link">
                        {nav.terms}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/privacy-policy" className="nav-link">
                        {nav.privacyPolicy}
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/disclaimer" className="nav-link">
                        {nav.disclaimer}
                    </Link>
                </li><li className="nav-item">
                    <Link to="/faq" className="nav-link">
                        {nav.faq}
                    </Link>
                </li>
            </ul>
            <div className="site-menu d-flex">
                <SocialNetworks />
            </div>
        </>
    )
}
export default Nav
