import './BlogEvent.scss'
import React, { useContext } from 'react'
import { LanguageContext } from '../../contexts/LanguageContext'
import { Link } from 'react-router-dom'
import BlogLayoutEventGrid from '../News/BlogLayoutEventGrid'
import ImageTag from '../Global/ImageTag'

export default function BlogEvent({ post, gridOption }) {
    const { language } = useContext(LanguageContext)

    if (!gridOption) {
        return (
            <>
                <div className="col-12 blog-item">
                    <div className="row">
                        <div className="col-12 col-lg-4 ">
                            {post.featuredImage && (
                                <div className="img-exhibitions-events">
                                    <ImageTag className="event-blog-img" src={post.featuredImage} />
                                </div>
                            )}
                        </div>

                        <div className="col-12 col-lg-8 wrapper-blogs event">
                            {post.startDate && (
                                <div className="calendar-event-blog">
                                    <div className="box-1-event-blog box-color-blog-event">
                                        <p className="data-nr-event-blog">{new Date(post.startDate).getDate()}</p>
                                    </div>
                                    <div className="box-2-event-blog box-color-blog-event">
                                        <p className="month-event-blog">
                                            {new Date(post.startDate).toLocaleDateString(undefined, { month: 'short' })}
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div className="city-author-title">
                                {(post.location || post.place) && (
                                    <p className="city-name-author">
                                        {post.location} · {post.place}
                                    </p>
                                )}
                                <p className="text-title-event-blog">
                                    <Link to={`/news/${post._id}`}>{post.multiLanguageFields[language].title}</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return <BlogLayoutEventGrid post={post} language={language} />
    }
}
