import React, { useState, useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'
import Select from 'react-select'
import Auth from '../../../auth'
import Helpers from '../../../helpers'

export default function Contact({ content, setValue, register }) {
    const initialValue = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    }

    const selectStyles = Helpers.getSelectStyles()
    const [submitter, setSubmitter] = useState(initialValue)
    const [artistRelation, setArtistRelation] = useState('')

    const handleFieldChange = e => {
        setSubmitter({ ...submitter, [e.target.name]: e.target.value })
        setValue('submitter', { ...submitter, [e.target.name]: e.target.value })
    }

    const contactRelationOptions = [
        { value: 'artist', label: 'Artist' },
        { value: 'representative', label: 'Representative' },
        { value: 'family_member', label: 'Family Member' },
        { value: 'other', label: 'Other' },
    ]

    useEffect(() => {
        register({ name: 'submitter' })
        //setValue('submitter', initialValue)
    })

    useEffect(() => {
        if (artistRelation === 'other') {
            // something
        }
    }, [artistRelation])

    const handleChangeSelectSubmitter = (selected, meta) => {
        setArtistRelation(selected.value)
        setSubmitter({ ...submitter, type: selected.value })
        setValue('submitter', { ...submitter, type: selected.value })
    }

    return (
        <div className="form-section">
            <Form.Group>
                <h4 className="text-center">{content.title}</h4>
            </Form.Group>
            <Form.Row>
                <Col md="12" sm="12">
                    <Form.Group>
                        <Select
                            options={contactRelationOptions}
                            styles={selectStyles}
                            ref={register}
                            name="type"
                            onChange={handleChangeSelectSubmitter}
                            placeholder="What is your relation with the artist?"
                        />
                        <div className="form-label">
                            <i className="fal fa-brush"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>

                {artistRelation === 'other' && (
                    <Col md="12" sm="12">
                        <Form.Group>
                            <Form.Control
                                type="text"
                                name="defineOther"
                                placeholder="Define Other"
                                onChange={handleFieldChange}
                                ref={register({ required: true })}
                            />
                            <div className="form-label">
                                <i className="fal fa-user"></i>
                                <span className="required-field"></span>
                            </div>
                        </Form.Group>
                    </Col>
                )}

                {artistRelation === 'representative' && (
                    <Col md="12" sm="12">
                        <Form.Group>
                            <Form.Control
                                type="text"
                                name="institutionName"
                                placeholder="Institution Name"
                                onChange={handleFieldChange}
                                ref={register({ required: true })}
                            />
                            <div className="form-label">
                                <i className="fal fa-user"></i>
                                <span className="required-field"></span>
                            </div>
                        </Form.Group>
                    </Col>
                )}
            </Form.Row>

            {!Auth.isLoggedIn() && (
                <>
                    {artistRelation !== 'artist' && (
                        <Form.Row>
                            <Col md="6" sm="12">
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        name="firstName"
                                        placeholder={content.firstName}
                                        onChange={handleFieldChange}
                                        ref={register({ required: true })}
                                    />
                                    <div className="form-label">
                                        <i className="fal fa-user"></i>
                                        <span className="required-field"></span>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md="6" sm="12">
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        name="lastName"
                                        placeholder={content.lastName}
                                        onChange={handleFieldChange}
                                        ref={register({ required: true })}
                                    />
                                    <div className="form-label">
                                        <i className="fal fa-user"></i>
                                        <span className="required-field"></span>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    )}

                    <Form.Row>
                        <Col md="6" sm="12">
                            <Form.Group>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder={content.email}
                                    onChange={handleFieldChange}
                                    ref={register({ required: true })}
                                />
                                <div className="form-label">
                                    <i className="fal fa-envelope"></i>
                                    <span className="required-field"></span>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col md="6" sm="12">
                            <Form.Group>
                                <Form.Control
                                    type="tel"
                                    name="phone"
                                    placeholder={content.phone}
                                    onChange={handleFieldChange}
                                    ref={register({ required: true })}
                                />
                                <div className="form-label">
                                    <i className="fal fa-phone"></i>
                                    <span className="required-field"></span>
                                </div>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                </>
            )}
        </div>
    )
}
