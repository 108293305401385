import React from 'react'
import presidencyImage from '../../assets/images/Presidenca.svg'
import municipalityImage from '../../assets/images/Pristina_Municipality.svg'

function Info({ info }) {
    const { sponsors } = info

    return (
        <div className="footer-info footer-row">
            <div className="info-box">
                <div className="info-box-section">
                    <div className="details">
                        <p>{sponsors.description}</p>
                    </div>

                    <figure className="info-box-section">
                        <img src={presidencyImage} alt={sponsors.presendency} />
                        <img src={municipalityImage} alt={sponsors.municipalityPristina} />
                    </figure>
                </div>
            </div>
        </div>
    )
}

export default Info
