import React from 'react'
import './BlogPost.scss'
import { Link } from 'react-router-dom'
import ImageTag from '../Global/ImageTag'
import BlogLayoutGrid from '../News/BlogLayoutGrid'
import { useContext } from 'react'
import { LanguageContext } from '../../contexts/LanguageContext'

export default function BlogPost({ post, gridOption }) {
    const replaceAll = (searchString, replaceString, str) => {
        return str.split(searchString).join(replaceString)
    }

    const { language } = useContext(LanguageContext)

    const transformPostContent = postContent => {
        try {
            let replacedWith = [/<[^>]*>/g, '&nbsp;', '&gt;', '&rsquo']
            replacedWith.forEach(replace => (postContent = replaceAll(replace, '', postContent)))
            return postContent
        } catch (err) {
            return ''
        }
    }

    let postContent =
        post &&
        post.multiLanguageFields &&
        post.multiLanguageFields[language] &&
        post.multiLanguageFields[language].content
            ? post.multiLanguageFields[language].content
            : ''
    postContent = transformPostContent(postContent)
    if (!gridOption) {
        return (
            <>
                <div className="col-12 blog-item">
                    <div className="row">
                        {post && post.featuredImage && (
                            <div className="col-12 col-lg-4 ">
                                <div className="img-blog-post">
                                    <ImageTag className="post-blog-img" src={post.featuredImage} />
                                </div>
                            </div>
                        )}
                        <div className="col-12 col-lg-8 wrapper-blogs post ">
                            <div className="categories-and-date">
                                <p className="data-categoris">
                                    {new Date(post.createdAt).toLocaleDateString(undefined, {
                                        year: 'numeric',
                                        month: 'short',
                                        day: 'numeric',
                                    })}
                                    <span className="dot-author">. {post.author}</span>
                                </p>
                                <Link className="text-title-post-blog" to={`/news/${post._id}`}>
                                    {post &&
                                        post.multiLanguageFields &&
                                        post.multiLanguageFields[language] &&
                                        post.multiLanguageFields[language].title &&
                                        post.multiLanguageFields[language].title}
                                </Link>
                                <p className="description-blog-post">
                                    {postContent.slice(0, 400)}
                                    {postContent.length > 400 && '...'}
                                </p>
                                <div className="button-read-more">
                                    <Link to={`/news/${post._id}`} className="read-more-button">
                                        READ MORE
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return <BlogLayoutGrid post={post} postContent={postContent} />
    }
}
