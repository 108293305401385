import React, { createContext, useState, useEffect } from 'react'
import Api from '../api'
export const AuthContext = createContext(null)

const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null)

    const getCurrentUser = async function () {
        const response = await Api.get('currentUser')
        if (!response || !response.data || response.data.confirmation !== "Success") {
            setUser(null)
            sessionStorage.removeItem('ACCESS_TOKEN')
            sessionStorage.removeItem('USER_DATA')
            return null;
        }
        setUser(response.data.results)
        return response.data.results
    }

    useEffect(() => {
        try {
            const userDataJson = sessionStorage.getItem('USER_DATA')
            const userData = JSON.parse(userDataJson)
            setUser(userData)
        } catch (err) {
            setUser(null)
        }
        getCurrentUser()
    }, [])

    return <AuthContext.Provider value={{ user, setUser, getCurrentUser }}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
