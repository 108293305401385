import React from 'react'
import { Link } from 'react-router-dom'
import SectionTitle from '../Global/SectionTitle'
import { Container, Row, Col } from 'react-bootstrap'
import galeria from '../../assets/images/logo_black.svg'
import './About.scss'

function About({ aboutContent, readMoreContent, isAboutPage }) {
    const { title, subtitle, description } = aboutContent

    return (
        <section className={`about-section text-center ${isAboutPage && 'background-clear has-seperator'}`}>
            <Container>
                <Row className="justify-content-md-center">
                    <Col md={8}>
                        <SectionTitle title={title} subtitle={subtitle} />
                        <img className="about-logo" src={galeria} alt={title} />
                        <p> {description}</p>
                        {!isAboutPage && (
                            <Link className="btn btn-clear btn-shadow" to="/about">
                                {readMoreContent}
                            </Link>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default About
