import React, { useState, useEffect } from "react"
import { Form, Col } from 'react-bootstrap'
import useForm from 'react-hook-form'
import { useAlert } from 'react-alert'
import Api from "../../api"

export default function Password({ getCurrentUser }) {
    const { register, handleSubmit, errors, setValue, setError } = useForm({ reValidateMode: 'onSubmit' })

    const [toCreateNewPassword, setToCreateNewPassword] = useState(false)

    const alert = useAlert()

    useEffect(() => {
        Object.keys(errors).forEach(err => {
            alert.error(`${err} is required`)
        })
        // eslint-disable-next-line
    }, [errors])

    const submit = async (data) => {

        const currentPassword = data.current_password.toString()
        const newPassword = data.new_password.toString()
        const newPasswordConfirm = data.new_password_confirm.toString()

        const regex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/i);

        if (!currentPassword.length) {
            alert.error(`Please enter current password.`)
            return
        }

        if (!regex.test(newPassword)) {
            alert.error(`Please create a stronger password.`)
            return
        }

        if (newPassword !== newPasswordConfirm) {
            alert.error("Password does not match.")
            return
        }

        const response = await Api.post('updateProfile', { currentPassword, newPassword })

        if (!response || !response.data || response.data.confirmation === "Fail" && typeof response.data.message === "string") {
            alert.error(response.data.message);
            return;
        }

        await getCurrentUser()

        alert.success("Successfully updated!")
    }

    const createNewPassword = function (val) {
        setToCreateNewPassword(val)
    }

    return (
        <>
            <Form onSubmit={handleSubmit(submit)}>
                <div className="form-section">
                    <Form.Group>
                        <h5 className='text-center'>{!toCreateNewPassword ? "Current password" : "Create new password" }</h5>
                    </Form.Group>

                    {
                        !toCreateNewPassword
                            ? (
                                <Col md="6" sm="12">
                                    <Form.Group>
                                        <Form.Control
                                            type="password"
                                            placeholder={"Password"}
                                            name="password"
                                            ref={register({ required: true })}
                                        />
                                        <div className="form-label">
                                            <i className="fal fa-user"></i>
                                            <span className="required-field"></span>
                                        </div>
                                    </Form.Group>
                                </Col>
                            )
                            : (
                                <>
                                    <Col md="6" sm="12">
                                        <Form.Group>
                                            <Form.Control
                                                type="password"
                                                placeholder={"New password"}
                                                name="current_password"
                                                ref={register}
                                            />
                                            <div className="form-label">
                                                <i className="fal fa-user"></i>
                                                <span className="required-field"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <Form.Group>
                                            <Form.Control
                                                type="password"
                                                placeholder={"New password"}
                                                name="new_password"
                                                ref={register}
                                            />
                                            <div className="form-label">
                                                <i className="fal fa-user"></i>
                                                <span className="required-field"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md="6" sm="12">
                                        <Form.Group>
                                            <Form.Control
                                                type="password"
                                                placeholder={"Confirm new password"}
                                                name="new_password_confirm"
                                                ref={register}
                                            />
                                            <div className="form-label">
                                                <i className="fal fa-user"></i>
                                                <span className="required-field"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </>
                            )
                    }

                    {
                        toCreateNewPassword 
                            ? (
                                <Col sm="12">
                                    <button type="submit" className="save-changes-btn">Save changes</button>
                                    <button onClick={() => createNewPassword(false)} className="save-changes-btn">Cancel</button>
                                </Col>
                            )
                            : (
                                <Col sm="12">
                                    <button onClick={() => createNewPassword(true)} type="button" className="save-changes-btn">Create new password</button>
                                </Col>
                            )
                    }

                </div>
            </Form>
        </>
    )
}