import React from 'react'
import { Form } from 'react-bootstrap'
import ImageUploadField from '../../Global/ImageUploadField'
//import MultilangUploadField from '../../Global/MultilangUploadField'

export default function Profile({ content, register, centerTitle, editDetails }) {
    return (
        <div className="form-section">
            <Form.Group>
                <h4 className={`${!editDetails ? 'text-center' : ''}`}>{content.title}</h4>
            </Form.Group>
            <ImageUploadField
                type="file"
                accept="image/png, image/jpeg"
                label={content.profileImage}
                format="1000x1000 px (max 2mb)"
                button={content.upload}
                icon="fal fa-portrait"
                innerRef={register}
                name="profileImage"
            />
            <ImageUploadField
                type="file"
                accept="image/png, image/jpeg"
                label={content.coverImage}
                format="1300x250 px (max 2mb)"
                button={content.upload}
                icon="fal fa-images"
                innerRef={register}
                name="coverImage"
            />
        </div>
    )
}
