import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col } from 'react-bootstrap'
import BlackLogo from '../../assets/images/databaza_icon.png'

export default function RegisterPage() {
    const { t } = useTranslation()

    const joinpage = t('joinpage', { returnObjects: true })

    const registrationPage = joinpage.userform.registration_complete

    return (
        <>
            <section className="registration-complete text-center">
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            <div style={{ marginBottom: '20px' }}>
                                <img className="databaza-logo" src={BlackLogo} alt="Databaza.art" />
                            </div>
                            <h2>{registrationPage.title}</h2>
                            <p>{registrationPage.subtitle}</p>
                            <p>{registrationPage.support}</p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
