import React, { useState, useEffect } from 'react'
import SectionTitle from '../Global/SectionTitle'
import PricingTables from './PricingTables'
import { Container } from 'react-bootstrap'
import { useAlert } from 'react-alert'
import Api from './../../api'
import './Pricing.scss'
import Helpers from '../../helpers'

function Pricing({ pricingContent, description, globalContent }) {
    const { title, subtitle } = pricingContent
    const [pricingList, setPricingList] = useState([])
    const alert = useAlert()

    useEffect(() => {
        async function getData() {
            const response = await Api.get('subscriptions')
            const res = Helpers.validateResponse(response, alert)
            if (!res) return
            setPricingList(res)
        }

        getData()
        // eslint-disable-next-line
    }, [])

    return (
        <section className="pricing-section text-center">
            <Container>
                <SectionTitle title={title} subtitle={subtitle} />
                <PricingTables pricingArr={pricingList} description={description} globalContent={globalContent} />
            </Container>
        </section>
    )
}

export default Pricing
