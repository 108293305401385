import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Container } from 'react-bootstrap'
import PageTitle from '../../components/Global/PageTitle'
import Logo from '../../assets/images/logo_black.svg'

export default function FourOFour() {
    const { t } = useTranslation()
    const globalContent = t('global', { returnObjects: true })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section className="text-center">
                <Container>
                    <div className="text-center verification-flex-center">
                        <div>
                            <img
                                src={Logo}
                                style={{ width: 80, marginBottom: 30 }}
                                alt="databaza e artisteve të ekosoves"
                            />
                            <PageTitle title="404" subtitle={globalContent.not_found} />
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    )
}
