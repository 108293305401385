import React, { useState, useEffect } from 'react'
import Select from 'react-select'

export default function SearchHeader({
    newTitle,
    mediaPageContent,
    terms,
    count,
    artistCount,
    postFilter,
    filterData,
    handleBlogLayoutChange,
    active,
    blogCategories,
    handleCategoriesSelectedChange,
    fromBlog,
    selectedTerm,
    similarWord,
    getData,
}) {
    const { title, result_stats, sort } = mediaPageContent
    const [resultCount, setResultCount] = useState({ current: 0, total: '0' })
    // const [term] = terms
    const [searchOptions] = useState([{ value: 'date', label: 'Date Added' }])
    const [options, setOptions] = useState([{ value: 'date', label: 'Date Added' }])
    const [activeBlogOptions, setActiveBlogOption] = useState(active)

    useEffect(() => {
        if (active !== activeBlogOptions) setActiveBlogOption(active)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active])

    useEffect(() => {
        if (postFilter) {
            setOptions([
                { value: '*', label: 'All' },
                { value: 'post', label: 'Post' },
                { value: 'artist', label: 'Artist' },
                { value: 'event', label: 'Event' },
            ])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postFilter])

    useEffect(() => {
        setResultCount({
            current: count,
            total: artistCount,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [artistCount, count])

    const handleFilter = e => {
        filterData(e.value)
    }

    return (
        <div className="container">
            {similarWord && similarWord.similarWord && (
                <p>
                    Did you mean{' '}
                    <a
                        onClick={() => getData(similarWord.similarWord)}
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        {similarWord.similarWord}{' '}
                    </a>
                    ?
                </p>
            )}
            <div className="search-results-header">
                <div className="results-info">
                    <p>
                        {result_stats[0]} <b>{resultCount.current}</b> {result_stats[1]} <b>{resultCount.total}</b>{' '}
                        {fromBlog ? 'posts' : result_stats[2]}
                    </p>
                </div>

                <div className="results-title">
                    {newTitle ? (
                        <h3 className="page-title">
                            <b>{newTitle}</b>
                        </h3>
                    ) : (
                        <h3 className="page-title">{title}</h3>
                    )}
                    {selectedTerm && <h5 className="search-term">"{selectedTerm}"</h5>}
                </div>

                {fromBlog ? (
                    <ul className="blog-icons">
                        <li
                            value="Grid"
                            onClick={handleBlogLayoutChange}
                            data-val="Grid"
                            className={`blog-icon-parent ${activeBlogOptions === 'Grid' ? 'bg_pink' : 'bg_white'}`}
                        >
                            <i onClick={handleBlogLayoutChange} data-val="Grid" className=" fas fa-th"></i>
                        </li>
                        <li
                            onClick={handleBlogLayoutChange}
                            value="List"
                            data-val="List"
                            className={`blog-icon-parent ${activeBlogOptions === 'List' ? 'bg_pink' : 'bg_white'}`}
                        >
                            <i onClick={handleBlogLayoutChange} data-val="List" className=" fas fa-th-list"></i>
                        </li>
                    </ul>
                ) : (
                    <div className="search-sort">
                        <div style={{ display: 'none' }}>
                            <span>{sort}</span>
                            <Select options={searchOptions} />
                        </div>
                    </div>
                )}
            </div>

            {fromBlog && (
                <div className="filtered-parent">
                    <div className="show-blog-options">
                        <div className="select-blog-options-parent">
                            <Select
                                options={blogCategories}
                                placeholder="Category options"
                                onChange={handleCategoriesSelectedChange}
                            />
                        </div>
                    </div>

                    <div className="show-blog-options">
                        <div className="select-blog-options-parent">
                            <Select
                                options={options}
                                styles={{ borderColor: 'red' }}
                                placeholder={sort}
                                onChange={handleFilter}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
