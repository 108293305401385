import React from 'react'
import Service from '../Service'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import cultural_capital_icon from '../../assets/images/vlerat-01.svg'
import transparency_integrity from '../../assets/images/vlerat-02.svg'
import preservation_conservation from '../../assets/images/vlerat-03.svg'
import SectionTitle from '../Global/SectionTitle'

function HowItWorksAlternative() {
    const { t } = useTranslation()
    const valuesText = t('aboutpage.values', { returnObjects: true })

    // IMAGE SIZE 80%
    const serviceProps = [
        {
            icon: cultural_capital_icon,
        },
        {
            icon: transparency_integrity,
        },
        {
            icon: preservation_conservation,
        },
    ]

    return (
        <section className="how-it-works">
            <Container>
                <SectionTitle title={valuesText.title} subtitle={valuesText.subtitle} />
                <div className="row">
                    {valuesText.whoweare.map((s, i) => (
                        <Service
                            key={i}
                            title={s.title}
                            description={s.description}
                            icon={serviceProps[i].icon}
                            arrow={serviceProps[i].arrow}
                            alternative={true}
                        />
                    ))}
                </div>
            </Container>
        </section>
    )
}
export default HowItWorksAlternative
