import React from 'react'
import './PageLoading.scss'

function PageLoading() {
    return (
        <div className="loading">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.54 78.14">
                <defs>
                    <linearGradient id="loading" x1="0%" y1="100%" x2="0%" y2="0%">
                        <stop offset="0" stopColor="#151515">
                            <animate dur="0.5s" attributeName="offset" fill="freeze" from="0" to="1" />
                        </stop>
                        <stop offset="0" stopColor="#fff">
                            <animate dur="0.5s" attributeName="offset" fill="freeze" from="0" to="1" />
                        </stop>
                    </linearGradient>
                </defs>
                <path
                    className="loading-icon-path"
                    fill="url(#loading)"
                    strokeWidth="1"
                    opacity="1"
                    d="M0,39.27a9,9,0,0,0,.08,1.17V61.9H63.26L78.54,78.14V34.94h0c0-4.69-4.61-14.16-5-14.89A39.27,39.27,0,0,0,7.1,16.75q-1,1.41-1.86,2.91C4.66,20.66.08,30.45.08,34.94h0v1.85C0,37.61,0,38.44,0,39.27Zm26,.84a7.91,7.91,0,0,1,6.5-7.78,36.13,36.13,0,0,1,13.33,0,7.91,7.91,0,0,1,6.5,7.78v6.81H26Z"
                />
            </svg>
            <p>PAGE IS LOADING</p>
            <p>39%</p>
        </div>
    )
}
export default PageLoading
