import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import './Join.scss'
import Auth from '../../auth'

export default function JoinHeaders({ user, artist, active }) {
    return (
        <header className="paper-view-header text-center">
            <Row>
                {!Auth.isLoggedIn() && (
                    <Link className={`col-md-6 col-sm-12 ${active === 'user' ? 'active-tab' : null}`} to="/join-user">
                        {user}
                    </Link>
                )}

                <Link
                    className={`${!Auth.isLoggedIn() ? 'col-md-6' : 'col-md-12'} col-sm-12 
                        ${active === 'artist' ? 'active-tab' : null}`}
                    to="/add-artist"
                >
                    {artist}
                </Link>
            </Row>
        </header>
    )
}
