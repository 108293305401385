import React, { useContext } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { LanguageContext } from '../../contexts/LanguageContext'
import { Link } from 'react-router-dom'

function LatestNews({ title, posts }) {
    const { language } = useContext (LanguageContext)
    const settings = {
        arrows: true,
    }

    const getField = (obj, field) => {
        if (obj && obj['multiLanguageFields'] && obj['multiLanguageFields'][language] && obj['multiLanguageFields'][language][field]) 
            return obj['multiLanguageFields'][language][field]
    }

    return (
        <div className="widget">
            <header>
                <h4>{title}</h4>
            </header>

            <Slider {...settings}>
                {posts &&
                    posts.length !== 0 &&
                    posts.map(news => (
                        <article className="latest-news" key={news._id}>
                            {news && news.featuredImage && (
                                <figure>
                                    <img
                                        src={`${process.env.REACT_APP_IP}:${process.env.REACT_APP_API_PORT}/${news.featuredImage}`}
                                        alt={news.title}
                                    />
                                </figure>
                            )}
                            <footer>
                                <div className="news-meta">
                                    <span className="category">
                                        {news.categories && news.categories.map(cat => <b key={cat._id}>{cat.en}</b>)}
                                    </span>
                                    <span className="date">
                                        {news.createdAt &&
                                            new Date(news.createdAt).toLocaleDateString('en-US', {
                                                dateStyle: 'medium',
                                            })}
                                    </span>
                                </div>
                                <Link className="artist-name" to={`/news/${news._id}`}>{getField(news, 'title') && getField(news, 'title')}</Link>
                            </footer>
                        </article>
                    ))}
            </Slider>
        </div>
    )
}
export default LatestNews
