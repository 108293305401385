import React, { useState, useEffect } from 'react'
import { Col, Form } from 'react-bootstrap'
import ImageUploadField from '../../Global/ImageUploadField'
import TagsInput from 'react-tagsinput'
import GooglePlacesInput from '../../GooglePlacesInput/index'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { geocodeByAddress } from 'react-places-autocomplete'

export default function Activities({ register, setValue }) {
    const initialValue = {
        artType: '',
        name: '',
        image: '',
        description: '',
        url: '',
        details: [
            {
                date: '',
                endDate: '',
                location: {
                    /* Database fields */
                    geo: [],
                    place: '',
                    /* None database fields */
                    selected: '',
                    dateSelected: null,
                    endDateSelected: null,
                },
            },
        ],
    }

    const [activitiesField, setActivitiesField] = useState([initialValue])

    const addFieldToGroup = e => {
        e.preventDefault()
        let temp = [...activitiesField]
        temp = temp.concat(initialValue)
        setActivitiesField(temp)
        setValue('activities', temp)
    }

    const updateField = (e, index) => {
        let temp = [...activitiesField]
        temp[index][e.target.name] = e.target.value
        setActivitiesField(temp)
        setValue('activities', temp)
    }

    const handleTags = (tag, value, index) => {
        let temp = [...activitiesField]
        temp[index][tag] = value
        setActivitiesField(temp)
        setValue('activities', temp)
    }

    const handleRatioButton = (e, index, type) => {
        let temp = [...activitiesField]
        temp[index]['artType'] = type
        setActivitiesField(temp)
        setValue('activities', temp)
    }

    const removeFieldGroup = (e, index) => {
        e.preventDefault()
        // if (index === 0) return;
        let temp = [...activitiesField]
        temp.splice(index, 1)
        setActivitiesField(temp)
        setValue('activities', temp)
    }

    const handleFile = (e, index) => {
        let temp = [...activitiesField]
        let img = e.target.files
        temp[index]['image'] = img
        setActivitiesField(temp)
        setValue('activities', temp)
    }

    useEffect(() => {
        register({ name: 'activities' })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    const addDetails = (e, activitiesIndex) => {
        e.preventDefault()
        let temp = [...activitiesField]
        let obj = { date: '', location: { geo: [], place: '', selected: '' } }
        temp[activitiesIndex]['details'].push(obj)
        setActivitiesField(temp)
        setValue('activities', temp)
    }

    const removeDetails = (e, detailIndex, activityIndex) => {
        if (detailIndex === 0) return
        e.preventDefault()
        let temp = [...activitiesField]
        temp[activityIndex]['details'].splice(detailIndex)
        setActivitiesField(temp)
        setValue('activities', temp)
    }

    const handlePlaceSelected = (val, detailIndex, fieldIndex) => {
        let temp = [...activitiesField]
        temp[fieldIndex]['details'][detailIndex]['location']['place'] = val
        temp[fieldIndex]['details'][detailIndex]['location']['selected'] = val
        setActivitiesField(temp)
        setValue('activities', temp)
    }

    const handleDateSelectedOnRepeater = (val, detailIndex, fieldIndex) => {
        let temp = [...activitiesField]
        temp[fieldIndex]['details'][detailIndex]['date'] = val
        temp[fieldIndex]['details'][detailIndex]['dateSelected'] = val
        setActivitiesField(temp)
        setValue('activities', temp)
    }

    const handleEndDateSelectedOnRepeater = (val, detailIndex, fieldIndex) => {
        let temp = [...activitiesField]
        temp[fieldIndex]['details'][detailIndex]['endDate'] = val
        temp[fieldIndex]['details'][detailIndex]['endDate'] = val
        setActivitiesField(temp)
        setValue('activities', temp)
    }

    const getGeoCodeByLocationName = async (location, activitiesIndex, detailIndex) => {
        try {
            const response = await geocodeByAddress(location)
            if (response && response.length > 0) {
                response.forEach((obj, index) => {
                    if (obj && obj.geometry && obj.geometry.location) {
                        const lat = obj.geometry.location.lat()
                        let tempArr = [...activitiesField]
                        tempArr[activitiesIndex]['details'][detailIndex]['location']['geo'].push(lat)
                        setActivitiesField(tempArr)
                        setValue('activities', tempArr)
                    }
                })
            }
        } catch (err) {
            console.error('err:', err)
        }
    }

    const generateActivitiesFields = () => {
        return (
            <>
                <Form.Group>
                    <h4 className="text-center">Activities</h4>
                </Form.Group>
                {activitiesField.map((fields, i) => {
                    return (
                        <div className="field-group-wrapper" key={i}>
                            <div className="art-works-checkboxes">
                                <div>
                                    <Form.Check
                                        custom
                                        type="radio"
                                        label="Exhibition"
                                        onChange={e => handleRatioButton(e, i, 'EXHIBITION')}
                                        checked={activitiesField[i]['artType'] === 'EXHIBITION'}
                                    />
                                </div>
                                <div>
                                    <Form.Check
                                        custom
                                        type="radio"
                                        label="Performance"
                                        onChange={e => handleRatioButton(e, i, 'PERFORMANCE')}
                                        checked={activitiesField[i]['artType'] === 'PERFORMANCE'}
                                    />
                                </div>
                                <div id="test">
                                    <Form.Check
                                        custom
                                        type="radio"
                                        label="Screening"
                                        onChange={e => handleRatioButton(e, i, 'SCREENING')}
                                        checked={activitiesField[i]['artType'] === 'SCREENING'}
                                    />
                                </div>
                            </div>

                            <Form.Row>
                                <span className="remove-field-group" onClick={e => removeFieldGroup(e, i)}>
                                    <i className="fal fa-times"></i>
                                </span>

                                <Col>
                                    <Form.Group>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={fields['name']}
                                            onChange={e => updateField(e, i)}
                                            placeholder={'Name'}
                                        />
                                        <div className="form-label">
                                            <i className="fal fa-map-marker-check"></i>
                                            <span className="required-field"></span>
                                        </div>
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group>
                                        <TagsInput
                                            value={fields['url'] === '' || !fields['url'] ? [] : fields['url']}
                                            onChange={e => handleTags('url', e, i)}
                                            name="url"
                                            addKeys={[9, 13, 188]}
                                            inputProps={{
                                                placeholder: 'Urls',
                                            }}
                                        />
                                        <div className="form-label">
                                            <i className="fal fa-award"></i>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <ImageUploadField
                                type="file"
                                accept="image/png, image/jpeg"
                                label={'Image'}
                                format="1000x1000 px (max 2mb)"
                                button={'Upload'}
                                icon="fal fa-user-circle"
                                // innerRef={register({ required: true })}
                                name="activityImage"
                                required={true}
                                callbackFile={event => handleFile(event, i)}
                            />
                            <Form.Row>
                                <Col>
                                    <Form.Group>
                                        <div className="custom-textarea-wrapper">
                                            <div className="form-label">
                                                <i className="fal fa-book-user"></i>
                                                <span className="required-field"></span>
                                                <span>Enter Description</span>
                                            </div>
                                            <div className="custom-textarea">
                                                <Form.Control
                                                    name="description"
                                                    onChange={e => updateField(e, i)}
                                                    as="textarea"
                                                    rows="3"
                                                />
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Form.Row>

                            <Form.Group>
                                <h4 className="text-center">Details</h4>
                            </Form.Group>

                            {fields['details'].map((detail, detailIndex) => {
                                return (
                                    <React.Fragment key={detailIndex}>
                                        <Form.Row>
                                            <Col>
                                                <Form.Group>
                                                    <DatePicker
                                                        dateFormat={'dd-MM-yyyy'}
                                                        showYearDropdown={true}
                                                        placeholderText={'Date'}
                                                        onChange={date =>
                                                            handleDateSelectedOnRepeater(date, detailIndex, i)
                                                        }
                                                        selected={detail['dateSelected']}
                                                        name="date"
                                                    />
                                                    <div className="form-label">
                                                        <i className="fal fa-history"></i>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <GooglePlacesInput
                                                        value={detail['location']['place']}
                                                        onChange={val => handlePlaceSelected(val, detailIndex, i)}
                                                        onSelect={val => getGeoCodeByLocationName(val, i, detailIndex)}
                                                    />
                                                    <div className="form-label">
                                                        <i className="fal fa-award"></i>
                                                    </div>
                                                </Form.Group>
                                                <span
                                                    className="remove-field-group"
                                                    onClick={e => removeDetails(e, detailIndex, i)}
                                                >
                                                    <i className="fal fa-times"></i>
                                                </span>
                                            </Col>
                                        </Form.Row>

                                        <Form.Row>
                                            <Col>
                                                <Form.Group>
                                                    <DatePicker
                                                        dateFormat={'dd-MM-yyyy'}
                                                        showYearDropdown={true}
                                                        placeholderText={'End Date'}
                                                        onChange={date =>
                                                            handleEndDateSelectedOnRepeater(date, detailIndex, i)
                                                        }
                                                        selected={detail['endDate']}
                                                        name="date"
                                                    />
                                                    <div className="form-label">
                                                        <i className="fal fa-history"></i>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </React.Fragment>
                                )
                            })}
                            <div id="test" style={{ display: 'flex', justifyContent: 'center' }}>
                                <button className="btn btn-clear btn-add" onClick={e => addDetails(e, i)}>
                                    <i class="fal fa-plus-circle"></i>
                                    {fields['details'].length === 0 ? 'ADD DETAILS' : 'ADD MORE'}
                                </button>
                            </div>
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <div className="education-fields repeater-wrapper">
            {generateActivitiesFields()}
            <Form.Row>
                <Col className="text-center">
                    <button className="btn btn-clear btn-add" onClick={e => addFieldToGroup(e)}>
                        <i class="fal fa-plus-circle"></i>
                        ADD ACTIVITY
                    </button>
                </Col>
            </Form.Row>
        </div>
    )
}
