
import React from "react"
import SavedArtists from "../../components/SavedArtists"

export default function SavedArtistsPage() {
    return (
        <>
        <div style={{ marginBottom: '80px' }}>
            <SavedArtists />
        </div>
        </>
    )
}