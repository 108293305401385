import React, { useContext } from 'react'
import { LanguageContext } from '../../contexts/LanguageContext'
import './ArtistOtherInfo.scss'

export default function ArtistOtherInfoList({ content }) {
    const currentLanguage = useContext(LanguageContext)
    const { language } = currentLanguage

    const contentList = Object.entries(content).map(([key, value]) => {
        if (key === language) {
            if (value.length === 0) {
                return 'No content'
            }
            return value.toString().replace(/,/g, '\n\n')
        }
    })
    return (
        <div class="artist-details-list">
            <p>{contentList}</p>
        </div>
    )
}
