
class Helpers {
    nameToSlug(name) {
        return name
            .split(' ')
            .join('-')
            .toLowerCase()
    }

    getSelectStyles() {
        return {
            container: styles => ({ ...styles, textAlign: 'left' }),
            control: styles => ({
                ...styles,
                height: 70,
                textAlign: 'left',
                '& > div:first-of-type': { minHeight: 40 },
            }),
            placeholder: styles => ({ ...styles, left: 55 }),
            singleValue: styles => ({ ...styles, left: 55 }),
            input: styles => ({
                ...styles,
                boxShadow: 'none',
                position: 'absolute',
                left: 55,
                '& input': { height: 40, boxShadow: 'none' },
            }),
            menu: styles => ({ ...styles, marginTop: 0, borderRadius: 0, padding: '35px 40px', zIndex: 3 }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: 'transparent',
                fontWeight: state.isSelected ? '600' : '400',
                color: 'black',
                '&:not(:last-child)': { borderBottom: 'solid 1px #efefef' },
                '&:focus, &:active': {
                    backgroundColor: 'transparent',
                },
            }),
        }
    }

    filterForm(data, isFormData = false) {
        let formData = new FormData()
        let formArr = []

        Object.keys(data).forEach(el => {
            if (data[el] !== '' && data[el] !== null && data[el] !== undefined) {
                if (typeof data[el] === 'object' && !(data[el] instanceof Date)) {
                    if (data[el].length === 0) {
                        return
                    }

                    if (el === 'profileImage' || el === 'coverImage' || el === 'biography_en' || el === 'biography_sq') {
                        if (isFormData) formData.append(el, data[el][0])
                        else formArr.push({ [el]: data[el][0] })
                        return
                    } 

                    if (isFormData) formData.append(el, JSON.stringify(data[el]))
                    else formArr.push({ [el]: JSON.stringify(data[el]) })
                    return
                }

                if (isFormData) formData.append(el, data[el])
                else formArr.push({ [el]: data[el] })
            }
        })
        return isFormData ? formData : formArr
    }

    toFormData(obj, form, namespace) {
        let fd = form || new FormData()
        let formKey

        for (let property in obj) {
            if (obj.hasOwnProperty(property) && obj[property]) {
                if (namespace) {
                    formKey = namespace + '[' + property + ']'
                } else {
                    formKey = property
                }

                // if the property is an object, but not a File, use recursivity.
                if (obj[property] instanceof Date) {
                    fd.append(formKey, obj[property].toISOString())
                } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                    this.toFormData(obj[property], fd, formKey)
                } else {
                    // if it's a string or a File object
                    fd.append(formKey, obj[property])
                }
            }
        }

        return fd
    }

    handleAlerts(alertFunk, message) {
        if (message instanceof Object) {
            if (message.errors && Array.isArray(message.errors) && message.errors.length !== 0) {
                message.errors.forEach(err => {
                    message += err.msg.toString() + '\n'
                })
            } else {
                if (Array.isArray(message)) {
                    message = message.toString()
                } else {
                    message = message.message || 'Something went wrong!'
                }
            }
        }
        if (message) {
            alertFunk(message.toString())
        }
    }

    validateResponse(res, alert, showSuccess = false) {
        if (res && res !== undefined && res.data) {
            if (res.data.confirmation === 'Success') {
                if (showSuccess) this.handleAlerts(alert.success, showSuccess)
                return res.data.results || res.data.confirmation
            } else {
                if (!alert) return
                this.handleAlerts(alert.error, res.data.message)
                return res.data.results
            }
        }
    }

    decamelize(str, separator) {
        separator = typeof separator === 'undefined' ? '_' : separator

        return str
            .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
            .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    }

    validateArtistRelationships(fields, requiredFields, alert, field){
        let errors = [];
        if (fields && Array.isArray(fields)) {
            fields.forEach((obj, index) => {
                Object.keys(obj).forEach(key => {
                    if (requiredFields.includes(key)) {
                        if (obj[key].length === 0) errors.push(`${field} ${key} is required!`);
                    }
                })
            })
        }

        if (errors.length > 0) {
            errors.forEach(err => alert.error(`${err}`))
            return false;
        } 
        return true;
    }

    validateArtistArtType(artistFormData, alert) {
        if (!artistFormData.type || typeof artistFormData.type !== "object") {
            alert.error("Art type is required!");
            return false;
        }
        let artType = artistFormData.type 
        if (artType && artType.length === 0) {
            alert.error("Art type is required!");
            return false
        }
        if (!artistFormData.taxonomies || artistFormData.taxonomies.length === 0) {
            alert.error("Please select at least one field below Art Type!");
            return false;
        }
        return true;
    }

    filterArtistRelationshipsImages(artistFormData, formData, field, startNameImage) {
        artistFormData[field].forEach((obj, index) => {
            if (obj.image) {
                let keyName = `${startNameImage}${obj.image[0].name}_image_${index}`;
                formData.set(keyName, obj.image[0])
            }
        })
        return formData;
    }
    unique(value, index, self) {
        return self.indexOf(value) === index
    }

    hasInfo(info) {
        let infoArr
        if (info instanceof Object) {
            infoArr = Object.values(info)
        }
        return !(
            info === null ||
            info === '' ||
            (Array.isArray(info) && info.length === 0) ||
            (infoArr !== undefined && infoArr.filter(this.unique)[0] === '')
        )
    }
}

export default new Helpers()
