import React, { useState, useEffect, useContext } from 'react'
import { Form, Col } from 'react-bootstrap'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css'
import Helpers from '../../../helpers'
import EducationFields from './EducationFields'
import Api from '../../../api'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import { useAlert } from 'react-alert'
import { LanguageContext } from '../../../contexts/LanguageContext'

export default function Professional({ content, register, globalContent, setValue }) {
    const selectStyles = Helpers.getSelectStyles()
    const [artstyleOptions, setArtstyleOptions] = useState([])
    const [workTags, setWorkTags] = useState([])
    const [publicationTags, setPublicationTags] = useState([])
    const alert = useAlert()
    const currentLanguage = useContext(LanguageContext)
    const { language } = currentLanguage

    const getArtstyles = async () => {
        const response = await Api.getWithId('taxonomies', 'artstyles')
        const res = Helpers.validateResponse(response, alert)
        const tempArtstyle = []
        if (res && res.legnth !== -1) {
            res.forEach(style => {
                tempArtstyle.push({ value: style._id, label: style[language] })
            })
            setArtstyleOptions(tempArtstyle)
        }
    }

    const handleChangeSelect = (selected, meta) => {
        setValue(meta.name, selected.value)
    }

    const handleSetWorkTags = newValue => {
        setWorkTags(newValue)
        setValue('work', newValue)
    }
    const handleSetPublicationTags = newValue => {
        setPublicationTags(newValue)
        setValue('publications', newValue)
    }

    useEffect(() => {
        getArtstyles()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        register({ name: 'work' }, { required: true })
        register({ name: 'artstyle' }, { required: true })
        register({ name: 'publications' })
        register({ name: 'education' })
    })

    const updateEducationFields = data => {
        setValue('education', data)
    }

    return (
        <div className="form-section">
            <Form.Group>
                <h4 className="text-center">{content.title}</h4>
            </Form.Group>

            <Form.Row>
                <Col>
                    <Form.Group>
                        <TagsInput
                            value={workTags}
                            onChange={handleSetWorkTags}
                            name="work"
                            addKeys={[9, 13, 188]}
                            inputProps={{
                                placeholder: content.work,
                            }}
                        />
                        <Form.Text className="text-muted">{globalContent.tag_input}</Form.Text>
                        <div className="form-label">
                            <i className="fal fa-briefcase"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
            </Form.Row>

            {/* <Form.Row>
                <Col>
                    <Form.Group>
                        <TagsInput value={tags} onChange={setTags} addKeys={[9, 13, 188]} />
                        <div className="form-label">
                            <i className="fal fa-user"></i>
                            <span className="required-field">test</span>
                        </div>
                    </Form.Group>
                </Col>
            </Form.Row> */}

            <Form.Row>
                <Col>
                    <Form.Group>
                        <Select
                            options={artstyleOptions}
                            styles={selectStyles}
                            name="artstyle"
                            ref={register}
                            onChange={handleChangeSelect}
                            placeholder={content.artstyle}
                        />
                        <div className="form-label">
                            <i className="fal fa-paint-brush"></i>
                            <span className="required-field"></span>
                        </div>
                    </Form.Group>
                </Col>
            </Form.Row>

            <Form.Row>
                <Col>
                    <Form.Group>
                        <TagsInput
                            value={publicationTags}
                            onChange={handleSetPublicationTags}
                            name="publications"
                            addKeys={[9, 13, 188]}
                            inputProps={{
                                placeholder: content.publications,
                            }}
                        />
                        <Form.Text className="text-muted">{globalContent.tag_input}</Form.Text>
                        <div className="form-label">
                            <i className="fal fa-file-alt"></i>
                        </div>
                    </Form.Group>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Label>Education</Form.Label>
                </Col>
            </Form.Row>
            <div className="education-fields repeater-wrapper">
                <EducationFields education={content.education} updateEducationFields={updateEducationFields} />
            </div>
        </div>
    )
}
