import React, { useState, useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'
import Select from 'react-select'
import Helpers from '../../helpers'

function MultilangUploadField({
    accept,
    label,
    format,
    button,
    icon,
    multiple,
    shortDesc,
    innerDescName,
    facts = false,
    innerFactsName = '',
    factsLabel = '',
    register,
    setValue,
}) {
    const languageOptions = [
        { value: 'en', label: 'English' },
        { value: 'sq', label: 'Shqip' },
    ]

    const [biographyData, setBiographyData] = useState({ en: '', sq: '', sr: '' })
    const [shortBioData, setShortBioData] = useState({ en: '', sq: '', sr: '' })
    const [factsData, setFactsData] = useState({ en: '', sq: '', sr: '' })
    const [selectedLang, setSelectedLang] = useState(languageOptions[0].value)

    const handleLanguageChange = selected => {
        setSelectedLang(selected.value)
    }

    const handleFileChange = e => {
        let temp = { ...biographyData }
        temp[selectedLang] = e.target.files[0]
        setBiographyData(temp)
        setValue('biography', temp)
    }

    const handleInputChange = e => {
        if (e.target.name === 'shortBio') {
            let temp = { ...shortBioData }
            temp[selectedLang] = e.target.value
            setShortBioData(temp)
            setValue('shortBio', temp)
        } else {
            let temp = { ...factsData }
            temp[selectedLang] = e.target.value
            setValue('facts', temp)
            setFactsData(temp)
        }
    }

    useEffect(() => {
        //register({ name: 'biography' })
        register({ name: 'shortBio' })
        register({ name: 'facts' })

        //setValue('biography', biographyData)
        setValue('shortBio', shortBioData)
        setValue('facts', factsData)
        // eslint-disable-next-line
    }, [])

    const selectStyles = Helpers.getSelectStyles()
    return (
        <div className="multilang-form">
            <div className="form-switcher">
                <Form.Row>
                    <Col md={{ span: 3, offset: 9 }}>
                        <div className="custom-language-selector">
                            <Select
                                defaultValue={languageOptions[0]}
                                isClearable={false}
                                options={languageOptions}
                                styles={selectStyles}
                                onChange={handleLanguageChange}
                            />
                        </div>
                    </Col>
                </Form.Row>
            </div>
            {languageOptions.map(opt => (
                <Form.Group
                    style={{ display: opt.value === selectedLang ? 'block' : 'none' }}
                    key={`field-group-${opt.value}`}
                    id={`field-group-${opt.value}`}
                >
                    <Form.Row>
                        <Col md="9">
                            <Form.Group>
                                <div className="custom-file-upload">
                                    <input
                                        multiple={multiple}
                                        type="file"
                                        accept={accept}
                                        name={`biography_${opt.value}`}
                                        ref={register}
                                        onChange={handleFileChange}
                                    />
                                    <div className="file-meta">
                                        <label>
                                            <i className={icon}></i>
                                            <span className="form-label">
                                                <span className="required-field"></span>
                                            </span>
                                            <span>{label}</span>
                                            <b>{format}</b>
                                        </label>
                                        <div className="file-preview">
                                            {
                                                (biographyData && biographyData[opt.value] && biographyData[opt.value].name) &&
                                                <small>{biographyData[opt.value].name}</small>
                                            }
                                            {biographyData &&
                                                biographyData.biography &&
                                                biographyData.biography[opt.value] &&
                                                biographyData.biography[opt.value].files && (
                                                    <>
                                                        <span className="file-type">.pdf</span>
                                                        <span className="file-name">
                                                            {biographyData.biography[opt.value].files[0].name}
                                                        </span> 
                                                    </>
                                                )}
                                            <span className="input-button">{button}</span>
                                        </div>
                                    </div>
                                </div>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Row>
                        <Col>
                            <Form.Group>
                                <div className="custom-textarea-wrapper">
                                    <div className="form-label">
                                        <i className="fal fa-book-user"></i>
                                        <span>{shortDesc}</span>
                                    </div>
                                    <div className="custom-textarea">
                                        <Form.Control
                                            name={innerDescName}
                                            as="textarea"
                                            rows="3"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    {facts && (
                        <Form.Row>
                            <Col>
                                <Form.Group>
                                    <div className="custom-textarea-wrapper">
                                        <div className="form-label">
                                            <i className="fal fa-surprise"></i>
                                            <span>{factsLabel}</span>
                                        </div>
                                        <div className="custom-textarea">
                                            <Form.Control
                                                name={innerFactsName}
                                                as="textarea"
                                                rows="3"
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    )}
                </Form.Group>
            ))}
        </div>
    )
}
export default MultilangUploadField
