import React, { useContext } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Auth from '../../auth'
import ImageTag from '../Global/ImageTag'
import { LanguageContext } from '../../contexts/LanguageContext'
import defaultUserImage from '../../assets/images/default_user_image.svg'

function Menu({ menu, loginState, expandState }) {
    const { language, setLanguage } = useContext(LanguageContext)
    const { join, account, login, addArtist } = menu
    const changeLanguage = e => {
        setLanguage(e.target.getAttribute('data-language'))
    }
    const toggleLogin = e => {
        loginState.funk(!loginState.value)
    }

    const userDataJson = sessionStorage.getItem('USER_DATA')
    const userData = JSON.parse(userDataJson)

    // const [expand, setExpand] = expandState
    // const toggleExpand = () => {
    //     setExpand(!expand)
    // }
    // const closeNav = () => {
    //     setExpand(false)
    // }

    return (
        <div className="site-menu">
            <DropdownButton className="custom-dropdown circle-fa-custom" size="sm" title={<i class="far fa-globe"></i>}>
                <Dropdown.Item eventKey="1" data-language="en" onClick={changeLanguage}>
                    English
                </Dropdown.Item>
                <Dropdown.Item eventKey="2" data-language="sq" onClick={changeLanguage}>
                    Shqip
                </Dropdown.Item>
                {/* <Dropdown.Item eventKey="3" data-language="sr" onClick={changeLanguage}>
                    Srpski
                </Dropdown.Item> */}
            </DropdownButton>

            {/* Add connect to text strings */}
            <DropdownButton
                className="custom-dropdown circle-fa-custom d-none-mobile"
                size="sm"
                title={<i class="fal fa-random"></i>}
            >
                <Dropdown.Item>
                    <Link to="/add-artist" className="d-none-mobile">
                        <i className="fal fa-user-music"></i>
                        {addArtist}
                    </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                    <Link to="/join-user" className="d-none-mobile">
                        <i className="fal fa-user-plus"></i>
                        {join}
                    </Link>
                </Dropdown.Item>
            </DropdownButton>

            {/* {Auth.isLoggedIn() ? (
                <Link to="/add-artist" className="d-none-mobile circle-fa-custom">
                    <i className="fal fa-user-music"></i>
                    {addArtist}
                </Link>
            ) : (
                <Link to="/join-user" className="d-none-mobile circle-fa-custom">
                    <i className="fal fa-user-plus"></i>
                    {join}
                </Link>
            )} */}

            {Auth.isLoggedIn() ? (
                <DropdownButton size="sm" title={account} className="dropdown-right d-none-mobile">
                    <Dropdown.Item eventKey="1" as={Link} to="/profile" className="profile">
                        <figure className="profile-image">
                            {userData.profileImage ? (
                                <ImageTag
                                    src={userData.profileImage}
                                    alt={`${userData.firstName} ${userData.lastName}`}
                                />
                            ) : (
                                <img src={defaultUserImage} alt={`${userData.firstName} ${userData.lastName}`} />
                            )}
                        </figure>{' '}
                        <span>
                            Hi <b>{userData.firstName}</b>
                        </span>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item eventKey="2" as={Link} to="/profile">
                        Profile
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="3" as={Link} to="/saved">
                        Saved
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                        eventKey="3"
                        onClick={() => {
                            Auth.logout()
                        }}
                    >
                        Sign Out
                    </Dropdown.Item>
                </DropdownButton>
            ) : (
                <button onClick={toggleLogin} className="btn btn-menu d-none-mobile">
                    <i className="fal fa-sign-in"></i>
                    {login}
                </button>
            )}
        </div>
    )
}
export default Menu
