import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

function ImageUploadField({ type, accept, label, format, button, icon, innerRef, name, required = false, callbackFile = false }) {
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedFileURL, setSelectedFileURL] = useState('')

    return (
        <Form.Group>
            <div className="custom-file-upload">
                <input
                    type="file"
                    ref={innerRef}
                    name={name}
                    accept={accept}
                    onChange={e => {
                        setSelectedFile(e.target)
                        setSelectedFileURL(URL.createObjectURL(e.target.files[0]))
                        callbackFile && callbackFile(e);
                    }}
                />
                <div className="file-meta">
                    <label>
                        <i className={icon}></i>
                        {required && (
                            <span className="form-label">
                                <span className="required-field"></span>
                            </span>
                        )}
                        <span>{label}</span>
                        <b>{format}</b>
                    </label>
                    <div className="file-preview">
                        {selectedFile && (
                            <>
                                <img className="preview-image" src={selectedFileURL} alt={selectedFile.files[0].name} />
                                <span className="file-name">{selectedFile.files[0].name}</span>
                            </>
                        )}
                        <span className="input-button">{button}</span>
                    </div>
                </div>
            </div>
        </Form.Group>
    )
}
export default ImageUploadField
