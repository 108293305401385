import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { LanguageContext } from '../../contexts/LanguageContext'

function Exhibitions({ title, posts }) {
    const options = { month: 'short' }
    const { language } = useContext (LanguageContext)
    let events = []
    if (posts && posts.length !== 0) events = posts.slice(0, 2)

    const getField = (obj, field) => {
        if (obj && obj['multiLanguageFields'] && obj['multiLanguageFields'][language] && obj['multiLanguageFields'][language][field]) 
            return obj['multiLanguageFields'][language][field]
    }

    return (
        <div className="widget">
            <header>
                <h4>{title}</h4>
            </header>
            {events.map(event => (
                <article
                    className="exhibition"
                    key={event._id}
                    style={
                        event.featuredImage && {
                            backgroundImage: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)), url(${process.env.REACT_APP_IP}:${
                                process.env.REACT_APP_API_PORT
                            }/${event.featuredImage.replace('\\', '/')})`,
                        }
                    }
                >
                    <div className="exhibition-meta">
                        {event.startDate && (
                            <div className="date">
                                <span className="day">{new Date(event.startDate).getDate()}</span>
                                <span className="month">
                                    {new Date(event.startDate).toLocaleDateString('en-US', options)}
                                </span>
                            </div>
                        )}
                        <Link className="exhibition-title" to={`/news/${event._id}`}>{getField(event, 'title')}</Link>
                    </div>
                </article>
            ))}
        </div>
    )
}
export default Exhibitions
