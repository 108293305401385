import React, { useEffect, useState } from 'react'
// import { useTranslation } from 'react-i18next'
import { Container, Form, Col } from 'react-bootstrap'
import PageTitle from '../../components/Global/PageTitle'
import useForm from 'react-hook-form'
import Api from '../../api'
import Helpers from '../../helpers'
import { useAlert } from 'react-alert'

export default function ForgotPasswordPage() {
    // const { t } = useTranslation()
    // const globalContent = t('global', { returnObjects: true })
    const { register, handleSubmit, errors } = useForm()
    const alert = useAlert()
    const [btnDisabled, setBtnDisabled] = useState(false)

    const onSubmit = async recoveryData => {
        setBtnDisabled(true)
        const res = await Api.post('requestResetPassword', recoveryData)
        Helpers.validateResponse(res, alert, 'Password reset link has been sent to your email')
        setBtnDisabled(false)
    }

    useEffect(() => {
        Object.keys(errors).forEach(err => {
            alert.error(errors[err].message)
        })
        // eslint-disable-next-line
    }, [errors])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <section className="text-center">
                <Container>
                    <div className="text-center verification-flex-center">
                        <div>
                            <PageTitle title="Forgot your password? " subtitle="Request a password recovery!" />

                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Form.Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Control
                                                type="text"
                                                name="email"
                                                ref={register({
                                                    required: 'Required',
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        message: 'invalid email address',
                                                    },
                                                })}
                                                placeholder="Your email"
                                            />
                                            <div className="form-label">
                                                <i className="fal fa-envelope"></i>
                                                <span className="required-field"></span>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Row>
                                    <Col>
                                        <button
                                            disabled={btnDisabled}
                                            className="btn btn-dark btn-lg btn-block text-white"
                                            type="submit"
                                        >
                                            Email me a recovery link
                                        </button>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    )
}
