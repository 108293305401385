import React, { useEffect } from 'react'
import PageTitle from '../../components/Global/PageTitle'
import { JoinHeaders, JoinInfo, ArtistForm } from '../../components/Join'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

export default function JoinArtistPage() {
    const { t } = useTranslation()
    const joinContent = t('joinpage', { returnObjects: true })
    const globalContent = t('global', { returnObjects: true })

    const { joinheader, info, artistform } = joinContent

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section>
            <Container>
                <div className="paper-view">
                    <JoinHeaders user={joinheader.user} artist={joinheader.artist} active="artist" />
                    <JoinInfo info={info} active="artist" />
                    <ArtistForm form={artistform} globalContent={globalContent} />
                </div>
            </Container>
        </section>
    )
}
