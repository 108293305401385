import React from 'react'
import PricingTable from './PricingTable.jsx'
import { Row } from 'react-bootstrap'
import Helpers from '../../helpers'

function PricingTables({ pricingArr, description, globalContent }) {
    return (
        <div className="pricing-tables">
            <Row>
                {pricingArr.map((table, i) => (
                    <PricingTable
                        key={i}
                        title={table.name}
                        slug={Helpers.nameToSlug(table.name)}
                        image={table.image}
                        features={table.features}
                        price={table.price}
                        brand={table.color}
                        shortDesc={table.shortDescription}
                        longDesc={table.longDescription}
                        defaultHighlighted={table.defaultHighlighted}
                        description={description}
                        globalContent={globalContent}
                    />
                ))}
            </Row>
        </div>
    )
}

export default PricingTables
