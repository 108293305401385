import React, { useState, useEffect } from 'react'
import Logo from './Logo'
import Navigation from './Navigation'
import { Navbar } from 'react-bootstrap'
import Login from './Login'
import Menu from './Menu'
import './Header.scss'
// import { LanguageContext } from '../../contexts/LanguageContext'

function Header({ nav, headerContent, setModal }) {
    const { menu, login } = headerContent
    const [showLogin, setShowLogin] = useState(false)
    const [expand, setExpand] = useState(false)
    // const language = useContext(LanguageContext)

    useEffect(() => {
        setModal(showLogin)
    }, [setModal, showLogin])

    return (
        <header id="site-header">
            <Navbar collapseOnSelect expand="lg" variant="dark" expanded={expand}>
                <Logo />
                <Navigation
                    nav={nav}
                    menu={menu}
                    expandState={[expand, setExpand]}
                    loginState={{ value: showLogin, funk: setShowLogin }}
                />
                <Menu
                    menu={menu}
                    loginState={{ value: showLogin, funk: setShowLogin }}
                    expandState={[expand, setExpand]}
                />
            </Navbar>
            <Login content={login} modalState={showLogin} setModalState={setShowLogin} />
        </header>
    )
}
export default Header
