import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap';
import Api from '../../api';

export default function SingleActivity({ match, history }) {

    const [activity, setActivity] = useState({})

    useEffect(() => {
        const id = getActivityID();
        if (!id) history.push('/');
        fetchActivity(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if (!match.params.id) history.push('/')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match])

    const fetchActivity = async (id) => {
        const response = await Api.getWithId('activity', id)
        if (response && response.data && response.data.results) setActivity(response.data.results)
        else history.push('/');
    }
    
    const getActivityID = () => {
        return (match && match.params && match.params.id) ? match.params.id : null
    }

    const getFormatDate = (detail, index) => {
        try {
            return index === 0 ? new Date(detail.date).toISOString().slice(0,10) + ', ' : new Date(detail.date).toISOString().slice(0,10)
        } catch(err) {
            return ''
        }
    }


    return (
        <Container>
            <div className="navcontainer ">
                <div className="media-news col-md-10 offset-md-1">
                    <ul className="navlist">
                        <li className="data.art" onClick={() => history.push('/')}>Databaza.art {'>'} </li>
                        <li className="data.art" onClick={() => history.goBack()}>Activity {'>'} </li>
                        <li className="data.art">{activity.name && activity.name}</li>
                    </ul>
                </div>
            </div>

            <div className="col-md-10 offset-md-1 singlenewspage">
                <div className="single-page-header">
                    <h4>{activity.name && activity.name}</h4>
                    <div className="activity-parent-dates-location-single">
                        <p className="date-and-endDate" style={{marginBottom: '0px'}}>
                            {
                                (activity.details && activity.details.length > 0)
                                    && activity.details.map((detail, index) => {
                                        return getFormatDate(detail, index)
                                    })
                            }
                        </p>
                        <p className="city-and-name">
                        {
                            (activity.details && activity.details.length > 0)
                                    && activity.details.map(detail => {
                                        if (detail && detail.location && typeof detail.location === "object") {   
                                            return Object.keys(detail.location).map(key => {
                                                if (key === "place") return detail.location[key] + ', '; 
                                            })
                                        }
                                    })
                        } 
                        </p>
                    </div>
                </div>

                {
                    activity.image 
                        && <img
                            className="img-news-back"
                            src={process.env.REACT_APP_IP + ':' + process.env.REACT_APP_API_PORT + '/' + activity.image}
                            alt={"ds"}
                        />
                }
                <p>{activity.description && activity.description}</p>
            </div>
        </Container>
    )
}