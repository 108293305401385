const ip = process.env.REACT_APP_IP
const port = process.env.REACT_APP_API_PORT
const api = 'https://admin.databaza.art/api' // `${ip}:${port}/api`

export default {
    subscriptions: `${api}/subscriptions`,
    subscribers: `${api}/join`,
    artist: `${api}/add-artist`,
    singleArtist: `${api}/single-artist`,
    search: `${api}/search`,
    login: `${api}/login`,
    contact: `${api}/contact`,
    artistsCount: `${api}/artists-count`,
    deleteAccount: `${api}/delete-account`,
    requestResetPassword: `${api}/request-reset-password`,
    resetPassword: `${api}/reset-password`,
    taxonomies: `${api}/taxonomies`,
    blogs: `${api}/blogs`,
    blog: `${api}/blog`,
    blogCategories: `${api}/blog/categories`,
    taxonomiesMultiple: `${api}/taxonomies/multiple`,
    activity: `${api}/activity`,
    getArtWorks: `${api}/getArtWorks`,
    newsLetterRegister: `${api}/newsletter-register`,
    saveArtist: `${api}/save-artist`,
    removeSavedArtist: `${api}/remove-saved-artist`,
    currentUser: `${api}/current-user`,
    updateProfile: `${api}/update-profile`,
    unSubscribe: `${api}/un-subscribe`,
}
