import React, { useEffect, useState } from 'react'
// import { useTranslation } from 'react-i18next'
import { Container, Form, Col } from 'react-bootstrap'
import PageTitle from '../../components/Global/PageTitle'
import useForm from 'react-hook-form'
import Api from '../../api'
// import Helpers from '../../helpers'
import { useAlert } from 'react-alert'

export default function RecoveryPasswordPage({ history }) {
    // const { t } = useTranslation()
    const { register, handleSubmit, errors } = useForm()
    const alert = useAlert()
    const [message] = useState(null)
    const [token, setToken] = useState(null)

    const onSubmit = async recoveryData => {
        recoveryData.token = token
        const res = await Api.post('resetPassword', recoveryData)
        if (res.data.confirmation === 'Success') {
            alert.success('Your password has been changed succesfully')
            setTimeout(() => {
                history.push('/')
            }, 3000)
        }
    }

    useEffect(() => {
        Object.keys(errors).forEach(err => {
            if (errors[err].type === 'pattern') {
                alert.error('Password must be at least 9 characters long and inlude an uppercase letter and a symbol')
                return
            }
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        let url = new URL(window.location.href)
        let token = url.searchParams.get('token')
        setToken(token)

        window.scrollTo(0, 0)
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <section className="text-center">
                <Container>
                    <div className="text-center verification-flex-center">
                        <div>
                            {message ? (
                                <PageTitle title={message} subtitle="" />
                            ) : (
                                <>
                                    <PageTitle title="Recover Password" subtitle="Let us help you" />
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Control
                                                        type="password"
                                                        name="password"
                                                        ref={register({
                                                            retuired: true,
                                                            min: 8,
                                                            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/i,
                                                        })}
                                                    />
                                                    <div className="form-label">
                                                        <i className="fal fa-user"></i>
                                                        <span className="required-field">New password</span>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Control
                                                        type="password"
                                                        name="crf_password"
                                                        ref={register({
                                                            retuired: true,
                                                            min: 8,
                                                            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/i,
                                                        })}
                                                    />
                                                    <div className="form-label">
                                                        <i className="fal fa-user"></i>
                                                        <span className="required-field">Confirm new password</span>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>

                                        <Form.Row>
                                            <Col>
                                                <button
                                                    className="btn btn-dark btn-lg btn-block text-white"
                                                    type="submit"
                                                >
                                                    Request password change
                                                </button>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </>
                            )}
                        </div>
                    </div>
                </Container>
            </section>
        </div>
    )
}
