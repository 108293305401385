
import React from "react"
import { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap"
import Api from '../../api'
import { AuthContext } from "../../contexts/AuthContext"
import { Link } from "react-router-dom"
export default function SavedArtists() {
    const { user, getCurrentUser } = useContext(AuthContext)
    
    if (!user || !user.savedArtists) {
        return null;
    }

    const removeArtist = async function (artist) {
        const data = {
            artistID: artist._id
        }
        await Api.post("removeSavedArtist", data)
        await getCurrentUser()
    }

    return (
        <>
            <Container className="mt-5 saved-artist-container" md={5}>
                <div className="saved-artists-header">
                    <div>
                        <h5>Saved artists</h5>
                    </div>
                    <div>
                        <h5>{user.savedArtists.length} {user.savedArtists.length === 1 ? 'Artist' : 'Artists'}</h5>
                    </div>
                </div>

                {
                    user.savedArtists && user.savedArtists.length > 0 && (
                        <Row className="mt-5">
                            {
                                user.savedArtists.map((artist, index) => (
                                    <div key={index} className="col-3">
                                        <div className="remove-artist-wrapper">
                                            <span onClick={() => removeArtist(artist)}><i class="far fa-times-circle"></i></span>
                                        </div>
                                        <div>
                                            <img className="saved-artist-image" src={process.env.REACT_APP_IP + ':' + process.env.REACT_APP_API_PORT + '/' + artist.profileImage} />
                                        </div>
                                        {
                                            artist.artistType && (
                                                <span className="saved-artist-type">{artist.artistType}</span>

                                            )
                                        }
                                        <h5><Link to={`/artist/${artist._id}`}>{artist.firstName + ' ' + artist.lastName}</Link></h5>
                                    </div>
                                ))
                            }
                        </Row>
                    )
                }

            </Container>
        </>
    )
}