

import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import SectionTitle from '../../components/Global/SectionTitle'
import Logo from '../../assets/images/logo_black.svg'
import Api from "../../api/Api"
import Spinner from 'react-bootstrap/Spinner'

export default function UnSubscribe() {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('token')

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const unSubscribe = async function () {
        const response = await Api.get('unSubscribe', { token: token })
        if (!response || !response.data || response.data.confirmation !== "Success") {
            setError(true)
            setLoading(false)
            return;
        }
        setError(false)
        setLoading(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        unSubscribe()
    }, [])

    return (
        <>
            <div>
                <section className="text-center">
                    <Container>
                        <div className="text-center verification-flex-center">
                            <div>
                                {
                                    loading
                                        ? (
                                            <Spinner animation="border" role="status">

                                            </Spinner>
                                        )
                                        : error ? (
                                            <>
                                                <img
                                                    src={Logo}
                                                    style={{ width: 80, marginBottom: 30 }}
                                                    alt="databaza e artisteve të ekosoves"
                                                />
                                                <SectionTitle
                                                    title={"Something went wrong!"}
                                                    subtitle={"Please try again later."}
                                                />
                                            </>
                                        )
                                            : (
                                                <>
                                                    <img
                                                        src={Logo}
                                                        style={{ width: 80, marginBottom: 30 }}
                                                        alt="databaza e artisteve të ekosoves"
                                                    />
                                                    <SectionTitle
                                                        title={"Success"}
                                                        subtitle={"You will no longer receive emails from databaza.art."}
                                                    />
                                                </>
                                            )
                                }
                            </div>
                        </div>
                    </Container>
                </section>
            </div>
        </>
    )
}