import React, { useState } from 'react'
import BlogEvent from '../BlogEvent/BlogEvent'
import BlogPost from '../BlogPost/BlogPost'
import SearchHeader from '../SearchResults/SearchHeader'
// import EventItem from './EventItem'
// import PostItem from './PostItem'
import './News.scss'

export default function News({
    blogContent,
    mediaPageContent,
    posts,
    filterData,
    blogCategories,
    setCategorySelected,
}) {
    const [blogLayoutSelected, setBlogLayoutSelected] = useState(
        sessionStorage.getItem('blog-view-option') === 'Grid' ? 'Grid' : 'List'
    )
    const [active, setActive] = useState(sessionStorage.getItem('blog-view-option') === 'Grid' ? 'Grid' : 'List')

    const handleBlogLayoutChange = e => {
        setActive(e.target.getAttribute('data-val'))
        sessionStorage.setItem('blog-view-option', e.target.getAttribute('data-val'))
        setBlogLayoutSelected(e.target.getAttribute('data-val'))
    }

    const handleCategoriesSelectedChange = e => setCategorySelected(e.value)

    return (
        <div className="search-results" style={{ marginTop: 60, marginBottom: 60 }}>
            <SearchHeader
                newTitle={blogContent.title}
                mediaPageContent={mediaPageContent}
                terms={[]}
                count={posts.length}
                artistCount={posts.length}
                postFilter={posts}
                filterData={filterData}
                handleBlogLayoutChange={handleBlogLayoutChange}
                active={active}
                fromBlog={true}
                blogCategories={blogCategories}
                handleCategoriesSelectedChange={handleCategoriesSelectedChange}
            />

            {!blogLayoutSelected || blogLayoutSelected === 'List' ? (
                <React.Fragment>
                    {posts.map(post => {
                        if (post.type === 'EVENT') {
                            return <BlogEvent key={post._id} type={post.type} post={post} />
                        } else if (post.type === 'ARTIST') {
                            return <BlogPost key={post._id} post={post} />
                        } else {
                            return <BlogPost key={post._id} post={post} />
                        }
                    })}
                </React.Fragment>
            ) : (
                <div className="row">
                    {posts.map(post => {
                        if (post.type === 'EVENT') {
                            return <BlogEvent gridOption={true} key={post._id} post={post} />
                        } else if (post.type === 'ARTIST') {
                            return <BlogPost gridOption={true} key={post._id} post={post} />
                        } else {
                            return <BlogPost gridOption={true} key={post._id} post={post} />
                        }
                    })}
                </div>
            )}
        </div>
    )
}
